import type { SxProps, SystemStyleObject } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import type { TransitionProps } from '@mui/material/transitions';
import type { CSSProperties } from '@mui/styles';
import type { WatershedPalette } from './palette';

import {
  mergeIntoNextFontFamily,
  jetBrainsMono,
  messinaSans,
  mackinacPro,
} from '@watershed/fonts/next';

export type ColorPrimitive =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'error'
  | 'info';

function typeCSSProperties(obj: CSSProperties): CSSProperties {
  return obj;
}

export function boxShadowBorder(
  color: string,
  options: { width?: number; inset?: boolean } = {}
) {
  const { width = 1, inset = true } = options;
  return `${inset ? 'inset ' : ''}0 0 0 ${width}px ${color}`;
}

export function getPaletteUtils<T extends WatershedPalette>(palette: T) {
  const border = (color: string = palette.divider) => boxShadowBorder(color);
  const boxShadowButtonBase = '0 1px 2px rgba(38, 50, 69, 0.1)';
  const boxShadowButtonBorder = 'inset 0 0 0 1px rgba(232, 234, 236, 0.99)';
  const boxShadowButtonHover = '0 1px 2px rgba(38, 50, 69, 0.2)';
  const boxShadowFocus = `0 0 0 2px ${palette.primary.main}40, inset 0 0 0 1px ${palette.primary.main}, inset 0 1px 2px rgba(0, 0, 0, 0.1)`;
  const boxShadowField = {
    base: border(),
    textInput: border(),
    warning: `${border(
      palette.warning.dark
    )}, inset 0 2px 4px rgba(0, 0, 0, 0.06)`,
    error: `${border(palette.error.dark)}, inset 0 2px 4px rgba(0, 0, 0, 0.06)`,
    focus: boxShadowFocus,
  };

  const inputPlaceholderStyles: CSSProperties = {
    color: palette.secondary.dark,
    opacity: 1,
  };

  const disabledPlaceholderStyles: CSSProperties = {
    color: palette.grey40,
    WebkitTextFillColor: palette.grey40,
    boxShadow: boxShadowField.base,
    cursor: 'not-allowed',
    userSelect: 'none',
  };

  const secondaryChip = {
    background: palette.grey20,
    text: palette.grey70,
    hoverBackground: palette.grey30,
  };

  return {
    transition: '250ms cubic-bezier(0.4, 0, 0.2, 1)',

    boxShadow: '0 1px 2px rgba(38, 50, 69, 0.35)',
    boxShadowMd: `0 10px 20px -2px rgba(0, 0, 0, 0.17), 0 6px 12px -6px rgba(0, 0, 0, 0.15), inset 0 1px 0 ${palette.white}`,
    boxShadowMenu:
      '0px 0px 4px 2px rgba(79, 89, 110, 0.06), 0px 0px 8px -4px rgba(79, 89, 110, 0.16), 0px 4px 8px 0px rgba(79, 89, 110, 0.08)',
    boxShadowTooltip:
      '0px 0px 4px 2px rgba(79, 89, 110, 0.06), 0px 0px 8px -4px rgba(79, 89, 110, 0.16), 0px 4px 8px 0px rgba(79, 89, 110, 0.08)',
    boxShadowCard:
      '0px 1px 2px 0px rgba(79, 89, 110, 0.16), 0px 0px 16px 1px rgba(79, 89, 110, 0.06)',
    boxShadowLg: `0 30px 60px -12px rgba(0, 0, 0, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), inset 0 1px 0 ${palette.white}`,
    boxShadowLgHeavy: `0 30px 60px -12px rgba(0, 0, 0, 0.5), 0 18px 36px -18px rgba(0, 0, 0, 0.5), inset 0 1px 0 ${palette.white}`,
    boxShadowFlowchartNode: `0px 25px 52px -10px rgba(0, 0, 0, 0.08);`,
    boxShadowFocus,
    boxShadowField,

    boxShadowButton: {
      secondary: {
        default: `${boxShadowButtonBorder}, ${boxShadowButtonBase}`,
        hover: `${boxShadowButtonBorder}, ${boxShadowButtonHover}`,
      },
      primary: {
        default: `${boxShadowButtonBase}, inset 0 1px 0 rgba(255, 255, 255, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.125)`,
        hover: `${boxShadowButtonHover}, inset 0 1px 0 rgba(255, 255, 255, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.125)`,
      },
      destructive: {
        default: `inset 0 0 0 1px ${palette.error.light}, ${boxShadowButtonBase}`,
        hover: `inset 0 0 0 1px ${palette.error.light}, ${boxShadowButtonHover}`,
        focus: `0 0 0 2px ${palette.error.light}, inset 0 0 0 1px ${palette.error.main}, inset 0 1px 2px rgba(0, 0, 0, 0.1)`,
      },
      focus: boxShadowFocus,
      disabled: boxShadowButtonBorder,
    },

    // these require only the border box-shadow because the drop shadow effect is on the group
    boxShadowButtonGrouped: {
      secondary: {
        default: `${boxShadowButtonBorder}`,
        hover: `${boxShadowButtonBorder}`,
      },
      primary: {
        default: `inset 0 1px 0 rgba(255, 255, 255, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.125)`,
        hover: `inset 0 1px 0 rgba(255, 255, 255, 0.125), inset 0 -1px 0 rgba(0, 0, 0, 0.125)`,
      },
      focus: boxShadowFocus,
      disabled: boxShadowButtonBorder,
    },

    disabledPlaceholderStyles,
    inputPlaceholderStyles,

    textFieldStyles: typeCSSProperties({
      font: 'inherit',
      letterSpacing: 'inherit',
      backgroundColor: palette.white,
      color: palette.text.primary,
      boxShadow: boxShadowField.textInput,
      padding: '4px 12px',
      lineHeight: '24px',
      borderRadius: '6px',
      appearance: 'none',
      border: 0,
      '&:focus': {
        outline: 0,
        boxShadow: boxShadowField.focus,
      },
      '&:disabled': disabledPlaceholderStyles,
      '&::placeholder': inputPlaceholderStyles,
      '&:disabled::placeholder': {
        ...disabledPlaceholderStyles,
        boxShadow: 'none',
      },
    }),

    alwaysShowWebkitScrollbar: typeCSSProperties({
      // Always show the scrollbar.
      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        height: 7,
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        // eslint-disable-next-line @watershed/no-custom-colors
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        boxShadow: `0 0 1px ${palette.lightShadow}`,
      },
    }),

    chipColors: {
      default: secondaryChip,
      secondary: secondaryChip,
      info: secondaryChip,
      primary: {
        background: palette.cobalt10,
        text: palette.cobalt70,
        hoverBackground: palette.cobalt20,
      },
      success: {
        background: palette.success.light,
        text: palette.spring100,
        hoverBackground: palette.spring20,
      },
      warning: {
        background: palette.warning.light,
        text: palette.marigold100,
        hoverBackground: palette.marigold20,
      },
      error: {
        background: palette.error.light,
        text: palette.error.dark,
        hoverBackground: palette.sun20,
      },
      decorative: {
        background: palette.decorative.light,
        text: palette.decorative.dark,
        hoverBackground: palette.forest20,
      },
    },
  } as const;
}

export const SPACING_PX = 8;

export const SANS_SERIF_FONT_FAMILY = mergeIntoNextFontFamily(messinaSans, [
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen-Sans',
  'Ubuntu',
  'Cantarell',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
]);

export const CODE_FONT_FAMILY = mergeIntoNextFontFamily(jetBrainsMono, [
  'ui-monospace',
  'source-code-pro',
  'Menlo',
  'Monaco',
  'Consolas',
  '"Courier New"',
  'monospace',
]);

export const SERIF_FONT_FAMILY = mergeIntoNextFontFamily(mackinacPro, [
  'Georgia',
  'Times New Roman',
  'serif',
]);

export const iconSizeMedium = 16;
// The MUI `theme.spacing(2)` returns a string with the `px` unit, which is less
// amenable to arithmetic. This function returns a number of pixels for a spacing.
export function themeSpacingInPx(spacing: number) {
  return spacing * SPACING_PX;
}
export function mixinSx(
  ...sxes: Array<SxProps<Theme> | undefined | false>
): SxProps<Theme> {
  return sxes.filter((x) => x != null && x !== false).flat();
}

/**
 * Simple helper to make it easier to type sx objects.
 */
export function sxType(sx: SxProps<Theme>): SxProps<Theme> {
  return sx;
}

export function systemStyleObjectType(
  sso: SystemStyleObject<Theme>
): SystemStyleObject<Theme> {
  return sso;
}

export const menuItemPadding = {
  // can't use the shorthand here or the button button base styles which somehow get combined with these will override it
  paddingTop: `${SPACING_PX * 1.5}px`,
  paddingBottom: `${SPACING_PX * 1.5}px`,
  paddingLeft: `${SPACING_PX * 2}px`,
  paddingRight: `${SPACING_PX * 2}px`,
};

export const popoverTransitionDuration: TransitionProps['timeout'] = {
  appear: 0,
  enter: 0,
  exit: 150,
};
