import ReactGA from 'react-ga4';
import Mixpanel from 'mixpanel-browser';
import * as FullStorySdk from '@fullstory/browser';
import consoleDebug from '@watershed/ui-core/utils/consoleDebug';
import isDashboardApp from '@watershed/ui-core/utils/isDashboardApp';
import isAdminApp from '@watershed/ui-core/utils/isAdminApp';
import {
  GQOrgPointOfContactKind,
  GQWatershedPlan,
  GQWatershedPlanLegacy,
} from '@watershed/shared-universal/generated/graphql';
import { Userpilot } from 'userpilot';

type UtmParams = {
  utm_source?: string;
  utm_medium?: string;
};

export function addUtmParams(path: string, params: UtmParams): string {
  const url = new URL(path);
  if (params.utm_source) {
    url.searchParams.set('utm_source', params.utm_source);
  }
  if (params.utm_medium) {
    url.searchParams.set('utm_medium', params.utm_medium);
  }
  return url.toString();
}

export interface UserProperties {
  userId: string;
  createdAt: Date;
  userName: string;
  userEmail: string;
  orgId: string;
  orgName: string;
  isCustomer: boolean;
  isCreatedAtCurrentMonthOfQuarter: boolean;
  isAdminUser: boolean;
  loginAsUserId: string | undefined;
  loginAsUserName: string | undefined;
  loginAsUserIsCustomer: boolean | undefined;
  preferredLocale: string | null | undefined;
}

type AdminUserProperties = {
  watershedEmployeeHandle: string | undefined;
  watershedEmployeeKind:
    | GQOrgPointOfContactKind
    | 'EngineerInDevelopment'
    | undefined;
  anonymizeInFullStory: boolean;
};

type DashboardUserProperties = {
  isLoginAsUser: boolean;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

interface MixpanelUserProperties extends UserProperties {
  // default mixpanel versions of these
  name: string;
  email: string;

  // Admin-specific properties
  handle: string | undefined;

  // Demo/Test org visibility
  isDemoOrg: boolean | undefined;
  isTestOrg: boolean | undefined;
  isStagingOrg: boolean | null | undefined;
}

const FS_INIT_TIMEOUT_MS = 10000;

// Disables FS initialization if set during initial page load.
export const DISABLE_FULLSTORY_URL_PARAM = 'disableFullStory';

type Primitive =
  | string
  | number
  | boolean
  | Array<string>
  | Array<number>
  | null
  | undefined;

export type AnalyticsProperties = {
  [key: string]: Primitive | AnalyticsProperties | Array<AnalyticsProperties>;
};

type TrackingOptions = {
  skipFullStory?: boolean;
};

type EventType = 'action' | 'error' | 'link' | 'modal' | 'view';

class AnalyticsUtils {
  domainOverride: string | undefined;
  feature: string = 'home';
  userProperties: UserProperties | undefined;
  adminUserProperties: AdminUserProperties | undefined;
  dashboardUserProperties: DashboardUserProperties | undefined;
  initializedMixpanelProjectId: string | null = null;
  fsReady: Promise<boolean>;
  fsReadyResolve: (v: boolean) => void;

  constructor(domainOverride?: string) {
    this.domainOverride = domainOverride;
    this.fsReadyResolve = () => {}; // purely to avoid TS error about not being "definitely" initialized
    this.fsReady = new Promise<boolean>((resolve) => {
      this.fsReadyResolve = resolve;
    });
  }

  private get domain() {
    if (this.domainOverride) {
      return this.domainOverride;
    }
    if (isDashboardApp()) {
      return 'dashboard';
    } else if (isAdminApp()) {
      return 'admin';
    } else {
      console.error(
        'Could not infer domain for analytics events; falling back to dashboard'
      );
      return 'dashboard';
    }
  }

  /**
   * Our event naming convention is:
   *   [domain].[feature].[name].[type]
   * e.g. dashboard.measure.active.view
   *
   * When calling track(), the domain and feature are automatically added:
   * - domain is set to 'dashboard' by default
   * - feature is set to the first route component after `.com/`
   *
   * The name and type are passed in as arguments; the type is inferred from
   * which of the template methods is called.
   *
   * Note that since the naming convention uses `.` as a delimiter, the event
   * name (the third component) should not contain `.`.
   */
  private async track(
    name: string,
    type: EventType,
    eventProperties?: any,
    options?: TrackingOptions
  ) {
    const eventName = this.getEventName(name, type);
    consoleDebug(
      'ANALYTICS',
      'lightsalmon',
      `${eventName}: %o`,
      eventProperties
    );

    if (this.isDatadogSyntheticBot()) {
      console.info('[skipping analytics]', eventName);
      return;
    }

    const mixpanelProps = {
      ...eventProperties,
      ...this.userProperties,
      domain: this.domain,
      feature: this.feature,

      propertyName: name,
      eventType: type,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      language: navigator.language,
      languages: navigator.languages,
      fullStorySessionUrl: await this.getFullStorySessionURL(),
    };
    if (this.initializedMixpanelProjectId) {
      Mixpanel.track(eventName, mixpanelProps);
    }

    Userpilot.track(eventName, mixpanelProps);

    if (!options?.skipFullStory) {
      // FullStory imposes a cardinality limit on event names and properties.
      // https://help.fullstory.com/hc/en-us/articles/360020623234-Client-API-Requirements#property-cardinality-limiting
      // Properties with the same property name but different event names are
      // considered different properties for the cardinality limit. We avoid the
      // cardinality limit by logging the eventType as the FS event name, and
      // the domain, feature, and propertyName as properties.
      const fullStoryProps = {
        ...eventProperties,
        eventName,
        domain: this.domain,
        feature: this.feature,
        propertyName: name,
      };
      await this.trackWithFullStory(type, fullStoryProps);
    }

    if (type === 'view') {
      await this.registerPageProperties({
        lastViewEvent: eventName,
      });
    }
  }

  getEventName(name: string, type: EventType) {
    return [this.domain, this.feature, name, type].join('.');
  }

  /**
   * The four template types of events:
   * 1. view = pageview
   * 2. modal = view of a modal / dialog
   * 3. action = user intra-page action
   * 4. link = external link
   */

  /**
   * view() designates a pageview, and is called automatically on path change.
   * It typically shouldn't be called manually.
   *
   * See @this.track() for more info on the naming convention.
   **/
  view(name: string, properties?: any, options?: TrackingOptions) {
    void this.track(name, 'view', properties, options);
  }

  /**
   * modal() designates the opening of a modal or dialog. There are two common
   * strategies for calling it:
   * 1. Call it on the callback / event handler for the modal opening.
   * 2. Call it within a useEffect hook in the component.
   *
   * See @this.track() for more info on the naming convention.
   */
  modal(name: string, properties?: any, options?: TrackingOptions) {
    void this.track(name, 'modal', properties, options);
  }

  /**
   * action() designates an action taken by the user within a page. This is the
   * most common event type.
   *
   * See @this.track() for more info on the naming convention.
   */
  action(name: string, properties?: any, options?: TrackingOptions) {
    void this.track(name, 'action', properties, options);
  }

  /**
   * link() designates clicking on a link to an external site.
   *
   * See @this.track() for more info on the naming convention.
   */
  link(name: string, url: string, properties?: any, options?: TrackingOptions) {
    void this.track(
      name,
      'link',
      {
        url,
        ...properties,
      },
      options
    );
  }

  /**
   * error() designates a user encountering an error. It's currently used for
   * both unexpected errors, and user-facing events like validation errors.
   */
  error(name: string, properties?: any, options?: TrackingOptions) {
    void this.track(name, 'error', properties, options);
  }

  /**
   * time() starts an event timer for a particular name + event type. When a
   * track event is called subsequently with a matching name + type a $duration
   * field will be included in the data sent to mixpanel which denotes the
   * elapsed duration between time() and the final Mixpanel.track call. See
   * https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpaneltime_event
   */
  time(name: string, type: EventType) {
    Mixpanel.time_event(this.getEventName(name, type));
  }

  path: string = 'not loaded';

  async updatePath(url: URL) {
    const components = url.pathname.split('/');
    this.feature = components[1].length > 1 ? components[1] : 'home';

    const idRegex = /_[A-Za-z0-9]{20}/g;

    const genericPath = url.pathname.replace(idRegex, '_id');
    const queryParams = url.search;

    const urlSearchParams = new URLSearchParams(queryParams);
    const utmParams: UtmParams = {
      utm_source: urlSearchParams.get('utm_source') ?? undefined,
      utm_medium: urlSearchParams.get('utm_medium') ?? undefined,
    };

    await this.registerPageProperties({
      path: url.pathname,
      genericPath,
      queryParams,
      ...utmParams,
    });

    // Add a view if the path (i.e. not the queryParams) changed
    if (url.pathname !== this.path) {
      const pageNameParts = components
        .map((component) => {
          return idRegex.test(component) ? '_id' : component;
        })
        // Ignore the first part since that's /), and the second part since
        // that's recorded as "feature" in the event name.
        .slice(2);
      const pageName =
        pageNameParts.length === 0 ? 'home' : pageNameParts.join('.');
      this.view(pageName, undefined, { skipFullStory: true });
      this.path = url.pathname;
    }
  }

  /**
   * Register page properties that will be sent with **all** subsequent Mixpanel
   * events, and just the FullStory events on the current pathname (different
   * query params are considered to be the same page by FullStory).
   *
   * Note: If you want to only register Mixpanel page properties for the
   * **current page**, then you should use the `useAnalyticsPageProperties`
   * hook, which takes care of calling `unregisterPageProperties` when the
   * component is unmounted.
   *
   * Note: Page properties that are registered in a `useEffect` hook will not
   * necessarily be registered before the page view event is emitted, for
   * example if there is a loading state that happens before the `useEffect`
   * fires. Registered page properties are only guaranteed to be included in
   * custom tracking events fired off by user actions.
   */
  async registerPageProperties(pageVars: AnalyticsProperties) {
    Mixpanel.register(pageVars, {
      // Disable cookie-based, cross-visit persistent storage of Mixpanel
      // tracking properties. We will explicitly send all properties we care
      // about with each tracking event.
      persistent: false,
    });

    await this.setFullStoryPageVars({
      ...pageVars,
      domain: this.domain,
      feature: this.feature,
    });
  }

  /**
   * Necessary for when only wanting to register page properties for a given
   * page, we must unregister them after the page is unmounted so subsequent
   * pages don't include those properties.
   */
  unregisterPageProperties(pageVarKeys: Array<string>) {
    for (const key of pageVarKeys) {
      Mixpanel.unregister(key, {
        persistent: false,
      });
    }

    // Note: This is only unregistering for Mixpanel. AFAICT from the FullStory
    // docs, when you set page vars, it's automatically only registered for the
    // current URL path, and are reset if the URL path changes.
  }

  setUserProperties(userProperties: UserProperties) {
    this.userProperties = userProperties;
  }

  setAdminUserProperties(adminUserProperties: AdminUserProperties) {
    this.adminUserProperties = adminUserProperties;
  }

  setDashboardUserProperties(dashboardUserProperties: DashboardUserProperties) {
    this.dashboardUserProperties = dashboardUserProperties;
  }

  async identify({
    userProperties,
    dashboardUserProperties,
    adminUserProperties,
  }: {
    userProperties: UserProperties;
    dashboardUserProperties: DashboardUserProperties | null;
    adminUserProperties: AdminUserProperties | null;
  }) {
    const { isLoginAsUser, demoOrg, testOrg, stagingOrg } =
      dashboardUserProperties ?? {};
    const { watershedEmployeeKind, watershedEmployeeHandle } =
      adminUserProperties ?? {};
    ReactGA.set({
      userId: userProperties.userId,
      dimension1: userProperties.orgId,
      dimension2: (isLoginAsUser ?? false).toString(),
      dimension3: userProperties.orgName,
      dimension4: String(demoOrg || testOrg || stagingOrg),
      // We overload eventLabel to tag orgId so that we have acess to
      // orgId in real time where custom dimensions aren't available.
      eventLabel: isLoginAsUser ? '' : userProperties.orgId,
      ...(watershedEmployeeKind
        ? {
            user_properties: {
              orgPointOfContactKind: watershedEmployeeKind,
            },
          }
        : {}),
    });

    const { loginAsUserId, loginAsUserName } = userProperties;
    if (loginAsUserId) {
      // If loginAs, clear Mixpanel's distinct_id to avoid merging.
      // Note: logouts also reset this.
      Mixpanel.reset();
    }
    const loginAsPrefix = loginAsUserName ? `${loginAsUserName} as ` : '';
    const userId = loginAsPrefix + userProperties.userId;
    const userName = loginAsPrefix + userProperties.userName;

    const mixpanelUserProperties: MixpanelUserProperties = {
      ...userProperties,
      // default mixpanel properties which populate the mixpanel UI
      name: userName,
      email: userProperties.userEmail,

      // override with the login as version
      userId,
      userName,

      // Admin properties
      handle: watershedEmployeeHandle,

      // Demo/Test org visibility
      isTestOrg: testOrg,
      isDemoOrg: demoOrg,
      isStagingOrg: stagingOrg,
    };

    if (this.initializedMixpanelProjectId) {
      Mixpanel.identify(userId);
      Mixpanel.people.set(mixpanelUserProperties);
    }

    if (adminUserProperties?.anonymizeInFullStory) {
      await this.anonymizeInFullstory();
    } else {
      // passing the mixpanel version of these properties for parity if we want the same names in fullstory for analytics
      await this.identifyWithFullStory(mixpanelUserProperties);
    }

    this.setUserProperties(userProperties);
    if (adminUserProperties) {
      this.setAdminUserProperties(adminUserProperties);
    }
    if (dashboardUserProperties) {
      this.setDashboardUserProperties(dashboardUserProperties);
    }

    const { userEmail, ...filteredUserProperties } = userProperties;

    Userpilot.identify(userProperties.userId, {
      name: userName,
      email: userEmail,
      ...filteredUserProperties,
      handle: watershedEmployeeHandle,
      company: {
        name: userProperties.orgName,
        id: userProperties.orgId,
        isTestOrg: testOrg,
        isDemoOrg: demoOrg,
        isStagingOrg: stagingOrg,
        watershedPlan: dashboardUserProperties?.watershedPlan,
        watershedPlanLegacy: dashboardUserProperties?.watershedPlanLegacy,
      },
      orgPointOfContactKind: watershedEmployeeKind ?? undefined,
    });
  }

  async getFullStorySessionURL(): Promise<string | null> {
    if (!(await this.fsReady)) {
      return null;
    }

    return FullStorySdk.getCurrentSessionURL(
      true // link to current moment in session replay
    );
  }

  /*
  This does not await fullstory sdk being ready, for use in
  urql exchanges (we don't want to gate API requests on fullstory loading).
  */
  syncGetFullStorySessionURL(): string | null {
    let url: string | null = null;

    if (FullStorySdk.isInitialized()) {
      try {
        url = FullStorySdk.getCurrentSessionURL(
          true // link to current moment in session replay
        );
      } catch (e) {
        console.info('Failed to retrieve fs session url');
      }
    }
    return url;
  }

  /*
  This does not await fullstory sdk being ready, for use in
  urql exchanges (we don't want to gate API requests on fullstory loading).
  */
  syncGetFullStorySessionId(): string | null {
    let session: string | null = null;

    if (
      FullStorySdk.isInitialized() &&
      window &&
      (window as any).FS &&
      typeof (window as any).FS.getCurrentSession === 'function'
    ) {
      try {
        session = (window as any).FS.getCurrentSession();
      } catch (e) {
        console.info('Failed to retrieve fs session id');
      }
    }
    return session;
  }

  private async trackWithFullStory(
    eventType: EventType,
    eventProps: AnalyticsProperties
  ) {
    if (!(await this.fsReady)) {
      return;
    }
    FullStorySdk.event(eventType, eventProps);
  }

  private async setFullStoryPageVars(pageVars: AnalyticsProperties) {
    if (!(await this.fsReady)) {
      return;
    }
    FullStorySdk.setVars('page', pageVars);
  }

  private async identifyWithFullStory(userProperties: MixpanelUserProperties) {
    if (!(await this.fsReady)) {
      return;
    }
    FullStorySdk.identify(userProperties.userId, {
      // we already have the special "email" prop but need FS's special displayName
      displayName: userProperties.userName,
      ...userProperties,
    });
  }

  private async anonymizeInFullstory() {
    if (!(await this.fsReady)) {
      return;
    }
    FullStorySdk.anonymize();
  }

  async init({
    fullStoryOrgId,
    mixpanelProjectId,
  }: {
    fullStoryOrgId: string | undefined;
    mixpanelProjectId: string | undefined;
  }) {
    // Only initialize Analytics client-side
    if (typeof window === 'undefined') {
      return;
    }

    if (!FullStorySdk.isInitialized()) {
      // my env variable being set to empty string caused this to throw. luckily the try catch caught it
      // and properly let everything continue but we should !! to avoid try ing to init with empty
      const willEverInitFS =
        !!fullStoryOrgId &&
        !this.isDatadogSyntheticBot() &&
        !this.isFullStoryDisabled();
      if (willEverInitFS) {
        try {
          setTimeout(() => {
            // calling a promise resolve function a second time is a no-op
            // so either init succeeds below within the timeout period or we resolve to false and bail
            this.fsReadyResolve(false);
          }, FS_INIT_TIMEOUT_MS);
          FullStorySdk.init(
            { orgId: fullStoryOrgId, recordCrossDomainIFrames: true },
            () => {
              this.fsReadyResolve(true);
            }
          );
        } catch (e) {
          this.fsReadyResolve(false);
          // TODO: should we capture this to sentry?
          console.error(e);
        }
      } else {
        this.fsReadyResolve(false);
      }
    }

    if (
      mixpanelProjectId !== undefined &&
      this.initializedMixpanelProjectId === null
    ) {
      Mixpanel.init(mixpanelProjectId, {
        api_host: 'https://mep.watershedclimate.com',
        debug: this.isDebugMode(),
        // We want to ignore DNT to get full visibility into product usage.
        ignore_dnt: true,
      });
      this.initializedMixpanelProjectId = mixpanelProjectId;
    }
    await this.fsReady;
  }

  isDatadogSyntheticBot() {
    // https://docs.datadoghq.com/synthetics/guide/identify_synthetics_bots/?tab=browsertests
    return Boolean(
      typeof window !== 'undefined' &&
        '_DATADOG_SYNTHETICS_BROWSER' in window &&
        window._DATADOG_SYNTHETICS_BROWSER
    );
  }

  isFullStoryDisabled() {
    if (typeof window === 'undefined') {
      // Always disabled outside of the browser
      return true;
    }
    // FullStory might have been disabled previously in the session. We store
    // the value in session storage so that it doesn't have to be set on every
    // navigation event.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue =
      this.getFullStoryParamFromSessionStorage() ??
      urlParams.get(DISABLE_FULLSTORY_URL_PARAM);
    if (paramValue) {
      this.setFullStoryParamInSessionStorage(paramValue);
      return paramValue !== 'false';
    }
    return false;
  }

  private getFullStoryParamFromSessionStorage() {
    return window.sessionStorage.getItem(DISABLE_FULLSTORY_URL_PARAM);
  }

  private setFullStoryParamInSessionStorage(value: string) {
    return window.sessionStorage.setItem(DISABLE_FULLSTORY_URL_PARAM, value);
  }

  isDebugMode() {
    if (
      process.env.NODE_ENV !== 'development' ||
      typeof window === 'undefined'
    ) {
      return false;
    }
    try {
      return window.localStorage.getItem('MIXPANEL_DEBUG') === 'true';
    } catch (err) {
      return false;
    }
  }
}

export const Analytics = new AnalyticsUtils();

// for use in tests
export const AnalyticsUtilsForTests = AnalyticsUtils;
