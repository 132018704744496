import { i18n } from '@watershed/intl';
import { GQCurrencyCode } from '../generated/graphql';
import { getObjectKeys } from '../getObjectKeys';
import assertKeyOf from './assertKeyOf';
import {
  DEFAULT_LOCALE,
  PSEUDO_LOCALE,
  SupportedLocale,
} from '@watershed/intl/constants';
import { pseudoLocalize } from '@watershed/intl/utils';
import memoize from 'lodash/memoize';

// Pulled from https://www.xe.com/symbols/
export const CURRENCY_MAP: {
  [key in GQCurrencyCode]: {
    code: GQCurrencyCode;
    symbol: string;
  };
} = {
  ALL: {
    code: 'ALL',
    symbol: 'Lek',
  },
  AFN: {
    code: 'AFN',
    symbol: '؋',
  },
  ARS: {
    code: 'ARS',
    symbol: '$',
  },
  AOA: {
    code: 'AOA',
    symbol: 'Kz',
  },
  AMD: {
    code: 'AMD',
    symbol: 'դր',
  },
  AWG: {
    code: 'AWG',
    symbol: 'ƒ',
  },
  AUD: {
    code: 'AUD',
    symbol: '$',
  },
  AZN: {
    code: 'AZN',
    symbol: '₼',
  },
  BSD: {
    code: 'BSD',
    symbol: '$',
  },
  BBD: {
    code: 'BBD',
    symbol: '$',
  },
  BYN: {
    code: 'BYN',
    symbol: 'Br',
  },
  BZD: {
    code: 'BZD',
    symbol: 'BZ$',
  },
  BHD: {
    code: 'BHD',
    symbol: '.د.ب',
  },
  BDT: {
    code: 'BDT',
    symbol: '৳',
  },
  BMD: {
    code: 'BMD',
    symbol: '$',
  },
  BTN: {
    code: 'BTN',
    symbol: 'Nu.',
  },
  BOB: {
    code: 'BOB',
    symbol: '$b',
  },
  BAM: {
    code: 'BAM',
    symbol: 'KM',
  },
  BWP: {
    code: 'BWP',
    symbol: 'P',
  },
  BGN: {
    code: 'BGN',
    symbol: 'лв',
  },
  BRL: {
    code: 'BRL',
    symbol: 'R$',
  },
  BND: {
    code: 'BND',
    symbol: '$',
  },
  BIF: {
    code: 'BIF',
    symbol: 'FBu',
  },
  DZD: {
    code: 'DZD',
    symbol: 'دج',
  },
  KMF: {
    code: 'KMF',
    symbol: 'CF',
  },
  CVE: {
    code: 'CVE',
    symbol: '$',
  },
  XAF: {
    code: 'XAF',
    symbol: 'FCFA',
  },
  KHR: {
    code: 'KHR',
    symbol: '៛',
  },
  CAD: {
    code: 'CAD',
    symbol: '$',
  },
  KYD: {
    code: 'KYD',
    symbol: '$',
  },
  JOD: {
    code: 'JOD',
    symbol: 'ينار',
  },
  KES: {
    code: 'KES',
    symbol: 'KSh',
  },
  KWD: {
    code: 'KWD',
    symbol: 'ك',
  },
  LSL: {
    code: 'LSL',
    symbol: 'L',
  },
  LYD: {
    code: 'LYD',
    symbol: 'LD',
  },
  MOP: {
    code: 'MOP',
    symbol: 'MOP$',
  },
  MGA: {
    code: 'MGA',
    symbol: 'Ar',
  },
  MWK: {
    code: 'MWK',
    symbol: 'K',
  },
  MVR: {
    code: 'MVR',
    symbol: 'MRf',
  },
  MRU: {
    code: 'MRU',
    symbol: 'UM',
  },
  MDL: {
    code: 'MDL',
    symbol: 'L',
  },
  MAD: {
    code: 'MAD',
    symbol: 'DH',
  },
  MMK: {
    code: 'MMK',
    symbol: 'K',
  },
  PGK: {
    code: 'PGK',
    symbol: 'K',
  },
  RUR: {
    code: 'RUR',
    symbol: '₽',
  },
  RWF: {
    code: 'RWF',
    symbol: 'R₣',
  },
  WST: {
    code: 'WST',
    symbol: 'SAT',
  },
  STD: {
    code: 'STD',
    symbol: 'Db',
  },
  SLL: {
    code: 'SLL',
    symbol: 'Le',
  },
  SSP: {
    code: 'SSP',
    symbol: 'SS£',
  },
  SDG: {
    code: 'SDG',
    symbol: 'SDG',
  },
  TJS: {
    code: 'TJS',
    symbol: 'TJS',
  },
  TZS: {
    code: 'TZS',
    symbol: 'TSh',
  },
  TND: {
    code: 'TND',
    symbol: 'د.ت',
  },
  TOP: {
    code: 'TOP',
    symbol: 'T$',
  },
  TMT: {
    code: 'TMT',
    symbol: 'T',
  },
  UGX: {
    code: 'UGX',
    symbol: 'Ush',
  },
  AED: {
    code: 'AED',
    symbol: 'AED',
  },
  VUV: {
    code: 'VUV',
    symbol: 'VT',
  },
  HTG: {
    code: 'HTG',
    symbol: 'G',
  },
  CLP: {
    code: 'CLP',
    symbol: '$',
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
  },
  CDF: {
    code: 'CDF',
    symbol: 'FC',
  },
  COP: {
    code: 'COP',
    symbol: '$',
  },
  CRC: {
    code: 'CRC',
    symbol: '₡',
  },
  HRK: {
    code: 'HRK',
    symbol: 'kn',
  },
  CUP: {
    code: 'CUP',
    symbol: '₱',
  },
  CZK: {
    code: 'CZK',
    symbol: 'Kč',
  },
  DJF: {
    code: 'DJF',
    symbol: 'Fdj',
  },
  DKK: {
    code: 'DKK',
    symbol: 'kr',
  },
  DOP: {
    code: 'DOP',
    symbol: 'RD$',
  },
  SZL: {
    code: 'SZL',
    symbol: 'E',
  },
  ETB: {
    code: 'ETB',
    symbol: 'ብር',
  },
  ERN: {
    code: 'ERN',
    symbol: 'ናቕፋ',
  },
  XCD: {
    code: 'XCD',
    symbol: '$',
  },
  EGP: {
    code: 'EGP',
    symbol: '£',
  },
  SVC: {
    code: 'SVC',
    symbol: '$',
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
  },
  FKP: {
    code: 'FKP',
    symbol: '£',
  },
  FJD: {
    code: 'FJD',
    symbol: '$',
  },
  GHS: {
    code: 'GHS',
    symbol: '¢',
  },
  GNF: {
    code: 'GNF',
    symbol: 'FG',
  },
  GIP: {
    code: 'GIP',
    symbol: '£',
  },
  GTQ: {
    code: 'GTQ',
    symbol: 'Q',
  },
  GGP: {
    code: 'GGP',
    symbol: '£',
  },
  GYD: {
    code: 'GYD',
    symbol: '$',
  },
  HNL: {
    code: 'HNL',
    symbol: 'L',
  },
  HKD: {
    code: 'HKD',
    symbol: '$',
  },
  HUF: {
    code: 'HUF',
    symbol: 'Ft',
  },
  ISK: {
    code: 'ISK',
    symbol: 'kr',
  },
  INR: {
    code: 'INR',
    symbol: '₹',
  },
  IDR: {
    code: 'IDR',
    symbol: 'Rp',
  },
  IRR: {
    code: 'IRR',
    symbol: '﷼',
  },
  IMP: {
    code: 'IMP',
    symbol: '£',
  },
  ILS: {
    code: 'ILS',
    symbol: '₪',
  },
  JMD: {
    code: 'JMD',
    symbol: 'J$',
  },
  JPY: {
    code: 'JPY',
    symbol: '¥',
  },
  JEP: {
    code: 'JEP',
    symbol: '£',
  },
  KZT: {
    code: 'KZT',
    symbol: 'лв',
  },
  KPW: {
    code: 'KPW',
    symbol: '₩',
  },
  KRW: {
    code: 'KRW',
    symbol: '₩',
  },
  KGS: {
    code: 'KGS',
    symbol: 'лв',
  },
  LAK: {
    code: 'LAK',
    symbol: '₭',
  },
  LBP: {
    code: 'LBP',
    symbol: '£',
  },
  LRD: {
    code: 'LRD',
    symbol: '$',
  },
  MKD: {
    code: 'MKD',
    symbol: 'ден',
  },
  MYR: {
    code: 'MYR',
    symbol: 'RM',
  },
  MUR: {
    code: 'MUR',
    symbol: '₨',
  },
  MXN: {
    code: 'MXN',
    symbol: '$',
  },
  MNT: {
    code: 'MNT',
    symbol: '₮',
  },
  MZN: {
    code: 'MZN',
    symbol: 'MT',
  },
  NAD: {
    code: 'NAD',
    symbol: '$',
  },
  NPR: {
    code: 'NPR',
    symbol: '₨',
  },
  ANG: {
    code: 'ANG',
    symbol: 'ƒ',
  },
  NZD: {
    code: 'NZD',
    symbol: '$',
  },
  NIO: {
    code: 'NIO',
    symbol: 'C$',
  },
  NGN: {
    code: 'NGN',
    symbol: '₦',
  },
  NOK: {
    code: 'NOK',
    symbol: 'kr',
  },
  OMR: {
    code: 'OMR',
    symbol: '﷼',
  },
  PKR: {
    code: 'PKR',
    symbol: '₨',
  },
  PAB: {
    code: 'PAB',
    symbol: 'B/.',
  },
  PYG: {
    code: 'PYG',
    symbol: 'Gs',
  },
  PEN: {
    code: 'PEN',
    symbol: 'S/.',
  },
  PHP: {
    code: 'PHP',
    symbol: '₱',
  },
  PLN: {
    code: 'PLN',
    symbol: 'zł',
  },
  QAR: {
    code: 'QAR',
    symbol: '﷼',
  },
  RON: {
    code: 'RON',
    symbol: 'lei',
  },
  RUB: {
    code: 'RUB',
    symbol: '₽',
  },
  SHP: {
    code: 'SHP',
    symbol: '£',
  },
  SAR: {
    code: 'SAR',
    symbol: '﷼',
  },
  RSD: {
    code: 'RSD',
    symbol: 'Дин.',
  },
  SCR: {
    code: 'SCR',
    symbol: '₨',
  },
  SGD: {
    code: 'SGD',
    symbol: '$',
  },
  SBD: {
    code: 'SBD',
    symbol: '$',
  },
  SOS: {
    code: 'SOS',
    symbol: 'S',
  },
  ZAR: {
    code: 'ZAR',
    symbol: 'R',
  },
  LKR: {
    code: 'LKR',
    symbol: '₨',
  },
  SEK: {
    code: 'SEK',
    symbol: 'kr',
  },
  CHF: {
    code: 'CHF',
    symbol: 'CHF',
  },
  SRD: {
    code: 'SRD',
    symbol: '$',
  },
  SYP: {
    code: 'SYP',
    symbol: '£',
  },
  TWD: {
    code: 'TWD',
    symbol: 'NT$',
  },
  THB: {
    code: 'THB',
    symbol: '฿',
  },
  TTD: {
    code: 'TTD',
    symbol: 'TT$',
  },
  TRY: {
    code: 'TRY',
    symbol: '₺',
  },
  TVD: {
    code: 'TVD',
    symbol: '$',
  },
  UAH: {
    code: 'UAH',
    symbol: '₴',
  },
  IQD: {
    code: 'IQD',
    symbol: 'د.ع',
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
  },
  USD: {
    code: 'USD',
    symbol: '$',
  },
  UYU: {
    code: 'UYU',
    symbol: '$U',
  },
  UZS: {
    code: 'UZS',
    symbol: 'лв',
  },
  VEF: {
    code: 'VEF',
    symbol: 'Bs',
  },
  VES: {
    code: 'VES',
    symbol: 'Bs',
  },
  VND: {
    code: 'VND',
    symbol: '₫',
  },
  YER: {
    code: 'YER',
    symbol: '﷼',
  },
  XOF: {
    code: 'XOF',
    symbol: 'CFA',
  },
  XPF: {
    code: 'XPF',
    symbol: 'F',
  },
  GMD: {
    code: 'GMD',
    symbol: 'D',
  },
  GEL: {
    code: 'GEL',
    symbol: '₾',
  },
  ZMW: {
    code: 'ZMW',
    symbol: 'ZK',
  },
} as const;

export type CurrencyCode = keyof typeof CURRENCY_MAP;
export type CurrencyOption = {
  code: GQCurrencyCode;
  symbol: string;
  name: string;
  longName: string; // {name} ({code})
};
export type CurrencySymbol = (typeof CURRENCY_MAP)[CurrencyCode]['symbol'];

const getIntlDisplayNames = memoize(
  (locale: SupportedLocale) =>
    new Intl.DisplayNames([locale], { type: 'currency' })
);

export const getCurrencyName = memoize(
  (currencyCode: CurrencyCode, locale: SupportedLocale) => {
    if (locale === PSEUDO_LOCALE) {
      return pseudoLocalize(
        getIntlDisplayNames(DEFAULT_LOCALE).of(currencyCode) ?? ''
      );
    }

    return getIntlDisplayNames(locale).of(currencyCode) ?? '';
  },
  (currencyCode, locale) => `${currencyCode}-${locale}`
);

export function getCurrencies(
  locale: SupportedLocale = i18n.locale as SupportedLocale
): Array<{
  id: GQCurrencyCode;
  name: string; // long name e.g. "US Dollar (USD)"
}> {
  return Object.values(CURRENCY_MAP).map(({ code }) => {
    const name = getCurrencyName(code, locale);
    return {
      id: code,
      name: `${name} (${code})`,
    };
  });
}

export function currencyFromCode(
  currency?: CurrencyCode | string | null,
  locale: SupportedLocale = i18n.locale as SupportedLocale
): CurrencyOption {
  const currencyCode = currency ?? 'USD';
  assertIsValidCurrencyCode(currencyCode);

  const name = getCurrencyName(currencyCode, locale);
  return {
    ...CURRENCY_MAP[currencyCode],
    name,
    longName: `${name} (${currencyCode})`,
  };
}

// This function takes in a currency code or string ('USD' or 'GBP'),
// and returns the symbol ('$' for example).
export function currencySymbolFromCode(
  currency?: CurrencyCode | string | null
): CurrencySymbol {
  if (!currency) {
    return CURRENCY_MAP.USD.symbol;
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function optionalCurrencySymbolFromCode(
  currency?: CurrencyCode | null
): CurrencySymbol {
  if (!currency) {
    return '';
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function isValidCurrencyCode(
  currency?: CurrencyCode | string | null
): currency is CurrencyCode {
  return !!currency && CURRENCY_MAP.hasOwnProperty(currency);
}

export function convertToCurrencyCode(
  str: string | undefined
): CurrencyCode | null {
  if (isValidCurrencyCode(str)) return str;
  return null;
}

export function assertIsValidCurrencyCode(
  currency: CurrencyCode | string
): asserts currency is keyof typeof CURRENCY_MAP {
  assertKeyOf(currency, CURRENCY_MAP);
}

export function getCurrencyForOrg({
  currency,
}: {
  currency: string | null;
}): CurrencyCode {
  const code = currency ?? 'USD';
  assertIsValidCurrencyCode(code);
  return code;
}

export function allCurrencyCodes(): Array<CurrencyCode> {
  return getObjectKeys(CURRENCY_MAP);
}
