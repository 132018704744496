import Box, { type BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles/createTheme';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Cash from '@watershed/icons/components/Cash';
import Categories from '@watershed/icons/components/Categories';
import Flag from '@watershed/icons/components/Flag';
import Folder from '@watershed/icons/components/Folder';
import Gear from '@watershed/icons/components/Gear';
import Reductions from '@watershed/icons/components/Reductions';
import SetSquare from '@watershed/icons/components/SetSquare';
import Shoe from '@watershed/icons/components/Shoe';
import ShoppingBasketIcon from '@watershed/icons/components/ShoppingBasket';
import { GQOrganization } from '@watershed/shared-universal/generated/graphql';
import BoxLink from '@watershed/ui-core/components/BoxLink';
import {
  routeForFinance,
  routeForMarketplaceIndex,
  routeForOrgSpecificMethodologyData,
  routeForSupplyChainCharts,
  urlForObject,
} from '@watershed/shared-universal/adminRoutes';
import InboxIcon from '@watershed/icons/components/Inbox';
import DraftsIcon from '@watershed/icons/components/Drafts';
import ToolIcon from '@watershed/icons/components/Tool';

interface OrgNavItem {
  to: (orgId: string) => string;
  label: string;
  icon: React.ReactNode;
  bg: string;
  showInAbbreviated?: boolean;
}

export const ORG_NAV_ITEMS: Array<OrgNavItem> = [
  {
    to: (orgId) => urlForObject('OrganizationFootprints', orgId),
    label: 'Footprint',
    icon: <Shoe />,
    bg: 'error.dark',
    showInAbbreviated: true,
  },
  {
    to: (orgId) => urlForObject('OrganizationReports', orgId),
    label: 'Reports',
    icon: <Categories />,
    bg: 'error.main',
  },
  {
    to: (orgId) => urlForObject('OrganizationFlags', orgId),
    label: 'Feature flags',
    icon: <Flag />,
    bg: 'warning.dark',
  },
  {
    to: routeForFinance,
    label: 'Finance',
    icon: <Cash />,
    bg: 'marigold',
  },
  {
    to: (orgId) => urlForObject('OrganizationDatasets', orgId),
    label: 'Datasets',
    icon: <SetSquare />,
    bg: 'primary.dark',
    showInAbbreviated: true,
  },
  {
    to: (orgId) => routeForOrgSpecificMethodologyData(orgId),
    label: 'Methodology',
    icon: <ToolIcon />,
    bg: 'warning.dark',
  },
  {
    to: (orgId) => urlForObject('OrganizationPlans', orgId),
    label: 'Reductions',
    icon: <Reductions />,
    bg: 'success.dark',
  },
  {
    to: (orgId) => urlForObject('OrganizationFiles', orgId),
    label: 'Files',
    icon: <Folder />,
    bg: 'info.main',
  },
  {
    to: (orgId) => urlForObject('OrganizationEmails', orgId),
    label: 'Emails',
    icon: <InboxIcon />,
    bg: 'purple',
  },
  {
    to: (orgId) => urlForObject('OrganizationMappings', orgId),
    label: 'Mappings',
    icon: <Gear />,
    bg: 'secondary.dark',
  },
  {
    to: (orgId) => routeForMarketplaceIndex({ orgId }),
    label: 'Purchases',
    icon: <ShoppingBasketIcon />,
    bg: 'slate',
  },
  {
    to: (orgId) => routeForSupplyChainCharts(orgId),
    label: 'Supply Chain',
    icon: <DraftsIcon />,
    bg: 'purple',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quickLinkItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      textDecoration: 'none',
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        opacity: 0.8,
      },
    },
    quickLinkItemIcon: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 12,
      color: theme.palette.common.white,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  })
);

export function OrgQuickLink({
  to,
  bg,
  icon,
  label,
}: {
  to: string;
  bg: string;
  icon: React.ReactNode;
  label: string;
}) {
  const classes = useStyles();
  return (
    <BoxLink href={to} className={classes.quickLinkItem}>
      <Tooltip title={label}>
        <Box bgcolor={bg} className={classes.quickLinkItemIcon}>
          {icon}
        </Box>
      </Tooltip>
    </BoxLink>
  );
}

export default function OrgQuickLinks({
  org,
  abbreviated,
  children,
  ...props
}: BoxProps & {
  org: Pick<GQOrganization, 'id' | 'name'>;
  abbreviated?: boolean;
}) {
  return (
    <Box display="flex" gap={0.5} {...props}>
      {ORG_NAV_ITEMS.map(
        (navItem) =>
          (!abbreviated || navItem.showInAbbreviated) && (
            <OrgQuickLink
              key={navItem.label}
              to={navItem.to(org.id)}
              label={`${org.name}: ${navItem.label}`}
              icon={navItem.icon}
              bg={navItem.bg}
            />
          )
      )}
      {children}
    </Box>
  );
}
