import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Theme } from '@mui/material/styles/createTheme';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Skeleton from '@watershed/ui-core/components/Skeleton';
import Link from '@watershed/ui-core/components/TextLink';
import IconButton from '@watershed/ui-core/components/IconButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@watershed/icons/components/Close';
import QuestionMarkIcon from '@watershed/icons/components/QuestionMark';
import InboxIcon from '@watershed/icons/components/Inbox';
import LogOutIcon from '@watershed/icons/components/LogOut';
import LoopedSquareIcon from '@watershed/icons/components/LoopedSquare';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import useLogout from '@watershed/shared-frontend/hooks/useLogout';
import { urlForObject } from '@watershed/shared-universal/adminRoutes';
import { GQOrgFieldsForAdminNavFragment } from '@watershed/shared-universal/generated/graphql';
import Button from '@watershed/ui-core/components/Button';
import Logo from '@watershed/ui-core/components/Logo';
import UnstyledLink from '@watershed/ui-core/components/UnstyledLink';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Fragment, useEffect } from 'react';
import useKey from 'react-use/lib/useKey';
import { ORGANIZATION_INBOX_DIALOG_QUERY_PARAM } from '../constants';
import { useAdminContext } from './AdminContext';
import OrgQuickLinks from './OrgQuickLinks';
import formatOrgName from '@watershed/shared-universal/utils/formatOrgName';
import GridIcon from '@watershed/icons/components/Grid';
import { LoginAsButtons } from './LoginAsButtons';

const OrganizationInbox = dynamic(() => import('./OrganizationInbox'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      marginRight: 8,
      verticalAlign: 'middle',
      transition: 'transform 160ms ease-out',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    toolbarItem: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        flexShrink: 0,
        width: 32,
        height: 18,
        marginRight: theme.spacing(1),
        opacity: 0.5,
      },
      '&[disabled]': {
        color: theme.palette.grey30,
      },
    },
    toolbarItemOrg: {
      '& span': {
        fontWeight: 700,
      },
    },
    iconButton: {
      color: theme.palette.common.white,
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      '&:focus, &:active': {
        backgroundColor: theme.palette.text.primary,
      },
    },
    skeletonDark: {
      backgroundColor: 'rgb(215 219 225 / 25%)',
    },
  })
);

export default function NavBar({
  org,
  title,
  breadcrumbs,
  onQuickSwitch,
  onOpenQuickLinks,
  helpLink,
  helpLinkDescription,
}: {
  org: GQOrgFieldsForAdminNavFragment | null;
  title?: string;
  breadcrumbs: Array<{ title: string; url?: string }>;
  onQuickSwitch?: () => void;
  onOpenQuickLinks?: () => void;
  helpLink?: string;
  helpLinkDescription?: string;
}) {
  const classes = useStyles();
  const [orgInboxDialog] = useQueryParam(ORGANIZATION_INBOX_DIALOG_QUERY_PARAM);
  const { openSideDrawer, setOpenSideDrawer } = useAdminContext();
  const handleLogout = useLogout();
  const isProduction = process.env.NODE_ENV === 'production';

  // If a dialog from the inbox is specified in the URL, default the org drawer
  // to open so the dialog appears on page load.
  // TODO: *don't* open the drawer if the user is on the OrganizationPage.
  useEffect(() => {
    if (orgInboxDialog) {
      setOpenSideDrawer('inbox');
    }
  }, [orgInboxDialog, setOpenSideDrawer]);

  useKey(
    (evt) => evt.key === 'h' && (evt.ctrlKey || evt.metaKey),
    (evt) => {
      evt.preventDefault();
      setOpenSideDrawer((d) => (d === 'context' ? null : 'context'));
    }
  );
  useKey(
    (evt) => evt.key === 'j' && (evt.ctrlKey || evt.metaKey),
    (evt) => {
      evt.preventDefault();
      setOpenSideDrawer((d) => (d === 'inbox' ? null : 'inbox'));
    }
  );

  const orgDisplayName = org ? formatOrgName(org) : '';
  const titleParts: Array<string> = [];
  if (title) {
    titleParts.push(title);
  } else if (breadcrumbs.length > 0) {
    titleParts.push(breadcrumbs[breadcrumbs.length - 1].title);
  }
  if (org) {
    titleParts.push(orgDisplayName);
  }

  return (
    <AppBar
      position="sticky"
      sx={(theme) => ({
        // update the navbar color when not on production
        backgroundColor: isProduction
          ? theme.palette.text.primary
          : window.location.hostname.startsWith('proxy')
            ? // make especially obvious when on a prod proxy
              theme.palette.error.dark
            : // or a more gentle custom lavender on normal localhost
              // eslint-disable-next-line @watershed/no-custom-colors
              '#655399',
        zIndex: theme.zIndex.drawer + 1,
      })}
    >
      <Head>
        <title>
          {titleParts.length > 0 ? titleParts.join(' – ') : 'Admin'}
        </title>
      </Head>
      <Box paddingX={2} paddingY={1} display="flex" alignItems="center">
        <UnstyledLink href="/">
          <a>
            <Logo size={24} className={classes.logo} color="white" />
          </a>
        </UnstyledLink>
        {org && (
          <Button
            href={urlForObject('Organization', org.id)}
            color="inherit"
            variant="text"
            className={classes.toolbarItemOrg}
          >
            {orgDisplayName}
          </Button>
        )}
        {breadcrumbs.length === 0 && org === null && (
          <Box width={200}>
            <Skeleton className={classes.skeletonDark} />
          </Box>
        )}
        {breadcrumbs.map((crumb, i) => (
          <Fragment key={`${crumb.url} ${crumb.title}`}>
            {i > 0 && <div>/</div>}
            {crumb.url ? (
              <Button
                href={crumb.url}
                color="inherit"
                variant="text"
                className={classes.toolbarItem}
              >
                {crumb.title}
              </Button>
            ) : (
              <Button
                color="inherit"
                variant="text"
                disabled
                className={classes.toolbarItem}
              >
                {crumb.title}
              </Button>
            )}
          </Fragment>
        ))}
        <div style={{ flexGrow: 1 }} />
        {org && (
          <>
            <Tooltip title="Organization inbox (Cmd-J)">
              <IconButton
                onClick={() => setOpenSideDrawer('inbox')}
                className={classes.iconButton}
                size="large"
              >
                <InboxIcon />
              </IconButton>
            </Tooltip>
            <OrgInboxDrawer
              open={openSideDrawer === 'inbox'}
              onClose={() => setOpenSideDrawer(null)}
              org={org}
            />
          </>
        )}
        {helpLink && (
          <Tooltip title={helpLinkDescription ?? 'Guides and documentation'}>
            <Link href={helpLink} sx={{ color: 'white', marginBottom: -1 }}>
              <QuestionMarkIcon />
            </Link>
          </Tooltip>
        )}
        <Tooltip title="Quick links… (Cmd + .)">
          <IconButton
            onClick={onOpenQuickLinks}
            className={classes.iconButton}
            size="large"
          >
            <GridIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Jump to… (Cmd + k)">
          <IconButton
            onClick={onQuickSwitch}
            className={classes.iconButton}
            size="large"
          >
            <LoopedSquareIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Log out">
          <IconButton
            onClick={handleLogout}
            className={classes.iconButton}
            size="large"
          >
            <LogOutIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </AppBar>
  );
}

function OrgInboxDrawer({
  open,
  onClose,
  org,
}: {
  open: boolean;
  onClose: () => void;
  org: GQOrgFieldsForAdminNavFragment;
}) {
  return (
    <SideDrawer open={open} onClose={onClose}>
      <Box component="header" display="flex" alignItems="center">
        <Typography variant="h1">{org.name}</Typography>
        <Box flexGrow={1} />
        <OrgQuickLinks org={org} flexWrap="wrap" alignItems="center" gap={0.25}>
          <LoginAsButtons targetOrgId={org.id} disabled={!org.hasUsers}>
            Login
          </LoginAsButtons>
          <IconButton onClick={onClose} style={{ padding: 4 }} size="large">
            <CloseIcon size={24} />
          </IconButton>
        </OrgQuickLinks>
      </Box>
      <Box
        component="section"
        position="relative"
        marginTop={2}
        paddingTop={2}
        borderTop={1}
        borderColor="divider"
      >
        <OrganizationInbox
          orgId={org.id}
          compact
          tableProps={{ marginX: -2, marginTop: 3 }}
        />
      </Box>
    </SideDrawer>
  );
}

function SideDrawer({
  open,
  onClose,
  children,
}: React.PropsWithChildren<{ open: boolean; onClose: () => void }>) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 'clamp(842px, 50vw, 1536px)',
          padding: 2,
        },
        onContextMenu: (e: PointerEvent) => e.preventDefault(),
      }}
      slotProps={{
        root: {
          style: {
            zIndex: 1300,
          },
        },
      }}
    >
      {children}
    </Drawer>
  );
}
