import { forwardRef, isValidElement, type ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import {
  SnackbarContent,
  type CustomContentProps,
  SnackbarProvider,
  type SnackbarProviderProps,
  type VariantType,
} from 'notistack';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import StatusCriticalIcon from '@watershed/icons/components/StatusCritical';
import StatusMediumIcon from '@watershed/icons/components/StatusMedium';
import StatusStableNewIcon from '@watershed/icons/components/StatusStableNew';
import StatusInformationIcon from '@watershed/icons/components/StatusInformation';
import StatusCompleteIcon from '@watershed/icons/components/StatusComplete';
// TODO: For some reason if you want to import the type from the
// package you need to use the following import and not react/macro.
// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/react';

const variantStyles = {
  success: (theme: Theme) => ({
    backgroundColor: theme.palette.spring05,
    color: theme.palette.spring100,
    border: `thin solid ${theme.palette.spring70}60`,
    '& .MuiStack-root > svg': {
      width: 14,
      height: 14,
      fill: theme.palette.spring70,
    },
  }),
  error: (theme: Theme) => ({
    backgroundColor: theme.palette.sun05,
    color: theme.palette.error.dark,
    border: `thin solid ${theme.palette.sun}60`,
    '& .MuiStack-root > svg': {
      width: 12,
      height: 12,
    },
  }),
  warning: (theme: Theme) => ({
    backgroundColor: theme.palette.marigold05,
    border: `thin solid ${theme.palette.marigold70}60`,
    color: theme.palette.marigold100,
    '& .MuiStack-root > svg': {
      fill: theme.palette.marigold70,
      width: 13,
      height: 13,
    },
  }),
  info: (theme: Theme) => ({
    backgroundColor: theme.palette.cobalt05,
    color: theme.palette.cobalt100,
    border: `thin solid ${theme.palette.cobalt40}60`,
    '& .MuiStack-root > svg': {
      fill: theme.palette.cobalt50,
      width: 11,
      height: 11,
    },
  }),
  default: (theme: Theme) => ({
    backgroundColor: theme.palette.white,
    color: theme.palette.grey100,
    border: `thin solid ${theme.palette.grey30}`,
    '& .MuiStack-root > svg': {
      fill: theme.palette.grey50,
      width: 11,
      height: 11,
    },
  }),
} as const;

const baseStyles = (theme: Theme) =>
  ({
    backgroundColor: theme.palette.grey100,
    color: theme.palette.common.white,
    borderRadius: '10px',
    minWidth: '320px',
    maxWidth: '512px',
    fontSize: '14px',
    padding: '12px 16px',
    boxShadow: '0px 4px 8px 0px rgba(79, 89, 110, 0.08)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
  }) as const;

const StyledContent = styled(Stack)(({ theme }) => ({
  ...baseStyles(theme),
  '&[data-variant="success"]': variantStyles.success(theme),
  '&[data-variant="error"]': variantStyles.error(theme),
  '&[data-variant="warning"]': variantStyles.warning(theme),
  '&[data-variant="info"]': variantStyles.info(theme),
  '&[data-variant="default"]': variantStyles.default(theme),
}));

const stackStyles: SxProps<Theme> = {
  width: '100%',
  flexShrink: 1,
} as const;

const iconContainerStyles: SxProps<Theme> = {
  height: 24,
  width: 16,
  justifyContent: 'center',
  alignItems: 'center',
} as const;

const messageTypographyStyles: SxProps<Theme> = {
  fontFamily: 'inherit',
  color: 'inherit',
  textWrap: 'pretty',
  width: '100%',
} as const;

interface WatershedSnackbarCustomProps {
  testId?: string;
}

// Extend the notistack options
declare module 'notistack' {
  interface OptionsObject extends WatershedSnackbarCustomProps {}
}

interface WatershedSnackbarProps
  extends CustomContentProps,
    WatershedSnackbarCustomProps {}

const variantIcon: Record<VariantType, React.ComponentType> = {
  success: StatusCompleteIcon,
  error: StatusCriticalIcon,
  warning: StatusMediumIcon,
  info: StatusInformationIcon,
  default: StatusStableNewIcon,
} as const;

const WatershedSnackbar = forwardRef<HTMLDivElement, WatershedSnackbarProps>(
  ({ id, message, action, variant = 'default', testId, ...other }, ref) => {
    const Icon = variantIcon[variant];
    const isSimpleMessage =
      typeof message === 'string' ||
      (isValidElement(message) && message.type === Trans);

    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <StyledContent
          data-variant={variant}
          data-test={variant === 'error' ? TestIds.ErrorStateSnackbar : testId}
        >
          <Stack
            gap={1}
            alignItems="flex-start"
            direction="row"
            sx={stackStyles}
          >
            <Stack sx={iconContainerStyles}>
              <Icon />
            </Stack>
            {isSimpleMessage ? (
              <Typography variant="body1" sx={messageTypographyStyles}>
                {message}
              </Typography>
            ) : (
              <Stack sx={stackStyles}>{message}</Stack>
            )}
          </Stack>
          {typeof action === 'function' ? (id ? action(id) : null) : action}
        </StyledContent>
      </SnackbarContent>
    );
  }
);

WatershedSnackbar.displayName = 'WatershedSnackbar';

interface WatershedSnackbarProviderProps extends SnackbarProviderProps {
  autoHideDuration?: number;
  children: ReactNode;
  persist?: boolean;
}

const DEFAULT_SNACKBAR_CONFIG = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  maxSnack: 4,
  autoHideDuration: 3000,
  persist: false,
} as const;

const SNACKBAR_COMPONENTS = Object.fromEntries(
  ['default', 'error', 'success', 'warning', 'info'].map((variant) => [
    variant,
    WatershedSnackbar,
  ])
) as Record<VariantType, typeof WatershedSnackbar>;

function WatershedSnackbarProvider({
  children,
  anchorOrigin = DEFAULT_SNACKBAR_CONFIG.anchorOrigin,
  maxSnack = DEFAULT_SNACKBAR_CONFIG.maxSnack,
  autoHideDuration = DEFAULT_SNACKBAR_CONFIG.autoHideDuration,
  persist = DEFAULT_SNACKBAR_CONFIG.persist,
  ...rest
}: WatershedSnackbarProviderProps) {
  return (
    <SnackbarProvider
      maxSnack={maxSnack}
      autoHideDuration={persist ? null : autoHideDuration}
      Components={SNACKBAR_COMPONENTS}
      anchorOrigin={anchorOrigin}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  );
}

export { WatershedSnackbarProvider };
