import { TSchema } from '../../meta';
import {
  CLEAN_POWER_CONTRACTUAL_INSTRUMENTS,
  PIPELINE_ACCEPTED_GHG_CATEGORY_IDS,
} from '../../../constants';
import { processKinds } from '../../../emissionsModel/emissionsModelSchema';
import { ALL_BUSINESS_CATEGORIES } from '../../../utils/BusinessCategory';
import { getKnownUnits } from '../../../utils/units';
import dedent from 'ts-dedent';
import { getAllEmissionsKindDefinitions } from '../../../emissionsModel/emissionsKindDefinitions';
import { getAllPlrEnabledResultKindDefinitions } from '../../../emissionsModel/resultKindToPlr';

const quantityFields = [
  ...getAllEmissionsKindDefinitions().map((k) => k.sqlQuantityField),
  ...getAllPlrEnabledResultKindDefinitions().map((k) => k.sqlQuantityField),
];

const quantityFieldsValidationLscript = `or(${quantityFields
  .map((x) => `${x} != null`)
  .join(', ')})`;

export const PipelineResultSchema = {
  slug: 'pipeline_result:13.7',
  schema: {
    properties: {
      month: {
        type: 'timestamp',
        nullable: false,
        description:
          'A date representing the month associated with the activity.',
      },
      ghg_category_id: {
        type: 'enum',
        nullable: true,
        description: "The activity's GHG scope and category.",
        options: PIPELINE_ACCEPTED_GHG_CATEGORY_IDS,
        examples: ['1 Scope 1', '3.2 Capital goods'],
      },
      category_id: {
        type: 'enum',
        nullable: true,
        description:
          "The first level of Watershed's business category taxonomy.",
        options: ALL_BUSINESS_CATEGORIES,
        examples: ['apparel', 'restaurants', 'offices', 'employees'],
      },
      subcategory_id: {
        type: 'string',
        nullable: true,
        description:
          "The second level of Watershed's business category taxonomy.",
        examples: ['Lounge Pants', 'blueberry-frozen', 'Customer relations'],
      },
      description: {
        type: 'string',
        nullable: true,
        description:
          "The third level of Watershed's business category taxonomy.",
        examples: [
          'Overnight Oats Mixed Berry 4.2 oz',
          'CloudFront',
          'Treats for the team',
        ],
      },
      location: {
        type: 'string',
        nullable: true,
        description:
          'A string consolidating all of the location information we have about the emissive activity.',
        examples: ['Yellowstone National Park, US', 'Bundaberg, AU-QLD, AU'],
      },
      deduplication_id: {
        type: 'string',
        nullable: true,
        description:
          'A string used for deduplication during the emissions calculation. This value generally should not be used outside of the calculation process.',
      },
      org_id: {
        type: 'string',
        nullable: false,
        description:
          'The unique ID of the `organization` record for the customer that produced the emissions.',
        examples: ['org_2zJ3aHRG9Q7S2aAxxxxx'],
      },
      location_city: {
        type: 'string',
        nullable: true,
        description: 'The city where the emissive activity occurred.',
        examples: ['Tucson', 'Paris'],
      },
      location_state: {
        type: 'string',
        nullable: true,
        description:
          'The ISO 3166 subdivision code for where the emissive activity occurred.',
        examples: ['BR-AC', 'AO-CCU'],
      },
      location_grid: {
        type: 'string',
        nullable: true,
        description:
          'An identifier for the electrical grid where the emissive activity occurred, such as an eGRID code or brief name. Only used for electricity emissions.',
        examples: ['SERC', 'AKMS', 'Europe without Switzerland'],
      },
      location_country: {
        type: 'string',
        nullable: true,
        description:
          'The ISO 3166 country code for where the emissive activity occurred.',
        examples: ['BR', 'AO'],
      },
      raw_vendor_entity: {
        type: 'string',
        nullable: true,
        description: "The vendor name as provided by Watershed's customer.",
        examples: ["Mom's Organic Market", 'Japanica'],
      },
      vendor_entity: {
        type: 'string',
        nullable: true,
        description:
          "The first level of Watershed's internal taxonomy for identifying vendors and linking them to `vendor` and `company` records. It's the 'cleaned' version of `raw_vendor_entity`, with the cleaning done via Watershed's automated code or manually.",
        examples: ["Mom's Organic Market", 'Japanica'],
        deprecationReason: 'Use `company_id` or `raw_vendor_entity` instead',
      },
      vendor_subentity: {
        type: 'string',
        nullable: true,
        description:
          "The second level of Watershed's internal taxonomy for identifying vendors and linking them to `vendor` and `company` records.",
        examples: ['AWS', 'Xcel Energy'],
        deprecationReason: 'Use `company_id` or `raw_vendor_entity` instead',
      },
      vendor_program: {
        type: 'string',
        nullable: true,
        description: "The name of the vendor's climate program.",
        examples: ['Net zero target', 'Science Based Target'],
      },
      entity: {
        type: 'string',
        nullable: true,
        description:
          'A generic tag rendered in Drilldown. For example, for buildings activity data, `entity` might be a building identifier.',
        examples: [
          '20 Rue Beaubourg, 75203 Paris, France',
          'Wise Brasil Pagamentos Ltda',
        ],
      },
      reporting_entity: {
        type: 'string',
        nullable: true,
        description: 'Same as entity. A generic tag rendered in Drilldown.',
      },
      product: {
        type: 'string',
        nullable: true,
        description: 'A generic tag rendered in Drilldown.',
        examples: ['marjoram', 'Cashew Halves & Pieces Roast 12 oz'],
      },
      custom_dimensions: {
        type: 'string',
        nullable: true,
        description:
          'A JSON object of custom dimensions that are used for calculation methodologies or for data slicing.',
        examples: [
          '{"product": "BCH"}',
          '{"description": "Product - Upstream", "product": "Blueberry"}',
        ],
      },
      emissions_specifier: {
        type: 'string',
        nullable: true,
        description:
          'The emissions specifier of the CADT row used in the calculation.',
        examples: ['logistics_downstream', 'cloud_compute'],
      },
      emissions_subspecifier: {
        type: 'string',
        nullable: true,
        description:
          'The emissions subspecifier of the CADT row used in the calculation.',
        examples: ['natural_gas', 't3small', 'air_travel'],
      },
      file_metadata_ids: {
        type: 'string',
        nullable: true,
        description:
          'A JSON array of the IDs of the `file_metadata` records pointing to the source data for this row.',
        examples: [
          '["fmd_2ZQ3EDavtsyxxxUEFXke"]',
          '["fmd_2Mi3LPR7H1E2jApxxxeT", "fmd_2Mi3xxxb6inKHLAFXfKL"] ',
        ],
      },
      conversion_factor_id: {
        type: 'string',
        nullable: true,
        description:
          'The `conversion_factor_id` of the conversion factor used in the calculation.',
        examples: ['5715231d4ff139053da7eb7fe803cxxx'],
      },
      vendor_id: {
        type: 'string',
        nullable: true,
        description: 'The ID of the associated `vendor` record.',
        examples: ['ven_2233RJ3n87kfpNaYoH6Y'],
      },
      company_id: {
        type: 'string',
        nullable: true,
        description: 'The ID of the associated `company` record.',
        examples: ['comp_2233bMuqu5pkt67PZ5kp'],
      },
      emissions_factor_id: {
        type: 'string',
        nullable: true,
        description:
          'The `emissions_factor_id` of the emissions factor used in the calculation.',
        examples: ['76244bb2-3d25-4d32-919d-77b766927xxx'],
      },
      emissions_factor_uuid: {
        type: 'string',
        nullable: true,
        description:
          'The unique Postgres ID of the specific `emissions_factor` record used in the calculation.',
        examples: ['ef_2qB3YTHQij3tjcYAVxxx'],
      },
      is_electricity_market_ef: {
        type: 'boolean',
        nullable: true,
        description:
          'Indicates if the emissions factor used in the calculation is for an market-based electricity allocation method, as opposed to a location-based one. Only used for electricity emissions.',
      },
      is_clean_power: {
        type: 'boolean',
        nullable: true,
        description:
          'Indicates if the row represents clean electricity. Only used for electricity emissions.',
      },
      ef_name: {
        type: 'string',
        nullable: true,
        description:
          'The `name` column from the `emissions_factor` record used in the emissions calculation.',
        examples: ['air transport', 'Food and Drink Composting'],
      },
      ef_kg_co2e: {
        type: 'number',
        nullable: true,
        description:
          'The `kg_co2e` column from the `emissions_factor` record used in the emissions calculation.',
      },
      emissions_factor_source_id: {
        type: 'string',
        nullable: true,
        description:
          'The `assumption_source_id` column from the `emissions_factor` record used in the calculation.',
        examples: ['aps_2273CWuLNDi5oo9xxxPV'],
      },
      unit: {
        type: 'enum',
        nullable: false,
        description:
          "The unit that is used for the emissions calculation. This is what we have after we've applied the conversion factor and before we've applied the emissions factor.",
        options: [...getKnownUnits()],
        examples: ['kwh', 'employees'],
      },
      input_unit: {
        type: 'enum',
        nullable: true,
        description:
          'For PipelineV2: the unit specified in the source CADT row, before any further conversions. For Emissions Models: this is the unit specified in the source BART row in terms of legacy units, before any further conversions or transformations.',
        options: [...getKnownUnits()],
        examples: ['kwh', 'employees'],
      },
      source_id: {
        type: 'string',
        nullable: true,
        description:
          'An imperfect pointer to the input UUT row that was the source of this record. Replaced with `source_row` and `source_uut_ids`.',
        deprecationReason: 'Use source_uut_ids instead',
      },
      source_uut_ids: {
        type: 'string',
        nullable: true,
        description:
          'A JSON array of IDs for `user_upload_table` records that provided source data for this record.',
        examples: [
          '["uut_2Wz3d6UGvQmFoExxxEWf"]',
          '["uut_1axxxNndG6FRaoJFEe1e", "uut_1aE2LNndj23KhThsSxxx"]',
        ],
      },
      source_row: {
        type: 'integer',
        nullable: true,
        description:
          'The row number of the UUT row that provided source data for this record. If there are multiple source UUTs, the value will be -2. If no source UUTs were specified, the value will be -1.',
      },
      uuid: {
        type: 'string',
        nullable: true,
        description: 'The `uuid` of the CADT source row.',
        examples: ['adt_22g3HiWtyZsTFCDWRbR3-4288'],
      },
      conversion_timestamp: {
        type: 'timestamp',
        nullable: true,
        description:
          'A timestamp indicating when the conversion and emissions factors were selected for this calculation. This timestamp will persist across stable pipeline runs. Can be an ISO 8601 string timestamp, numeric timestamp, or other date format appropriate to the language. If a timezone is specified, it is UTC; if no timezone is specified the value is interpreted as UTC.',
      },
      quantity_kg_co2e: {
        type: 'number',
        nullable: true,
        description: 'The quantity of emissions, measured in kgCO2e.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kgco2e',
        },
      },
      quantity: {
        type: 'number',
        nullable: false,
        description:
          "The activity quantity that is used for the emissions calculation. This is what we have after we've applied the conversion factor, and before we've applied the emissions factor.",
      },
      input_quantity: {
        type: 'number',
        nullable: true,
        description:
          'For PipelineV2: the quantity specified in the source CADT row, before any further conversions. For Emissions Models: the quantity specified in the source BART row, before any further conversions or transformations.',
      },
      adt_id: {
        type: 'string',
        nullable: true,
        description:
          "The ID of the `activity_data_table` whose CADT contains this record's source CADT row.",
        examples: ['adt_2583jrvt2t5pHC2T8PNL'],
      },
      adt_name: {
        type: 'string',
        nullable: true,
        description:
          "The human-readable name of the activity data table whose CADT contains this record's activity data source row.",
        examples: [
          'some_org.adt_buildings_201901_202012',
          'blorque.downstream_logistics_2021_h1',
        ],
      },
      footprint_kind: {
        type: 'string',
        nullable: false,
        description:
          "Used to differentiate multiple footprints in a customer's Drilldown.",
        examples: ['corporate_footprint', 'third_party_crypto'],
      },
      collapsed_vendor_entity: {
        type: 'string',
        nullable: true,
        description:
          'If there is fewer than $25k ($100k prior to 10/26/22) of spend with this vendor, this value is `"Various"`. Otherwise, it is the `vendor_entity`. This column allows consumers to group all the less significant vendors.',
        examples: ['other', 'Amazon'],
      },
      collapsed_vendor_id: {
        type: 'string',
        nullable: true,
        description:
          'If there is fewer than $25k ($100k prior to 10/26/22) of spend with this vendor, this value is `null`. Otherwise, it is the `vendor_id`. This column allows consumers to group all the less significant vendors.',
        examples: ['ven_2233RJ3n87kfpNaYoH6Y'],
      },
      collapsed_company_id: {
        type: 'string',
        nullable: true,
        description:
          'If there is fewer than $25k ($100k prior to 10/26/22) of spend with this company, this value is `null`. Otherwise, it is the `company_id`. This column allows consumers to group all the less significant vendors.',
        examples: ['comp_2233bMuqu5pkt67PZ5kp'],
      },
      unique_id: {
        type: 'string',
        nullable: true,
        description:
          'An identifier for a specific "type of emissions," which is formed from a combination of multiple fields and used to deduplicate: if two rows have the same `unique_id`, only one will be used in a footprint (the one with the higher specificity and match value).',
      },
      specificity_rank: {
        type: 'integer',
        nullable: true,
        description:
          'The specificity rank of the conversion factor used in the emissions calculation. The lower the value, the more specific (precise) the conversion is.',
      },
      allocation_location_emissions_factor_id: {
        type: 'string',
        nullable: true,
        description:
          'The `emissions_factor_id` of the emissions factor used in the location-based calculation. Identical to the market-based calculation except for some Scope 2 rows.',
      },
      allocation_location_emissions_factor_uuid: {
        type: 'string',
        nullable: true,
        description:
          'The unique Postgres ID of the specific `emissions_factor` record used in the location-based calculation. Identical to the market-based calculation except for some Scope 2 rows.',
      },
      allocation_location_quantity_kg_co2e: {
        type: 'number',
        nullable: true,
        description:
          'The quantity of emissions, measured in kgCO2e, when using the location-based emissions factor.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kgco2e',
        },
      },
      biogenic_co2_quantity_kg_co2e: {
        type: 'number',
        nullable: true,
        description:
          'The quantity of biogenic CO2 emissions, measured in kgCO2e.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kgco2e',
        },
      },
      biogenic_co2_location_quantity_kg_co2e: {
        type: 'number',
        nullable: true,
        description:
          'The quantity of biogenic CO2 emissions, measured in kgCO2e, when using the location-based emissions factor.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kgco2e',
        },
      },
      clean_power_avoided_quantity_kg_co2e: {
        type: 'number',
        nullable: true,
        description:
          'The quantity of emissions, measured in kgCO2e, avoided by using renewable power.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kgco2e',
        },
      },
      clean_power_contractual_instrument: {
        type: 'enum',
        options: CLEAN_POWER_CONTRACTUAL_INSTRUMENTS,
        nullable: true,
        description:
          'The contractual instrument used for the clean power purchase.',
        examples: ['SpotEAC', 'PPA'],
      },
      marketplace_allocation_instruction_id: {
        description:
          'The ID of the `marketplace_allocation_instruction` which was applied to this clean power row.',
        type: 'string',
        nullable: true,
      },
      bart_instance_id: {
        type: 'string',
        nullable: true,
        description:
          "The ID of the `business_activity_record_table` which contains this record's source row.",
      },
      bart_row_id: {
        type: 'integer',
        nullable: true,
        description:
          "The ID of the row in the `business_activity_record_table` instance which is this record's source row.",
      },
      // For PLR rows that came from emissions model evaluation, the following
      // columns, taken together, can be used to uniquely identify where the row
      // came from / how it was calculated:
      // - emissions_model_evaluation_id (which file)
      // - emissions_model_evaluation_row_id (which row)
      // - emissions_model_namespaced_variable_name (which column)
      emissions_model_version_id: {
        type: 'string',
        nullable: true,
        description:
          'The ID of the emisssions model version that was used to generate this row.',
        examples: ['emv_2224Ake8mc9BYKALEfiD'],
      },
      emissions_model_emissions_variable_path: {
        type: 'string',
        nullable: true,
        description:
          'Path to the emissions variable within the emissions model that generated this row.',
        examples: [
          'nodes.processes.coloration.emissions.kgco2e_from_coloration',
        ],
        deprecationReason:
          'Use `emissions_model_namespaced_variable_name` instead',
      },
      emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the emissions variable within the emissions model that generated this row.',
        examples: ['emissions@fabric_coloration@textile_1'],
      },
      allocation_location_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the emissions variable within the emissions model that generated this row. Specific to location-based emissions.',
        examples: ['emissions@fabric_coloration@textile_1'],
      },
      biogenic_co2_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the biogenic CO2 emissions variable within the emissions model that generated this row.',
        examples: ['biogenic_co2@fabric_coloration@textile_1'],
      },
      biogenic_co2_location_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the biogenic CO2 emissions variable within the emissions model that generated this row. Specific to location-based emissions.',
        examples: ['biogenic_co2@fabric_coloration@textile_1'],
      },
      clean_power_avoided_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the emissions variable within the emissions model that generated the value for clean power avoided emissions.',
        examples: [
          'clean_power_avoided_emissions@electricity_production_renewable@electricity_1',
        ],
      },
      emissions_model_bart_values: {
        type: 'string',
        nullable: true,
        description:
          'JSON representation of the BART values used to evaluate the emissions model that generated this row.',
        examples: ['{"material_id": "cotton", "supplier": "Acme Factory"}'],
      },
      emissions_model_process_kind: {
        type: 'enum',
        nullable: true,
        options: processKinds,
        description:
          'The process kind from the emissions model that generated this row',
        examples: ['Raw material production', 'Electricity production'],
      },
      // Replaces emissions_model_version_id, emissions_model_resolved_parameter_set_id, etc.
      emissions_model_evaluation_id: {
        type: 'string',
        nullable: true,
        description:
          'The ID of the emissions model evaluation that generated this row',
        examples: ['eme_2ce4GAxCnGCirgtbhVxk'],
      },
      emissions_model_evaluation_row_id: {
        type: 'number',
        nullable: true,
        description:
          'The one-indexed ID of the row within the emissions model evaluation parquet file that generated this row',
      },
      ext_bart_instance_id: {
        type: 'string',
        nullable: true,
        description:
          "The ID of the 'extended_business_activity_record_table` instance which is this record's source.",
      },
      ext_bart_row_id: {
        type: 'number',
        nullable: true,
        description:
          "The ID of the row in the 'extended_business_activity_record_table` instance which is this record's source row.",
      },
      __input_row_id: {
        description:
          "The ID of the row in the EMTA instance which is this record's source row.",
        type: 'integer',
        nullable: true,
      },

      // Non-carbon quantities that are extracted from results.
      kwh: {
        type: 'number',
        nullable: true,
        description:
          'The amount of energy consumed, measured in kWh. If null, the emissions model did not calculate energy consumption data.',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kilowatt-hour',
        },
      },
      kwh_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the kwh result within the emissions model that generated this row.',
        examples: ['kwh@electricity_production_grid@electricity'],
      },
      quantity_kg_waste: {
        type: 'number',
        nullable: true,
        description: 'The quantity of waste, measured in kg',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kilogram',
        },
      },
      waste_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the waste result within the emissions model that generated this row.',
        examples: ['kg_waste@waste@landfill'],
      },
      quantity_liters_water_consumed: {
        type: 'number',
        nullable: true,
        description: 'Quantity of water consumed, measured in liters',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'liter',
        },
      },
      water_consumed_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the water_consumed result within the emissions model that generated this row.',
        examples: ['liters_of_water_consumed@water_usage'],
      },
      quantity_liters_water_used: {
        type: 'number',
        nullable: true,
        description: 'Quantity of water used, measured in liters',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'liter',
        },
      },
      water_used_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the water_used result within the emissions model that generated this row.',
        examples: ['liters_of_water_used@water_usage'],
      },
      quantity_liters_water_stored: {
        type: 'number',
        nullable: true,
        description: 'Quantity of water stored, measured in liters',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'liter',
        },
      },
      water_stored_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the water_stored result within the emissions model that generated this row.',
        examples: ['liters_of_water_stored@water_usage'],
      },
      quantity_liters_water_recycled: {
        type: 'number',
        nullable: true,
        description: 'Quantity of water recycled, measured in liters',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'liter',
        },
      },
      water_recycled_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the water_recycled result within the emissions model that generated this row.',
        examples: ['liters_of_water_recycled@water_usage'],
      },
      quantity_liters_water_discharged: {
        type: 'number',
        nullable: true,
        description: 'Quantity of water discharged, measured in liters',
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'liter',
        },
      },
      water_discharged_emissions_model_namespaced_variable_name: {
        type: 'string',
        nullable: true,
        description:
          'Namespaced name for the water_discharged result within the emissions model that generated this row.',
        examples: ['liters_of_water_discharged@water_usage'],
      },
      quantity_kg_substance_inflow: {
        description: 'The quantity of substance inflow, measured in kg',
        type: 'number',
        nullable: true,
      },
      substance_inflow_emissions_model_namespaced_variable_name: {
        description:
          'Namespaced name for the substance inflow result within the emissions model that generated this row.',
        type: 'string',
        nullable: true,
        examples: ['kg_substance_inflow@materials@procurement'],
      },
      quantity_kg_substance_outflow: {
        description: 'The quantity of substance outflow, measured in kg',
        type: 'number',
        nullable: true,
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kilogram',
        },
      },
      substance_outflow_emissions_model_namespaced_variable_name: {
        description:
          'Namespaced name for the substance outflow result within the emissions model that generated this row.',
        type: 'string',
        nullable: true,
        examples: ['kg_substance_outflow@transportation@ground_transport'],
      },
      quantity_kg_microplastics_inflow: {
        description: 'The quantity of microplastics inflow, measured in kg',
        type: 'number',
        nullable: true,
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kilogram',
        },
      },
      microplastics_inflow_emissions_model_namespaced_variable_name: {
        description:
          'Namespaced name for the microplastics inflow result within the emissions model that generated this row.',
        type: 'string',
        nullable: true,
        examples: ['kg_microplastics_inflow@materials@procurement'],
      },
      quantity_kg_microplastics_outflow: {
        description: 'The quantity of microplastics outflow, measured in kg',
        type: 'number',
        nullable: true,
        unitInfo: {
          type: 'implicit',
          implicitUnit: 'kilogram',
        },
      },
      microplastics_outflow_emissions_model_namespaced_variable_name: {
        description:
          'Namespaced name for the microplastics outflow result within the emissions model that generated this row.',
        type: 'string',
        nullable: true,
        examples: ['kg_microplastics_outflow@transportation@ground_transport'],
      },
    },
    validators: [
      {
        rule: 'lscript',
        condition: `
          or(
            collapsed_vendor_entity == vendor_entity,
            collapsed_vendor_entity == "unknown",
            collapsed_vendor_entity == "Various"
          )
        `,
        message: dedent`
          collapsed_vendor_entity must be the same as vendor_entity or one of 'unknown' or 'Various'.
          See https://www.notion.so/watershedclimate/Pipeline-Result-Validation-e6e40874f3cc4ae8ab9e1397e30c3083
        `,
      },
      {
        rule: 'is-json-object',
        prop: 'custom_dimensions',
      },
      {
        rule: 'lscript',
        condition: `
          (quantity_kg_co2e == null) == (allocation_location_quantity_kg_co2e == null)
        `,
        message:
          'Both of quantity_kg_co2e and allocation_location_quantity_kg_co2e must be present if either is present',
      },
      {
        rule: 'lscript',
        condition: quantityFieldsValidationLscript,
        message: `At least one of these quantities must be non-null: ${quantityFields.join(
          ', '
        )}`,
      },
    ],
  },
} as const satisfies TSchema;
