import { Teams } from '@watershed/constants/teams';
import usePaintEffect from '../../hooks/usePaintEffect';
import { useEffect } from 'react';
import { perfume } from './initializeFrontendPerformanceMonitoring';
import values from 'lodash/values';
import { CustomSpanAttribute } from '@watershed/shared-universal/constants/otelCustomAttributes';

export const MEANINGFUL_PAINT_PAGE_NAME = {
  SUPPLY_CHAIN_OVERVIEW: 'supplyChain.SuppliersOverview',
  SUPPLY_CHAIN_TASKS: 'supplyChain.Tasks',
  SUPPLY_CHAIN_SUPPLIER_DETAILS: 'supplyChain.supplierDetails',

  // Datasets and Objects
  DRILLDOWN: 'drilldown',
  ACTIVITY_DATA_LANDING: 'activityData.landing',
  VALUE_MAPPING: 'valueMapping.landing',
} as const;

export const MEANINGFUL_PAINT_PAGE_NAMES: Set<string> = new Set(
  values(MEANINGFUL_PAINT_PAGE_NAME)
);

type PageName =
  (typeof MEANINGFUL_PAINT_PAGE_NAME)[keyof typeof MEANINGFUL_PAINT_PAGE_NAME];

export type MeaningfulPaintTimerProps = {
  owner: Teams;
  userLeftTabDuringLoad?: boolean;
  extraAttributes?: Partial<Record<CustomSpanAttribute, string | number>>;
};

export type MeaningfulPaintAdditionalProperties = Partial<
  Record<CustomSpanAttribute, string | number>
>;

/*
 * A global set of running timer names to track properties of each timer
 */
const globalTimers = new Map<string, MeaningfulPaintTimerProps>();

/**
 * Starts a timer for a latency measurement.
 *
 * Only one timer can be running for a given pageName at a time, if a 2nd
 * timer for the same pageName is started, it will be ignored.
 *
 * @param pageName Name of the page
 */
export function startMeaningfulPaintTimer(
  pageName: PageName,
  owner: Teams,
  restartTimer: boolean = false
) {
  // If there's already a timer running for this event, do nothing.
  if (globalTimers.has(pageName)) {
    if (!restartTimer) {
      return;
    }
    globalTimers.delete(pageName);
    perfume?.clear(pageName);
  }

  // if the user leaves the tab, it will stop rendering, which inflates our timing
  window.addEventListener('blur', () => {
    if (globalTimers.size > 0) {
      new Array(...globalTimers.entries()).forEach(([pageName, properties]) => {
        globalTimers.set(pageName, {
          ...properties,
          userLeftTabDuringLoad: true,
        });
      });
    }
  });
  globalTimers.set(pageName, { owner });
  perfume?.start(pageName);
}

export const useStartMeaningfulPaintTimer = (
  pageName: PageName,
  owner: Teams,
  restartTimer?: boolean
) => {
  useEffect(() => {
    startMeaningfulPaintTimer(pageName, owner, restartTimer);
  }, [pageName, owner, restartTimer]);
};

/**
 * Stops a timer for a latency measurement, and reports the measurement to
 * Honeycomb.
 *
 * If the timer for the given eventName is not running, this function will
 * do nothing. This is intentional behaviour, with events that result in
 * UI changes in a very different part of the app (perhaps they open a modal
 * or trigger navigation) it's easier to just optimistically stop the timer
 * rather than having UI-level code keep track of whether it's running or not.
 *
 * @param pageName Name of the page. This must match the pageName passed to startTimer
 * in order for the measurement to be correctly recorded.
 */
export function stopMeaningfulPaintTimerAndReportToHoneycomb(
  pageName: PageName,
  additionalProperties: MeaningfulPaintAdditionalProperties
) {
  // If there's no timer running for this event, do nothing.
  if (!globalTimers.has(pageName)) {
    return;
  }
  const properties = globalTimers.get(pageName);
  globalTimers.delete(pageName);
  perfume?.end(
    pageName,
    additionalProperties
      ? { ...properties, ...additionalProperties }
      : properties
  );
}

export const useStopMeaningfulPaintTimerAndReportToHoneycomb = (
  pageName: PageName,
  isFinishedRendering: boolean = true,
  additionalProperties: MeaningfulPaintAdditionalProperties = {}
) => {
  usePaintEffect(() => {
    if (isFinishedRendering) {
      stopMeaningfulPaintTimerAndReportToHoneycomb(
        pageName,
        additionalProperties
      );
    }
  });
};
