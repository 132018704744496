/* eslint-disable @watershed/pages-must-assign-owners */
import Chip from '@mui/material/Chip';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Theme } from '@mui/material/styles/createTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/browser';
import { devtoolsExchange } from '@urql/devtools';
import urqlSchema from '../generated/urql-schema.json';
import '@watershed/shared-frontend/styles/index.css';
import { WatershedSnackbarProvider } from '@watershed/shared-frontend/components/Snackbar';
import debugExchange from '@watershed/shared-frontend/exchanges/debugExchange';
import errorExchange from '@watershed/shared-frontend/exchanges/errorExchange';
import requestIdExchange from '@watershed/shared-frontend/exchanges/requestIdExchange';
import fullstoryExchange from '@watershed/shared-frontend/exchanges/fullstoryExchange';
import retryExchange from '@watershed/shared-frontend/exchanges/retryExchange';
import scalarsExchange from '@watershed/shared-frontend/exchanges/scalarsExchange';
import FailedToContactApiDevelopmentErrorBox from '@watershed/shared-frontend/components/FailedToContactApiDevelopmentErrorBox';
import useAutoRefresh from '@watershed/shared-frontend/hooks/useAutoRefresh';
import { LOTTIE_SCRIPT_URL } from '@watershed/ui-core/constants/lottie';
import { useActiveWatershedEmployeeQuery } from '@watershed/shared-frontend/generated/urql';
import sentryBeforeSend from '@watershed/shared-frontend/sentryBeforeSend';
import '@watershed/shared-frontend/utils/DataDogFrontendInit';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { splitUrlByOperationNameExchange } from '@watershed/shared-frontend/utils/splitUrlByOperationNameExchange';
import { SENTRY_MAX_VALUE_LENGTH } from '@watershed/shared-universal/utils/universalSentryUtils';
import getAppVersion from '@watershed/shared-universal/utils/getAppVersion';
import { PortalContextProviderWithNextRouter } from '@watershed/ui-core/utils/PortalContext';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import gql from 'graphql-tag';
import invariant from 'invariant';
import type { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Suspense, useEffect, useState } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ErrorBoundary } from 'react-error-boundary';
import { createClient, fetchExchange, Provider } from 'urql';
import cache from '../cache';
import { AdminContextProvider } from '../components/AdminContext';
import AdminPageContainer from '../components/AdminPageContainer';
import ErrorPage from '../components/ErrorPage';
import {
  initializeAdminAnalyticsAtTopLevelOfModule,
  useAdminAnalytics,
} from '../utils/adminAnalytics';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import 'reactflow/dist/style.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// eslint-disable-next-line no-restricted-imports
import theme from '@watershed/shared-frontend/styles/theme';
import WatershedI18nProvider from '@watershed/intl/frontend/WatershedI18nProvider';
import { Messages } from '@lingui/core';
import { SupportedLocale } from '@watershed/intl/constants';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

if (process.env.NEXT_PUBLIC_MUI_V5_LICENSE) {
  LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_V5_LICENSE);
}

const queryClient = new QueryClient();

const loadMessages = async (locale: SupportedLocale) => {
  const bundle = await import(
    `../generated/locales/${locale}/unassigned/messages`
  );
  return bundle.messages as Messages;
};

// we don't currently await this even though FullStory init is async
// because all the FullStory methods internally await it
void initializeAdminAnalyticsAtTopLevelOfModule();

gql`
  fragment ActiveWatershedEmployeeFields on WatershedEmployee {
    id
    name
    handle
    email
    user {
      id
      createdAt
      accessibleOrgs {
        id
        name
      }
      primaryLoginOrgId
    }
    orgPointsOfContact {
      id
      orgId
      kind
    }
  }

  query ActiveWatershedEmployee {
    activeWatershedEmployee {
      ...ActiveWatershedEmployeeFields
    }
  }
`;

if (typeof window !== 'undefined') {
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
  const sentryEnabled =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ||
    process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

  Sentry.init({
    dsn,
    release: 'admin-frontend@' + getAppVersion(),
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    enabled: sentryEnabled,
    // Disabling autoSessionTracking fixes openHandle issues in jest when
    // Sentry is disabled. Context: https://github.com/getsentry/sentry-javascript/issues/3827
    autoSessionTracking: sentryEnabled,
    initialScope: {
      tags: {
        svcname: 'admin-ui',
        region: process.env.NEXT_PUBLIC_REGION,
      },
    },
    maxValueLength: SENTRY_MAX_VALUE_LENGTH,
    beforeSend: sentryBeforeSend,
    ignoreErrors: [
      // Ignore noisy Next.js error. We _should_ be able to remove this once we
      // host our assets on a CDN via Render so they don't disappear between
      // deploys 🤞. For discussion:
      // https://watershedclimate.slack.com/archives/C02JM11N62F/p1662146583775999
      /attempted to hard navigate to the same URL/,
      // Ignore error that isn't actionable
      /ResizeObserver loop limit exceeded/,
    ],
  });
}

// See https://github.com/FormidableLabs/urql-devtools for the browser extension
// to install to use this.
const enableDevtoolsExchange = false;
const devtoolsExchanges = enableDevtoolsExchange ? [devtoolsExchange] : [];

const client = createClient({
  // NOTE: This gets overridden by the `splitUrlByOperationNameExchange`
  url: '/graphql',
  exchanges: [
    ...devtoolsExchanges,
    debugExchange,
    requestIdExchange,
    fullstoryExchange,
    scalarsExchange(urqlSchema),
    cache(urqlSchema),
    retryExchange(),
    errorExchange,
    splitUrlByOperationNameExchange(),
    fetchExchange,
  ],
  fetchOptions: {
    headers: {
      Accept:
        'application/graphql-response+json, application/json, multipart/mixed',
      'content-type': 'application/json',
    },
  },
});

function DevelopmentBar() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') {
    return (
      <Chip
        style={{
          position: 'fixed',
          bottom: 8,
          right: 8,
          backgroundColor: theme.palette.error.main,
          color: theme.palette.primary.contrastText,
          zIndex: 5,
        }}
        label="Dev"
      />
    );
  } else {
    return null;
  }
}

function LoggedInApp({ children }: { children: React.ReactNode }) {
  const { location } = useGlobalLocation();

  const [result] = useActiveWatershedEmployeeQuery();
  const { data, error } = result;

  const activeWatershedEmployee = data?.activeWatershedEmployee;
  useAdminAnalytics(activeWatershedEmployee);

  useEffect(() => {
    if (activeWatershedEmployee) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          // Leaving `id` as user id for legacy reasons; maybe we can remove it if
          // we don't actually need consistency with the past?
          id: activeWatershedEmployee.user.id,
          name: activeWatershedEmployee.name,
          userId: activeWatershedEmployee.user.id,
          watershedEmployeeId: activeWatershedEmployee.id,
        });
      });
    }
  }, [activeWatershedEmployee]);

  if (error) {
    if (error.response?.status === 401) {
      return (
        <Redirect
          to={`/login?redirect=${location.pathnameWithQuery}`}
          loadingElement={<AdminPageContainer.Loading />}
        />
      );
    }
    return <FailedToContactApiDevelopmentErrorBox error={error} />;
  }
  if (isFetchingOrStale(result)) {
    return <AdminPageContainer.Loading />;
  }
  invariant(activeWatershedEmployee, 'activeWatershedEmployee');

  return (
    <AdminContextProvider activeWatershedEmployee={activeWatershedEmployee}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        {/* <PortalContextProviderWithNextRouter> should be the inner-most, so it can
            take advantage of all other context providers. This provider just
            provides a place in the React tree to insert portals (modals,
            dialogs) into programatically. */}
        <PortalContextProviderWithNextRouter>
          {children}
        </PortalContextProviderWithNextRouter>
      </ErrorBoundary>
    </AdminContextProvider>
  );
}

type NextPageWithLayout = NextPage & {
  doesAllowUnauthenticatedUsers?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function AdminApp({ Component, pageProps }: AppPropsWithLayout) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useAutoRefresh({
    // Check every 10 minutes on Admin
    interval: 1000 * 60 * 10,
  });

  const head = (
    <Head>
      <title>Watershed – Admin</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );

  if (!isMounted) {
    // Unfortunately, we have to early return here before rendering the full
    // page content until we can figure out how to server-render our styles.
    // It's currently failing on a server-client mismatch hydration error due to
    // the Emotion library.
    return head;
  }
  return (
    <>
      {head}
      <QueryClientProvider client={queryClient}>
        <Provider value={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <WatershedI18nProvider
                FallbackComponent={ErrorPage}
                loadMessages={loadMessages}
              >
                <WatershedSnackbarProvider>
                  <DevelopmentBar />
                  <Suspense fallback={<AdminPageContainer.Loading />}>
                    {Component.doesAllowUnauthenticatedUsers ? (
                      <Component {...pageProps} />
                    ) : (
                      <LoggedInApp>
                        <Component {...pageProps} />
                      </LoggedInApp>
                    )}
                  </Suspense>
                </WatershedSnackbarProvider>
              </WatershedI18nProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </QueryClientProvider>
      <Script src={LOTTIE_SCRIPT_URL} strategy="afterInteractive" />
    </>
  );
}

export default AdminApp;
