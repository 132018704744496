import assertNever from '@watershed/shared-util/assertNever';
import carbonRemovalKindToArchetypeJson from '../generated/shared_data/bart_carbon_removal_kind_to_archetype.json';
import BUILDING_KINDS_H1_2023 from '../generated/shared_data/building_kinds_h1_2023.json';
import CLEAN_RENEWABLE_FUEL_KINDS_UNTYPED from '../generated/shared_data/clean_renewable_fuel_kinds.json';
import STATIONARY_FUEL_COMBUSTION_REASON_UNTYPED from '../generated/shared_data/stationary_fuel_combustion_reasons.json';
import { PrimaryTypeName } from './types';

// Type to strongly type options in shared_data/stationary_fuel_combustion_reasons.json
export type StationaryFuelCombustionReason =
  | 'building heating'
  | 'other heating'
  | 'electricity'
  | 'steam'
  | 'cooling'
  | 'combined heat and power generation'
  | 'combined cooling, heat, and power generation'
  | 'other stationary combustion';
export const STATIONARY_FUEL_COMBUSTION_REASONS =
  STATIONARY_FUEL_COMBUSTION_REASON_UNTYPED as Array<StationaryFuelCombustionReason>;

/** Fuel combusted for cogeneration or trigeneration. */
export const COGENERATION_FUEL_COMBUSTION_REASONS: Array<StationaryFuelCombustionReason> =
  [
    'combined heat and power generation',
    'combined cooling, heat, and power generation',
  ];

// Type to strongly type options in shared_data/clean_renewable_fuel_kinds.json
export type CleanRenewableFuelKind =
  | 'Solar energy'
  | 'Wind energy'
  | 'Geothermal energy'
  | 'Hydropower';

/**
 * Renewable fuels that are also non-emissive as options for selecting clean renewable fuel sources.
 *
 * If we want to create a type for renewable fuel kinds that includes emissive ones (such as
 * certain types of certified renewable biomass) we should not add them to this list.
 */
export const CLEAN_RENEWABLE_FUEL_KINDS =
  CLEAN_RENEWABLE_FUEL_KINDS_UNTYPED as Array<CleanRenewableFuelKind>;

/**
 * Renewable fuels that may or may not be emissive.
 *
 * Note: if we add renewable fuel kinds that are emissive in the future turn this into a new array.
 */
export const RENEWABLE_FUEL_KINDS = CLEAN_RENEWABLE_FUEL_KINDS;

export const BUILDING_LEASE_TYPES = [
  'owned',
  'upstream_lease',
  'upstream_sublease',
  'downstream_lease_customer_paying_utilities', // owned, lease to someone
  'downstream_lease_customer_not_paying_utilities',
  'downstream_sublease_customer_paying_utilities', // leased, sublease to someone
  'downstream_sublease_customer_not_paying_utilities',
  'coworking_or_serviced_office',
  'franchise',
  'sold_building',
];

export const BUILDING_KIND = [
  'office',
  'residential',
  'residential_multifamily',
  'residential_singlefamily',
  'restaurant',
  'retail',
  'warehouse_refrigerated',
  'warehouse',
  'data_center_colocation',
  'data_center_non_colocation',
  'medical_facility_non_diagnostic',
  'commercial_industrial',
  'laboratory',
  'other',
] as const;

export const ALL_BUILDING_KINDS = [...BUILDING_KIND, ...BUILDING_KINDS_H1_2023];

// Only applies to the legacy Carbon Removal BAT
export const CARBON_REMOVAL_KINDS = [
  ...Object.keys(carbonRemovalKindToArchetypeJson),
  'Other external purchase',
];

// Used in the Carbon Credit Retirements BAT
export const CARBON_CREDIT_TYPES = [
  'removal - biogenic sink',
  'removal - technological sink',
  'Other reduction',
];

export const TRANSPORTATION_MODES = [
  'bicycle',
  'car_electric',
  'car_hybrid',
  'car',
  'motorcycle',
  'plane_commercial',
  'plane_private',
  'plane',
  'scooter_electric',
  'scooter',
  'ship_freezer',
  'ship_refrigerated',
  'ship',
  'train_commuter',
  'train_intercity',
  'train_transit',
  'light_rail',
  'international_rail',
  'national_rail',
  'london_underground',
  'train',
  'truck_delivery',
  'truck_freezer',
  'truck_heavy',
  'truck_refrigerated',
  'truck',
  'walking',
] as const;

type TRANSPORTATION_MODES_TYPE = (typeof TRANSPORTATION_MODES)[number];
export const LOGISTICS_TRANSPORTATION_MODES: Array<TRANSPORTATION_MODES_TYPE> =
  [
    'plane',
    'ship',
    'ship_freezer',
    'ship_refrigerated',
    'train',
    'truck',
    'truck_delivery',
    'truck_freezer',
    'truck_refrigerated',
  ] as const;

export const TRANSPORTATION_MODES_LABEL_MAPPING: Record<
  TRANSPORTATION_MODES_TYPE,
  string
> = {
  bicycle: 'bicycle',
  car_electric: 'car (electric)',
  car_hybrid: 'car (hybrid)',
  car: 'car',
  motorcycle: 'motorcycle',
  plane_commercial: 'plane (commercial)',
  plane_private: 'plane (private)',
  plane: 'plane',
  scooter_electric: 'scooter (electric)',
  scooter: 'scooter',
  ship_freezer: 'ship (freezer)',
  ship_refrigerated: 'ship (refrigerated)',
  ship: 'ship',
  train_commuter: 'train (commuter)',
  train_intercity: 'train (intercity)',
  train_transit: 'train (transit)',
  light_rail: 'light rail',
  international_rail: 'international rail',
  national_rail: 'national rail',
  london_underground: 'london underground',
  train: 'train',
  truck_delivery: 'truck (delivery)',
  truck_freezer: 'truck (freezer)',
  truck_heavy: 'truck (heavy)',
  truck_refrigerated: 'truck (refrigerated)',
  truck: 'truck',
  walking: 'walking',
};

// EPA fuels, all of these match directly to an EF.
export const EPA_GAS_FUEL_KINDS = [
  'Natural Gas',
  'Blast Furnace Gas',
  'Coke Oven Gas',
  'Fuel Gas',
  'Propane Gas',
  'Landfill Gas',
  'Other Biomass Gases',
] as const;

export const EPA_LIQUID_FUEL_KINDS = [
  'Asphalt and Road Oil',
  'Aviation Gasoline',
  'Butane',
  'Butylene',
  'Crude Oil',
  'Distillate Fuel Oil No. 1',
  'Distillate Fuel Oil No. 2',
  'Distillate Fuel Oil No. 4',
  'Ethane',
  'Ethylene',
  'Heavy Gas Oils',
  'Isobutane',
  'Isobutylene',
  'Kerosene',
  'Kerosene-Type Jet Fuel',
  'Liquefied Petroleum Gases (LPG)',
  'Lubricants',
  'Motor Gasoline',
  'Naphtha (<401 deg F)',
  'Natural Gasoline',
  'Other Oil (>401 deg F)',
  'Pentanes Plus',
  'Petrochemical Feedstocks',
  'Propane',
  'Propylene',
  'Residual Fuel Oil No. 5',
  'Residual Fuel Oil No. 6',
  'Special Naphtha',
  'Unfinished Oils',
  'Used Oil',
  'Biodiesel (100%)',
  'Ethanol (100%)',
  'Rendered Animal Fat',
  'Vegetable Oil',
] as const;

export const EPA_COAL_FUEL_KINDS = [
  'Anthracite Coal',
  'Bituminous Coal',
  'Sub-bituminous Coal',
  'Lignite Coal',
  'Mixed (Commercial Sector) Coal',
  'Mixed (Electric Power Sector) Coal',
  'Mixed (Industrial Coking)',
  'Mixed (Industrial Sector)',
  'Coal Coke',
  'Petroleum Coke (Solid)',
] as const;

export const EPA_BIOMASS_FUEL_KINDS = [
  'Municipal Solid Waste',
  'Plastics',
  'Tires',
  'Agricultural Byproducts',
  'Peat',
  'Solid Byproducts',
  'Wood and Wood Residuals',
  'Bagasse',
  'Bamboo',
  'Biofuels and waste',
  'North American Hardwood',
  'North American Softwood',
  'Straw',
] as const;

export const EPA_COAL_AND_BIOMASS_FUEL_KINDS = [
  ...EPA_COAL_FUEL_KINDS,
  ...EPA_BIOMASS_FUEL_KINDS,
];

// Duplicates with above fuels from EPA are omitted.
export const EPA_MOBILE_COMBUSTION_FUEL_KINDS = [
  // 'Aviation Gasoline', In liquid fuels
  // 'Biodiesel (100%)', In liquid fuels
  'Compressed Natural Gas (CNG)',
  'Diesel Fuel',
  // 'Ethanol (100%)', In liquid fuels
  // 'Kerosene-Type Jet Fuel', In liquid fuels
  'Liquefied Natural Gas (LNG)',
  // 'Liquefied Petroleum Gases (LPG)', In liquid fuels
  // 'Motor Gasoline', In liquid fuels
  // 'Residual Fuel Oil', In liquid fuels, equivalent: Residual Fuel Oil No. 6
] as const;

// Steam heat comes from EPA EFs
// Historically, these fuels are included as a fuel type, even though it's bit more of a utility type
export const OTHER_BUILDING_HEATING_FUELS = [
  'Steam Heat',
  'District Heat',
  'District Chilled Water',
] as const;

export type OTHER_BUILDING_HEATING_FUEL =
  (typeof OTHER_BUILDING_HEATING_FUELS)[number];

export function districtUtilityFuelKindToCombustionReason(
  fuelKind: (typeof OTHER_BUILDING_HEATING_FUELS)[number]
): StationaryFuelCombustionReason {
  switch (fuelKind) {
    case 'Steam Heat':
      return 'steam';
    case 'District Heat':
      return 'building heating';
    case 'District Chilled Water':
      return 'cooling';
    default:
      assertNever(fuelKind);
  }
}

export const ALL_EPA_FUEL_KINDS = [
  ...EPA_GAS_FUEL_KINDS,
  ...EPA_LIQUID_FUEL_KINDS,
  ...EPA_COAL_AND_BIOMASS_FUEL_KINDS,
  ...EPA_MOBILE_COMBUSTION_FUEL_KINDS,
] as const;

/**
 * This is a set of fuel kinds meant to be available in the `fuel_combustion` BAT
 * but NOT as a building fuel kind. We'd like to add these to the building list soon
 * for consistency, but today we are blocked on adding new fuel types to the Facilities flow.
 * See: https://www.notion.so/watershedclimate/Design-Doc-CMVs-x-Backwards-Incompatible-BAT-updates-158e2b76a3fb80fcbc6dcc726f9a0021
 *
 * We are also NOT adding these to lists including renewable fuel kinds for now, as these are
 * not necessarily guaranteed to be 100% renewable (and haven't been assessed in the reporting contexts downstream)
 *
 * (I'm sorry for further complicating the fuel kind enum situation)
 */
export const ADDITIONAL_VEHICLE_FUEL_KINDS = [
  'Biomethanol',
  'Diesel Fuel (100% Mineral Diesel)',
  'Motor Gasoline (100% Mineral Gasoline)',
  'Renewable Diesel',
  'Renewable Gasoline',
  'Sustainable Aviation Fuel (SAF)',
  'B5 Diesel',
  'B10 Diesel',
  'B20 Diesel',
  'E10 Gasoline',
  'E15 Gasoline',
  'E85 Gasoline',
  'Ethanol Flex Fuel',
] as const;

/**
 * See comment above on ADDITIONAL_VEHICLE_FUEL_KINDS for context
 */
export const ALL_NON_BUILDING_FUEL_KINDS = [
  ...ALL_EPA_FUEL_KINDS,
  // ...ADDITIONAL_VEHICLE_FUEL_KINDS, // TODO (aivant): Add this list in followup PR
];

// Categorizing fuels as renewable vs nonrenewable
export const NONRENEWABLE_FUEL_KINDS = [
  ...ALL_EPA_FUEL_KINDS,
  ...OTHER_BUILDING_HEATING_FUELS,
  'Electricity',
] as const;

/**
 * Includes:
 * - All EPA fuels, available in all BATs
 * - Building heating "fuels" (district heating), available in building stationary combustion BAT
 * - "Electricity", a special case fuel that is historical
 * - Renewable "fuels" that can be used as a fuel kind in electricity generation, but NOT the fuel combustion BATs
 *
 * We have a fuel_kind default tag that can pick up the fuel type from any of these BATs.
 * This constant is used for footprint schemas + reporting.
 */
export const ALL_FUEL_KINDS = [
  ...NONRENEWABLE_FUEL_KINDS,
  ...CLEAN_RENEWABLE_FUEL_KINDS,
  ...ADDITIONAL_VEHICLE_FUEL_KINDS, // TODO (aivant): Move this to be in NONRENEWABLE_FUEL_KINDS
] as const;

// TEMPORARY: This constant exists to split up the rollout of the fuels in "ADDITIONAL_VEHICLE_FUEL_KINDS"
// TODO (aivant): Remove this constant in followup PR
export const ALL_BAT_FUEL_KINDS = [
  ...NONRENEWABLE_FUEL_KINDS,
  ...CLEAN_RENEWABLE_FUEL_KINDS,
] as const;

/**
 * Used to define the fuel kinds available in the building stationary combustion BAT
 * - All EPA fuels
 * - Building heating "fuels"
 *
 * Used to populate the BAT enum + the Facilities flow.
 * Note, facilities flow is always tied to the "latest" list of fuels, and does not play well with
 * older BAT versions as a result.
 */
export const STATIONARY_COMBUSTION_FUEL_KINDS = [
  ...ALL_EPA_FUEL_KINDS,
  ...OTHER_BUILDING_HEATING_FUELS,
] as const;

/**
 * Historical list of vheicle fuel kinds in the vehicles BAT.
 * Replaced by instructions to use the fuel_combustion BAT directly
 */
export const VEHICLE_FUEL_KINDS = [
  'Motor Gasoline',
  'Biodiesel (100%)',
  'Diesel Fuel',
  'Ethanol (100%)',
  'Compressed Natural Gas (CNG)',
  'Liquefied Natural Gas (LNG)',
  'Liquefied Petroleum Gases (LPG)',
  'Electricity',

  // Adding our new renewable fuels to this list, as it's used outside of just BAT contexts
  // ...ADDITIONAL_VEHICLE_FUEL_KINDS, // TODO (aivant): Add this list in followup PR
] as const;

const VEHICLE_USAGE_REASONS = [
  'vehicles business travel',
  'vehicles upstream logistics',
  'vehicles downstream logistics',
];

export const ELECTRICITY_CONSUMPTION_REASON = [
  'vehicles', // Deprecated -- use one of VEHICLE_USAGE_REASONS instead
  ...VEHICLE_USAGE_REASONS,
];

export const MOBILE_FUEL_COMBUSTION_REASON = [
  'aviation',
  'vehicles', // Deprecated -- use one of VEHICLE_USAGE_REASONS instead
  ...VEHICLE_USAGE_REASONS,
  'other mobile combustion',
];

export const FOOD_ITEM_LIST = [
  'alfalfa feed',
  'barley feed',
  'barley feed, organic',
  'corn feed, by-product of maize flour, country-specific',
  'oat feed',
  'rye feed, organic',
  'wheat feed',
  'wheat feed, organic',
  'fava bean',
  'fava bean, organic',
  'lentil',
  'navy bean',
  'pinto bean',
  'red kidney bean',
  'soybean',
  'soybean, organic',
  'cream',
  'milk, cow',
  'milk, skim',
  'apple',
  'apricot',
  'avocado',
  'banana',
  'coconut',
  'cucumber',
  'date fruit',
  'date fruit, conditioned and dried',
  'date fruit, conditioned and dried, organic',
  'date fruit, organic',
  'grape',
  'kiwi',
  'lemon',
  'lime',
  'mandarin',
  'mandarin, fresh',
  'mandarin, processing grade',
  'mango',
  'mango, conditioned',
  'melon',
  'orange, fresh',
  'orange, processing grade',
  'papaya',
  'peach',
  'pear',
  'pineapple',
  'pomegranate',
  'strawberry',
  'barley grain',
  'barley grain, organic',
  'corn grain',
  'corn, organic',
  'millet',
  'oat grain',
  'rye',
  'rye, organic',
  'sorghum',
  'wheat',
  'wheat, organic',
  'beef, boneless',
  'beef, with bone',
  'chicken, boneless',
  'chicken, with bone',
  'lamb and mutton, boneless',
  'lamb and mutton, with bone',
  'pork, boneless',
  'pork, with bone',
  'castor bean',
  'coconut husk',
  'cotton',
  'cotton, organic',
  'flax husk',
  'jute plant, harvested',
  'kenaf plant, harvested',
  'miscanthus, chopped',
  'sunn hemp plant, harvested',
  'almond',
  'cashew',
  'peanut',
  'sesame seed',
  'sunflower seed',
  'flax plant, harvested',
  'jatropha seed',
  'linseed',
  'palm fruit bunch',
  'rape seed',
  'rape seed, organic',
  'chickpea',
  'protein pea',
  'protein pea, organic',
  'rice, basmati',
  'rice, non-basmati',
  'cocoa bean',
  'coffee bean',
  'coriander',
  'mint',
  'mulberry leaf',
  'mustard',
  'sweet sorghum stem',
  'tea, dried',
  'vanilla',
  'sugar beet pulp',
  'sugarcane',
  'potato',
  'potato, organic',
  'sugar beet',
  'asparagus, green',
  'asparagus, white',
  'bell pepper',
  'broccoli',
  'cabbage, red',
  'cabbage, white',
  'carrot',
  'carrot, Paris market',
  'cauliflower',
  'celery',
  'chilli',
  'corn',
  'eggplant, aubergine',
  'fennel',
  'lettuce',
  'lettuce, iceberg',
  'olive',
  'onion',
  'radish',
  'spinach',
  'tomato, fresh grade',
  'tomato, processing grade',
  'zucchini',
  'corn feed',
  'corn feed, organic',
  'corn feed, silage',
  'corn feed, silage, organic',
  'cottonseed feed',
  'fish residues',
  'fishmeal, 63-65% protein',
  'fishmeal, 65-67% protein',
  'landed anchovy by-catch, fresh',
  'meat and bone protein feed',
  'palm kernel feed',
  'rape feed',
  'rye feed, silage',
  'ryegrass feed, Egyptian and Persian clover, silage',
  'ryegrass feed, red and Egyptian clover, silage',
  'soybean feed',
  'soybean meal',
  'sunflower feed, silage',
  'breadcrumbs',
  'tofu',
  'butter',
  'buttermilk',
  'cheese',
  'corn flour',
  'corn starch',
  'wheat bran',
  'wheat flour',
  'wheat flour mix',
  'coconut oil',
  'cottonseed oil, crude',
  'cottonseed oil, refined',
  'fish oil',
  'palm kernel oil, crude',
  'palm oil',
  'palm oil, crude',
  'rape oil, crude',
  'soybean oil',
  'soybean oil, crude',
  'vegetable oil',
  'anchovy, boneless',
  'anchovy, with bone',
  'cod, haddock, and halibut, boneless',
  'cod, haddock, and halibut, with bone',
  'fish block, hake',
  'fish stick, frozen',
  'hake, boneless',
  'hake, with bone',
  'herring and sardines, boneless',
  'herring and sardines, with bone',
  'swordfish, salmon, and other fish, boneless',
  'swordfish, salmon, and other fish, with bone',
  'tilapia, boneless',
  'tilapia, with bone',
  'trout, boneless',
  'trout, with bone',
  'tuna, boneless',
  'tuna, with bone',
  'salt',
  'whey',
  'glucose',
  'molasses',
  'sugar, from sugar beet',
  'sugar, from sugarcane',
  'potato starch',
  'canary seed feed',
  'safflower seed feed',
  'broad bean',
  'green bean',
  'other legumes',
  'milk of buffalo',
  'milk of sheep',
  'milk, goat',
  'other milk',
  'egg',
  'cherry',
  'currant and gooseberry',
  'fig',
  'other berries',
  'other citrus fruits',
  'other fruits',
  'other stone fruits',
  'plantain',
  'plum',
  'pomelo and grapefruit',
  'raspberry',
  'watermelon',
  'buckwheat',
  'other cereal crops',
  'quinoa',
  'triticale (hybrid of wheat and rye)',
  'asses',
  'buffalo, boneless',
  'buffalo, with bone',
  'camels and camelids',
  'duck, boneless',
  'duck, with bone',
  'goat, boneless',
  'goat, with bone',
  'horse',
  'mules and hinnies',
  'turkey, boneless',
  'turkey, with bone',
  'natural rubber in primary forms or in plates, sheets or strip',
  'ramie, raw or retted',
  'raw hides and skins of bovine animals',
  'raw hides and skins of cattle',
  'raw hides and skins of goats or kids',
  'raw hides and skins of sheep or lambs',
  'roundwood',
  'unmanufactured tobacco',
  'wool, greasy, including fleece-washed shorn wool',
  'brazil nut',
  'carobs, locust bean',
  'chestnut',
  'fruit seed',
  'hazelnut',
  'other nuts (excluding wild edible nuts and groundnuts)',
  'other tree nuts',
  'pistachio',
  'poppy seed',
  'walnut',
  'cow pea',
  'green pea',
  'pigeon pea',
  'chicory root',
  'cinnamon',
  'clove',
  'garlic',
  'ginger',
  'hop cone',
  'mate leaf',
  'nutmeg, mace, and cardamoms',
  'other stimulant, spice and aromatic crops',
  'other sugar crops',
  'cocoyam, taro',
  'cocoyam, yautia',
  'sweet potato',
  'yam',
  'artichoke',
  'cassava',
  'leek',
  'mushrooms and truffles',
  'other fresh vegetables',
  'pepper (spice)',
  'pumpkins, squash, and gourds',
  'soy milk',
  'soy milk, organic',
  'oat milk',
  'almond milk',
  'coconut milk (dairy milk substitute)',
  'sector - breweries and beer',
  'sector - distilleries and spirits',
  'sector - wineries and wine',
  'sector - dog and cat food',
  'sector - other animal food',
  'sector - bread and other baked goods',
  'sector - cookies, crackers, pastas, and tortillas',
  'sector - snack foods (chips/crisps, pretzels, popcorn)',
  'sector - fresh soybeans, canola, flaxseeds, and other oilseeds',
  'sector - cheese',
  'sector - dairy farms',
  'sector - dry, condensed, and evaporated dairy',
  'sector - ice cream and frozen desserts',
  'sector - milk and butter (dairy-based and plant-based)',
  'sector - canned, pickled, or dried fruits and vegetables (incl. jams, tomato-based sauces, ketchup)',
  'sector - fresh fruits and tree nuts',
  'sector - breakfast cereals (incl. oatmeal, other hot cereals)',
  'sector - corn products',
  'sector - flours and malts',
  'sector - fresh wheat, corn, rice, and other grains',
  'sector - cattle farms',
  'sector - packaged meat (beef, pork, lamb; all except poultry)',
  'sector - packaged poultry (chicken, turkey, others)',
  'sector - poultry farms (incl. eggs)',
  'sector - timber and raw forest products',
  'sector - tobacco products',
  'sector - other crude vegetable oils and by-products',
  'sector - other refined vegetable, olive, and seed oils',
  'sector - all other foods',
  "sector - frozen foods that don't map to other primary components",
  'sector - mushrooms, greenhouse crops, nurseries, and flowers',
  'sector - soft drinks, bottled water, and ice',
  'sector - sugarcane, peanuts, sugar beets, herbs and spices, and other crops',
  'sector - animal farms and aquaculture ponds (except cattle and poultry)',
  'sector - packaged seafood (farmed and wild-caught)',
  'sector - wild-caught fish and game',
  'sector - coffee and tea',
  'sector - flavored drink concentrates',
  'sector - seasonings and dressings (incl. mayonnaise, mustard, prepared sauces/dips)',
  'sector - sugar, candy, and chocolate',
  'sector - fresh vegetables, melons, and potatoes',
  'sector - organic chemical manufacturing',
  'sector - all other chemical manufacturing',
  'sector - vitamins and botanical supplement manufacturing',
  'unmapped food', //  This is a catch-all to map old values in this BART that were added before this was an enum
];

export const FOOD_PROCESSING_SECTORS = [
  'sector - breweries and beer',
  'sector - distilleries and spirits',
  'sector - wineries and wine',
  'sector - dog and cat food',
  'sector - other animal food',
  'sector - bread and other baked goods',
  'sector - cookies, crackers, pastas, and tortillas',
  'sector - snack foods (chips/crisps, pretzels, popcorn)',
  'sector - cheese',
  'sector - dry, condensed, and evaporated dairy',
  'sector - ice cream and frozen desserts',
  'sector - milk and butter (dairy-based and plant-based)',
  'sector - canned, pickled, or dried fruits and vegetables (incl. jams, tomato-based sauces, ketchup)',
  'sector - breakfast cereals (incl. oatmeal, other hot cereals)',
  'sector - corn products',
  'sector - flours and malts',
  'sector - packaged meat (beef, pork, lamb; all except poultry)',
  'sector - packaged poultry (chicken, turkey, others)',
  'sector - tobacco products',
  'sector - other crude vegetable oils and by-products',
  'sector - other refined vegetable, olive, and seed oils',
  'sector - all other foods',
  "sector - frozen foods that don't map to other primary components",
  'sector - soft drinks, bottled water, and ice',
  'sector - packaged seafood (farmed and wild-caught)',
  'sector - coffee and tea',
  'sector - flavored drink concentrates',
  'sector - seasonings and dressings (incl. mayonnaise, mustard, prepared sauces/dips)',
  'sector - sugar, candy, and chocolate',
  'no additional upstream processing',
];

export const FUEL_COMBUSTION_REASON = [
  ...MOBILE_FUEL_COMBUSTION_REASON,
  ...STATIONARY_FUEL_COMBUSTION_REASONS, // comes from shared data to share values with building db model
  'sold fuels',
  'other',
];

/**
 * Updated set of constants to categorize vehicles by vehicle category and powertrain type
 */
export const ROAD_VEHICLE_CATEGORIES = [
  'car',
  'motorcycle',
  'van_light_truck',
  'medium_heavy_truck',
];

export const ROAD_VEHICLE_POWERTRAIN_TYPES = [
  'combustion_gasoline',
  'combustion_diesel',
  'hybrid_gasoline',
  'hybrid_diesel',
  'plug_in_hybrid_gasoline',
  'battery_electric',
];

export type RoadVehicleCategory = (typeof ROAD_VEHICLE_CATEGORIES)[number];
export type RoadVehiclePowertrainType =
  (typeof ROAD_VEHICLE_POWERTRAIN_TYPES)[number];

/** The two vehicle enum lists below are deprecated, meant to be replaced by vehicle_category and vehicle_powertrain*/
// Default vehicle types, most BARTs use these only
export const VEHICLE_TYPES_STANDARD = [
  'van_light_truck',
  'motorcycle',
  'medium_heavy_truck',
  'car_US',
  'car_UK',
  'car_ROW',
  'van_light_truck_electric',
  'car_electric',
  'medium_heavy_truck_electric',
] as const;

// All available vehicle types with full detail, for high complexity users
export const VEHICLE_TYPES_DETAILED = [
  'epa_Medium- and Heavy-Duty Truck',
  'epa_Passenger Car A',
  'epa_Light-Duty Truck B',
  'epa_Motorcycle',
  'defra_Vans_Class I (up to 1.305 tonnes)_Diesel',
  'defra_Vans_Class II (1.305 to 1.74 tonnes)_Diesel',
  'defra_Vans_Class III (1.74 to 3.5 tonnes)_Diesel',
  'defra_Vans_Average (up to 3.5 tonnes)_Diesel',
  'defra_Vans_Class I (up to 1.305 tonnes)_Petrol',
  'defra_Vans_Class II (1.305 to 1.74 tonnes)_Petrol',
  'defra_Vans_Class III (1.74 to 3.5 tonnes)_Petrol',
  'defra_Vans_Average (up to 3.5 tonnes)_Petrol',
  'defra_Vans_Average (up to 3.5 tonnes)_CNG',
  'defra_Vans_Average (up to 3.5 tonnes)_LPG',
  'defra_Vans_Average (up to 3.5 tonnes)_Unknown',
  'defra_HGV_Rigid (>3.5 - 7.5 tonnes)_0% Laden_Diesel',
  'defra_HGV_Rigid (>7.5 tonnes-17 tonnes)_0% Laden_Diesel',
  'defra_HGV_Rigid (>17 tonnes)_0% Laden_Diesel',
  'defra_HGV_All rigids_0% Laden_Diesel',
  'defra_HGV_Articulated (>3.5 - 33t)_0% Laden_Diesel',
  'defra_HGV_Articulated (>33t)_0% Laden_Diesel',
  'defra_HGV_All artics_0% Laden_Diesel',
  'defra_HGV_All HGVs_0% Laden_Diesel',
  'defra_HGV_Rigid (>3.5 - 7.5 tonnes)_50% Laden_Diesel',
  'defra_HGV_Rigid (>7.5 tonnes-17 tonnes)_50% Laden_Diesel',
  'defra_HGV_Rigid (>17 tonnes)_50% Laden_Diesel',
  'defra_HGV_All rigids_50% Laden_Diesel',
  'defra_HGV_Articulated (>3.5 - 33t)_50% Laden_Diesel',
  'defra_HGV_Articulated (>33t)_50% Laden_Diesel',
  'defra_HGV_All artics_50% Laden_Diesel',
  'defra_HGV_All HGVs_50% Laden_Diesel',
  'defra_HGV_Rigid (>3.5 - 7.5 tonnes)_100% Laden_Diesel',
  'defra_HGV_Rigid (>7.5 tonnes-17 tonnes)_100% Laden_Diesel',
  'defra_HGV_Rigid (>17 tonnes)_100% Laden_Diesel',
  'defra_HGV_All rigids_100% Laden_Diesel',
  'defra_HGV_Articulated (>3.5 - 33t)_100% Laden_Diesel',
  'defra_HGV_Articulated (>33t)_100% Laden_Diesel',
  'defra_HGV_All artics_100% Laden_Diesel',
  'defra_HGV_All HGVs_100% Laden_Diesel',
  'defra_HGV_Rigid (>3.5 - 7.5 tonnes)_Average Laden_Diesel',
  'defra_HGV_Rigid (>7.5 tonnes-17 tonnes)_Average Laden_Diesel',
  'defra_HGV_Rigid (>17 tonnes)_Average Laden_Diesel',
  'defra_HGV_All rigids_Average Laden_Diesel',
  'defra_HGV_Articulated (>3.5 - 33t)_Average Laden_Diesel',
  'defra_HGV_Articulated (>33t)_Average Laden_Diesel',
  'defra_HGV_All artics_Average Laden_Diesel',
  'defra_HGV_All HGVs_Average Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>3.5 - 7.5 tonnes)_0% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>7.5 tonnes-17 tonnes)_0% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>17 tonnes)_0% Laden_Diesel',
  'defra_HGVs refrigerated_All rigids_0% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>3.5 - 33t)_0% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>33t)_0% Laden_Diesel',
  'defra_HGVs refrigerated_All artics_0% Laden_Diesel',
  'defra_HGVs refrigerated_All HGVs_0% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>3.5 - 7.5 tonnes)_50% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>7.5 tonnes-17 tonnes)_50% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>17 tonnes)_50% Laden_Diesel',
  'defra_HGVs refrigerated_All rigids_50% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>3.5 - 33t)_50% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>33t)_50% Laden_Diesel',
  'defra_HGVs refrigerated_All artics_50% Laden_Diesel',
  'defra_HGVs refrigerated_All HGVs_50% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>3.5 - 7.5 tonnes)_100% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>7.5 tonnes-17 tonnes)_100% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>17 tonnes)_100% Laden_Diesel',
  'defra_HGVs refrigerated_All rigids_100% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>3.5 - 33t)_100% Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>33t)_100% Laden_Diesel',
  'defra_HGVs refrigerated_All artics_100% Laden_Diesel',
  'defra_HGVs refrigerated_All HGVs_100% Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>3.5 - 7.5 tonnes)_Average Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>7.5 tonnes-17 tonnes)_Average Laden_Diesel',
  'defra_HGVs refrigerated_Rigid (>17 tonnes)_Average Laden_Diesel',
  'defra_HGVs refrigerated_All rigids_Average Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>3.5 - 33t)_Average Laden_Diesel',
  'defra_HGVs refrigerated_Articulated (>33t)_Average Laden_Diesel',
  'defra_HGVs refrigerated_All artics_Average Laden_Diesel',
  'defra_HGVs refrigerated_All HGVs_Average Laden_Diesel',
  'defra_Car_Mini_Diesel',
  'defra_Car_Supermini_Diesel',
  'defra_Car_Lower medium_Diesel',
  'defra_Car_Upper medium_Diesel',
  'defra_Car_Executive_Diesel',
  'defra_Car_Luxury_Diesel',
  'defra_Car_Sports_Diesel',
  'defra_Car_Dual purpose 4X4_Diesel',
  'defra_Car_MPV_Diesel',
  'defra_Car_Mini_Petrol',
  'defra_Car_Supermini_Petrol',
  'defra_Car_Lower medium_Petrol',
  'defra_Car_Upper medium_Petrol',
  'defra_Car_Executive_Petrol',
  'defra_Car_Luxury_Petrol',
  'defra_Car_Sports_Petrol',
  'defra_Car_Dual purpose 4X4_Petrol',
  'defra_Car_MPV_Petrol',
  'defra_Car_Mini_Unknown',
  'defra_Car_Supermini_Unknown',
  'defra_Car_Lower medium_Unknown',
  'defra_Car_Upper medium_Unknown',
  'defra_Car_Executive_Unknown',
  'defra_Car_Luxury_Unknown',
  'defra_Car_Sports_Unknown',
  'defra_Car_Dual purpose 4X4_Unknown',
  'defra_Car_Supermini_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Lower medium_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Upper medium_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Executive_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Luxury_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Sports_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Dual purpose 4X4_Plug-in Hybrid Electric Vehicle',
  'defra_Car_MPV_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Small car_Diesel',
  'defra_Car_Medium car_Diesel',
  'defra_Car_Large car_Diesel',
  'defra_Car_Average car_Diesel',
  'defra_Car_Small car_Petrol',
  'defra_Car_Medium car_Petrol',
  'defra_Car_Large car_Petrol',
  'defra_Car_Average car_Petrol',
  'defra_Car_Small car_Hybrid',
  'defra_Car_Medium car_Hybrid',
  'defra_Car_Large car_Hybrid',
  'defra_Car_Average car_Hybrid',
  'defra_Car_Medium car_CNG',
  'defra_Car_Large car_CNG',
  'defra_Car_Average car_CNG',
  'defra_Car_Medium car_LPG',
  'defra_Car_Large car_LPG',
  'defra_Car_Average car_LPG',
  'defra_Car_Small car_Unknown',
  'defra_Car_Medium car_Unknown',
  'defra_Car_Large car_Unknown',
  'defra_Car_Average car_Unknown',
  'defra_Car_Small car_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Medium car_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Large car_Plug-in Hybrid Electric Vehicle',
  'defra_Car_Average car_Plug-in Hybrid Electric Vehicle',
  'defra_Motorbike_Small_Unknown',
  'defra_Motorbike_Medium_Unknown',
  'defra_Motorbike_Large_Unknown',
  'defra_Motorbike_Average_Unknown',
] as const;

// Type capturing all vehicle types used in the `vehicles` bart specifically
export type VehicleTypeOld =
  | (typeof VEHICLE_TYPES_STANDARD)[number]
  | (typeof VEHICLE_TYPES_DETAILED)[number]
  | 'unknown';

export const ALL_OLD_VEHICLES: Array<VehicleTypeOld> = [
  ...VEHICLE_TYPES_STANDARD,
  ...VEHICLE_TYPES_DETAILED,
  'unknown',
];

/* 
`AP42_SCC_W_CONTROLS` maps to the set of ap42 Source Classification Codes with associated controls after filtering the database for:
   (ap42_df['LEVEL1'].isin(["internal combustion engines", "external combustion boilers"])) & 
   (ap42_df['ACTION'].isin(["Burned"])) & 
   (ap42_df['REVOKED'].isna())
*/
export const AP42_SCC_W_CONTROLS = [
  '10100101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100101_UNCONTROLLED',
  '10100102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100102_UNCONTROLLED',
  '10100201_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100201_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100201_UNCONTROLLED',
  '10100202_LOW_NOX_BURNER_(LNB)',
  '10100202_OTHER_CONTROL_DEVICE',
  '10100202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100202_UNCONTROLLED',
  '10100203_OTHER_CONTROL_DEVICE',
  '10100203_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100203_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100203_UNCONTROLLED',
  '10100204_BAGHOUSE',
  '10100204_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100204_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10100204_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10100204_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100204_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100204_UNCONTROLLED',
  '10100205_CYCLONES_(MULTIPLE)',
  '10100205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100205_UNCONTROLLED',
  '10100211_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100211_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100211_UNCONTROLLED',
  '10100212_LOW_NOX_BURNER_(LNB)',
  '10100212_OTHER_CONTROL_DEVICE',
  '10100212_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100212_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100212_UNCONTROLLED',
  '10100215_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100215_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100215_UNCONTROLLED',
  '10100217_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100217_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100217_UNCONTROLLED',
  '10100218_OTHER_CONTROL_DEVICE',
  '10100218_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100218_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100218_UNCONTROLLED',
  '10100221_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100221_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100221_UNCONTROLLED',
  '10100222_OTHER_CONTROL_DEVICE',
  '10100222_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100222_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100222_UNCONTROLLED',
  '10100223_OTHER_CONTROL_DEVICE',
  '10100223_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100223_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100223_UNCONTROLLED',
  '10100224_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10100224_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10100224_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100224_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100224_UNCONTROLLED',
  '10100225_CYCLONES_(MULTIPLE)',
  '10100225_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100225_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100225_UNCONTROLLED',
  '10100226_OTHER_CONTROL_DEVICE',
  '10100226_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100226_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100226_UNCONTROLLED',
  '10100235_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100235_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100235_UNCONTROLLED',
  '10100237_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100237_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100237_UNCONTROLLED',
  '10100238_OTHER_CONTROL_DEVICE',
  '10100238_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100238_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100238_UNCONTROLLED',
  '10100300_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100300_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100300_UNCONTROLLED',
  '10100301_OTHER_CONTROL_DEVICE',
  '10100301_OVERFIRE_AIR',
  '10100301_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100301_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100301_UNCONTROLLED',
  '10100302_OTHER_CONTROL_DEVICE',
  '10100302_OVERFIRE_AIR',
  '10100302_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100302_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100302_UNCONTROLLED',
  '10100303_OTHER_CONTROL_DEVICE',
  '10100303_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100303_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100303_UNCONTROLLED',
  '10100304_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100304_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100304_UNCONTROLLED',
  '10100306_CYCLONES_(MULTIPLE)',
  '10100306_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100306_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100306_UNCONTROLLED',
  '10100316_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100316_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100316_UNCONTROLLED',
  '10100317_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100317_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100317_UNCONTROLLED',
  '10100318_OTHER_CONTROL_DEVICE',
  '10100318_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100318_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100318_UNCONTROLLED',
  '10100401_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100401_LOW_NOX_BURNER_(LNB)',
  '10100401_SCRUBBER',
  '10100401_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100401_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100401_UNCONTROLLED',
  '10100404_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100404_LOW_NOX_BURNER_(LNB)',
  '10100404_SCRUBBER',
  '10100404_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100404_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100404_UNCONTROLLED',
  '10100405_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100405_SCRUBBER',
  '10100405_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100405_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100405_UNCONTROLLED',
  '10100406_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100406_SCRUBBER',
  '10100406_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100406_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100406_UNCONTROLLED',
  '10100501_LOW_NOX_BURNER_(LNB)',
  '10100501_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100501_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100501_UNCONTROLLED',
  '10100504_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100504_SCRUBBER',
  '10100504_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100504_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100504_UNCONTROLLED',
  '10100505_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10100505_SCRUBBER',
  '10100505_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100505_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100505_UNCONTROLLED',
  '10100601_FLUE_GAS_RECIRCULATION',
  '10100601_LOW_NOX_BURNER_(LNB)',
  '10100601_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100601_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100601_UNCONTROLLED',
  '10100602_LOW_NOX_BURNER_(LNB)',
  '10100602_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100602_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100602_UNCONTROLLED',
  '10100604_FLUE_GAS_RECIRCULATION',
  '10100604_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100604_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100604_UNCONTROLLED',
  '10100701_UNCONTROLLED',
  '10100702_UNCONTROLLED',
  '10100801_UNCONTROLLED',
  '10100901_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100901_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100902_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100902_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100903_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100903_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100910_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100910_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100911_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100911_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10100912_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10100912_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10101001_UNCONTROLLED',
  '10101002_',
  '10101002_UNCONTROLLED',
  '10101101_UNCONTROLLED',
  '10101201_OTHER_CONTROL_DEVICE',
  '10101201_UNCONTROLLED',
  '10101202_UNCONTROLLED',
  '10101302_UNCONTROLLED',
  '10200101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200101_UNCONTROLLED',
  '10200104_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200104_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200104_UNCONTROLLED',
  '10200107_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200107_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200107_UNCONTROLLED',
  '10200117_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200117_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200117_UNCONTROLLED',
  '10200201_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200201_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200201_UNCONTROLLED',
  '10200202_LOW_NOX_BURNER_(LNB)',
  '10200202_OTHER_CONTROL_DEVICE',
  '10200202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200202_UNCONTROLLED',
  '10200203_OTHER_CONTROL_DEVICE',
  '10200203_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200203_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200203_UNCONTROLLED',
  '10200204_BAGHOUSE',
  '10200204_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10200204_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10200204_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10200204_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200204_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200204_UNCONTROLLED',
  '10200205_CYCLONES_(MULTIPLE)',
  '10200205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200205_UNCONTROLLED',
  '10200206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200206_UNCONTROLLED',
  '10200210_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200210_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200210_UNCONTROLLED',
  '10200212_LOW_NOX_BURNER_(LNB)',
  '10200212_OTHER_CONTROL_DEVICE',
  '10200212_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200212_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200212_UNCONTROLLED',
  '10200213_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200213_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200213_UNCONTROLLED',
  '10200217_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200217_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200217_UNCONTROLLED',
  '10200218_OTHER_CONTROL_DEVICE',
  '10200218_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200218_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200218_UNCONTROLLED',
  '10200219_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200219_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200219_UNCONTROLLED',
  '10200221_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200221_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200221_UNCONTROLLED',
  '10200222_OTHER_CONTROL_DEVICE',
  '10200222_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200222_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200222_UNCONTROLLED',
  '10200223_OTHER_CONTROL_DEVICE',
  '10200223_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200223_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200223_UNCONTROLLED',
  '10200224_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10200224_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10200224_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200224_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200224_UNCONTROLLED',
  '10200225_CYCLONES_(MULTIPLE)',
  '10200225_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200225_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200225_UNCONTROLLED',
  '10200226_OTHER_CONTROL_DEVICE',
  '10200226_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200226_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200226_UNCONTROLLED',
  '10200229_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200229_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200229_UNCONTROLLED',
  '10200300_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200300_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200300_UNCONTROLLED',
  '10200301_OTHER_CONTROL_DEVICE',
  '10200301_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200301_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200301_UNCONTROLLED',
  '10200302_OTHER_CONTROL_DEVICE',
  '10200302_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200302_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200302_UNCONTROLLED',
  '10200303_OTHER_CONTROL_DEVICE',
  '10200303_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200303_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200303_UNCONTROLLED',
  '10200304_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200304_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200304_UNCONTROLLED',
  '10200306_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200306_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200306_UNCONTROLLED',
  '10200307_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200307_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200307_UNCONTROLLED',
  '10200401_CYCLONES_(MULTIPLE)',
  '10200401_LOW_NOX_BURNER_(LNB)',
  '10200401_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200401_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200401_UNCONTROLLED',
  '10200402_CYCLONES_(MULTIPLE)',
  '10200402_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200402_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200402_UNCONTROLLED',
  '10200403_CYCLONES_(MULTIPLE)',
  '10200403_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200403_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200403_UNCONTROLLED',
  '10200404_CYCLONES_(MULTIPLE)',
  '10200404_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200404_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200404_UNCONTROLLED',
  '10200405_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200405_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200405_UNCONTROLLED',
  '10200501_LOW_NOX_BURNER_(LNB)',
  '10200501_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200501_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200501_UNCONTROLLED',
  '10200502_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200502_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200502_UNCONTROLLED',
  '10200503_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200503_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200503_UNCONTROLLED',
  '10200504_CYCLONES_(MULTIPLE)',
  '10200504_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200504_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200504_UNCONTROLLED',
  '10200505_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200505_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200505_UNCONTROLLED',
  '10200601_FLUE_GAS_RECIRCULATION',
  '10200601_LOW_NOX_BURNER_(LNB)',
  '10200601_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200601_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200601_UNCONTROLLED',
  '10200602_LOW_NOX_BURNER_(LNB)',
  '10200602_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200602_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200602_UNCONTROLLED',
  '10200603_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200603_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200603_UNCONTROLLED',
  '10200604_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200604_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200604_UNCONTROLLED',
  '10200701_UNCONTROLLED',
  '10200704_UNCONTROLLED',
  '10200707_UNCONTROLLED',
  '10200802_UNCONTROLLED',
  '10200804_UNCONTROLLED',
  '10200901_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200901_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200902_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200902_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200903_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200903_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200904_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200904_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200905_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200905_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200906_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200906_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200907_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200907_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200910_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200910_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200911_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200911_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10200912_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10200912_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10201001_UNCONTROLLED',
  '10201002_UNCONTROLLED',
  '10201101_UNCONTROLLED',
  '10201201_UNCONTROLLED',
  '10201202_UNCONTROLLED',
  '10201301_UNCONTROLLED',
  '10201302_UNCONTROLLED',
  '10201401_UNCONTROLLED',
  '10201402_UNCONTROLLED',
  '10201403_UNCONTROLLED',
  '10201404_UNCONTROLLED',
  '10300101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300101_UNCONTROLLED',
  '10300102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300102_UNCONTROLLED',
  '10300103_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300103_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300103_UNCONTROLLED',
  '10300203_OTHER_CONTROL_DEVICE',
  '10300203_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300203_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300203_UNCONTROLLED',
  '10300205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300205_UNCONTROLLED',
  '10300206_LOW_NOX_BURNER_(LNB)',
  '10300206_OTHER_CONTROL_DEVICE',
  '10300206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300206_UNCONTROLLED',
  '10300207_CYCLONES_(MULTIPLE)',
  '10300207_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300207_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300207_UNCONTROLLED',
  '10300208_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10300208_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300208_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300208_UNCONTROLLED',
  '10300209_BAGHOUSE',
  '10300209_ELECTROSTATIC_PRECIPITATOR_-_DRY_(DESP)',
  '10300209_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10300209_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10300209_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300209_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300209_UNCONTROLLED',
  '10300211_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300211_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300211_UNCONTROLLED',
  '10300214_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300214_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300214_UNCONTROLLED',
  '10300216_LOW_NOX_BURNER_(LNB)',
  '10300216_OTHER_CONTROL_DEVICE',
  '10300216_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300216_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300216_UNCONTROLLED',
  '10300217_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300217_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300217_UNCONTROLLED',
  '10300218_OTHER_CONTROL_DEVICE',
  '10300218_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300218_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300218_UNCONTROLLED',
  '10300221_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300221_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300221_UNCONTROLLED',
  '10300222_OTHER_CONTROL_DEVICE',
  '10300222_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300222_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300222_UNCONTROLLED',
  '10300223_OTHER_CONTROL_DEVICE',
  '10300223_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300223_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300223_UNCONTROLLED',
  '10300224_MULTIPLE_CYCLONE_W/O_FLY_ASH_REINJECTION',
  '10300224_MULTIPLE_CYCLONE_W/_FLY_ASH_REINJECTION',
  '10300224_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300224_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300224_UNCONTROLLED',
  '10300225_CYCLONES_(MULTIPLE)',
  '10300225_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300225_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300225_UNCONTROLLED',
  '10300226_OTHER_CONTROL_DEVICE',
  '10300226_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300226_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300226_UNCONTROLLED',
  '10300300_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300300_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300300_UNCONTROLLED',
  '10300305_OTHER_CONTROL_DEVICE',
  '10300305_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300305_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300305_UNCONTROLLED',
  '10300306_OTHER_CONTROL_DEVICE',
  '10300306_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300306_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300306_UNCONTROLLED',
  '10300307_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300307_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300307_UNCONTROLLED',
  '10300309_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300309_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300309_UNCONTROLLED',
  '10300401_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300401_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300401_UNCONTROLLED',
  '10300402_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300402_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300402_UNCONTROLLED',
  '10300403_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300403_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300403_UNCONTROLLED',
  '10300404_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300404_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300404_UNCONTROLLED',
  '10300501_LOW_NOX_BURNER_(LNB)',
  '10300501_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300501_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300501_UNCONTROLLED',
  '10300502_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300502_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300502_UNCONTROLLED',
  '10300503_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300503_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300503_UNCONTROLLED',
  '10300504_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300504_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300504_UNCONTROLLED',
  '10300601_FLUE_GAS_RECIRCULATION',
  '10300601_LOW_NOX_BURNER_(LNB)',
  '10300601_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300601_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300601_UNCONTROLLED',
  '10300602_LOW_NOX_BURNER_(LNB)',
  '10300602_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300602_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300602_UNCONTROLLED',
  '10300603_LOW_NOX_BURNER_(LNB)',
  '10300603_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300603_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300603_UNCONTROLLED',
  '10300701_UNCONTROLLED',
  '10300901_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300901_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300902_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300902_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300903_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300903_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300910_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300910_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300911_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300911_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10300912_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10300912_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10301001_UNCONTROLLED',
  '10301002_UNCONTROLLED',
  '10301201_UNCONTROLLED',
  '10301202_UNCONTROLLED',
  '10301302_UNCONTROLLED',
  '10500102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500102_UNCONTROLLED',
  '10500105_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500105_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500105_UNCONTROLLED',
  '10500106_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500106_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500106_UNCONTROLLED',
  '10500110_UNCONTROLLED',
  '10500113_UNCONTROLLED',
  '10500114_UNCONTROLLED',
  '10500202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500202_UNCONTROLLED',
  '10500205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500205_UNCONTROLLED',
  '10500206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '10500206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '10500206_UNCONTROLLED',
  '10500210_UNCONTROLLED',
  '10500213_UNCONTROLLED',
  '10500214_UNCONTROLLED',
  '20100101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100102_UNCONTROLLED',
  '20100105_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100105_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100106_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100106_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100107_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100107_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100108_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100108_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100109_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100109_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100201_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100201_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100202_UNCONTROLLED',
  '20100205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100207_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100207_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100208_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100208_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100209_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100209_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100901_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100901_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100902_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100902_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100905_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100905_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100906_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100906_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100907_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100907_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100908_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100908_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20100909_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20100909_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200102_UNCONTROLLED',
  '20200103_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200103_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200104_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200104_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200104_UNCONTROLLED',
  '20200105_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200105_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200106_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200106_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200107_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200107_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200108_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200108_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200109_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200109_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200201_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200201_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200202_UNCONTROLLED',
  '20200203_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200203_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200204_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200204_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200204_UNCONTROLLED',
  '20200205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200207_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200207_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200208_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200208_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200209_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200209_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200252_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200252_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200253_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200253_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200254_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200254_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200255_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200255_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200256_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200256_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200301_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200301_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200301_UNCONTROLLED',
  '20200305_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200305_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200306_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200306_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200307_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200307_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200401_OTHER_CONTROL_DEVICE',
  '20200401_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200401_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200401_UNCONTROLLED',
  '20200501_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200501_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200501_UNCONTROLLED',
  '20200901_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200901_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200902_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200902_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200905_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200905_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200906_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200906_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200907_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200907_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200908_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200908_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20200909_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20200909_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300101_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300101_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300101_UNCONTROLLED',
  '20300102_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300102_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300105_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300105_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300106_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300106_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300107_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300107_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300108_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300108_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300109_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300109_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300201_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300201_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300201_UNCONTROLLED',
  '20300202_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300202_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300203_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300203_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300204_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300204_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300205_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300205_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300206_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300206_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300207_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300207_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300208_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300208_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300209_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300209_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300301_SELECTIVE_CATALYTIC_REDUCTION_(SCR)',
  '20300301_SELECTIVE_NONCATALYTIC_REDUCTION_FOR_NOX',
  '20300301_UNCONTROLLED',
  '20400301_UNCONTROLLED',
  '20400302_UNCONTROLLED',
  '20400401_UNCONTROLLED',
  '20400402_UNCONTROLLED',
];

// smart defaults for non-ghg stationary combustion
export const NON_GHG_STATIONARY_COMBUSTION_SMART_DEFAULTS = [
  'boiler_gt_100m_bth_hr__flue_gas_recirculation',
  'boiler_gt_100m_bth_hr__low_nox_burner_(lnb)',
  'boiler_gt_100m_bth_hr__selective_catalytic_reduction_(scr)',
  'boiler_gt_100m_bth_hr__selective_noncatalytic_reduction_for_nox',
  'boiler_gt_100m_bth_hr__uncontrolled',
  'boiler_gt_100m_bth_hr__wet_scrubber_-_medium_efficiency',
  'boiler_gt_10m_lt_100m_bth_hr__low_nox_burner_(lnb)',
  'boiler_gt_10m_lt_100m_bth_hr__selective_catalytic_reduction_(scr)',
  'boiler_gt_10m_lt_100m_bth_hr__selective_noncatalytic_reduction_for_nox',
  'boiler_gt_10m_lt_100m_bth_hr__uncontrolled',
  'boiler_lt_10m_bth_hr__low_nox_burner_(lnb)',
  'boiler_lt_10m_bth_hr__selective_catalytic_reduction_(scr)',
  'boiler_lt_10m_bth_hr__selective_noncatalytic_reduction_for_nox',
  'boiler_lt_10m_bth_hr__uncontrolled',
  'emergency_generator__selective_catalytic_reduction_(scr)',
  'emergency_generator__selective_noncatalytic_reduction_for_nox',
  'emergency_generator__uncontrolled',
];

export const GHG_NAMES = [
  'N2O',
  'CH4',
  'SF6',
  'CO2',
  'CHF2OCHClCF3', // Isoflurane
  '(CF3)2CHOCH2F', // Sevoflurane
  'CHF2OCHFCF3', // Desflurane
  'CO2e',
  'CO2-biogenic',
  'CH4-biogenic',
  '2ClEVE',
  'AE',
  'AEE',
  'Allyl cyanide',
  'Carbon tetrachloride',
  'CFC 1112',
  'CFC 1112a',
  'CFC-11',
  'CFC-112',
  'CFC-112a',
  'CFC-113',
  'CFC-113a',
  'CFC-114',
  'CFC-114a',
  'CFC-115',
  'CFC-12',
  'CFC-13',
  'Chloroform',
  'E-R316c',
  'EDB',
  'Fluroxene',
  'Halon-1011',
  'Halon-1201',
  'Halon-1202',
  'Halon-1211',
  'Halon-1301',
  'Halon-2301',
  'Halon-2311',
  'Halon-2401',
  'Halon-2402',
  'HCFC-121',
  'HCFC-122',
  'HCFC-122a',
  'HCFC-123',
  'HCFC-123a',
  'HCFC-124',
  'HCFC-124a',
  'HCFC-132',
  'HCFC-132a',
  'HCFC-132c',
  'HCFC-133a',
  'HCFC-141',
  'HCFC-141b',
  'HCFC-142b',
  'HCFC-21',
  'HCFC-22',
  'HCFC-225ca',
  'HCFC-225cb',
  'HCFC-31',
  'HCFE-235ca2',
  'HCFE-235da2',
  'HCFO-1233zd(E)',
  'HCFO-1233zd(Z)',
  'HFC-125',
  'HFC-134',
  'HFC-134a',
  'HFC-143',
  'HFC-143a',
  'HFC-152',
  'HFC-152a',
  'HFC-161',
  'HFC-227ca',
  'HFC-227ea',
  'HFC-23',
  'HFC-236cb',
  'HFC-236ea',
  'HFC-236fa',
  'HFC-245ca',
  'HFC-245cb',
  'HFC-245ea',
  'HFC-245eb',
  'HFC-245fa',
  'HFC-263fb',
  'HFC-272ca',
  'HFC-32',
  'HFC-329p',
  'HFC-365mfc',
  'HFC-41',
  'HFC-43-10mee',
  'HFE-125',
  'HFE-134',
  'HFE-143a',
  'HFE-216',
  'HFE-227ea',
  'HFE-236ca12',
  'HFE-236ea2',
  'HFE-236fa',
  'HFE-245cb2',
  'HFE-245fa1',
  'HFE-245fa2',
  'HFE-254cb1',
  'HFE-263m1',
  'HFE-263mf',
  'HFE-329mcc2',
  'HFE-329me3',
  'HFE-338mcf2',
  'HFE-338mmz1',
  'HFE-338pcc13',
  'HFE-347mcc3',
  'HFE-347mcf2',
  'HFE-347mmy1',
  'HFE-347mmz1',
  'HFE-347pcf2',
  'HFE-356mec3',
  'HFE-356mff2',
  'HFE-356mmz1',
  'HFE-356pcc3',
  'HFE-356pcf2',
  'HFE-356pcf3',
  'HFE-365mcf3',
  'HFE-374pc2',
  'HFE-43-10pccc124',
  'HFE-449s1',
  'HFE-569sf2',
  'HFE-7300',
  'HFE-7500',
  'HFIP',
  'HFO-1123',
  'HFO-1132a',
  'HFO-1141',
  'HFO-1225ye(E)',
  'HFO-1225ye(Z)',
  'HFO-1234yf',
  'HFO-1234ze(E)',
  'HFO-1234ze(Z)',
  'HFO-1243zf',
  'HFO-1336mzz(E)',
  'HFO-1336mzz(Z)',
  'HFO-1345zfc',
  'HFO-1438ezy(E)',
  'HFO-1447fz',
  'HG-02',
  'HG-03',
  'HG-04',
  'HG--01',
  'HG--02',
  'HG--03',
  'i-HFE-7100',
  'i-HFE-7200',
  'Methyl bromide',
  'Methyl chloride',
  'Methyl chloroform',
  'Methylene bromide',
  'Methylene chloride',
  'MVK',
  'n-HFE-7100',
  'Octafluorooxolane',
  'PFC-1114',
  'PFC-116',
  'PFC-1216',
  'PFC-14',
  'PFC-218',
  'PFC-31-10',
  'PFC-41-12',
  'PFC-51-14',
  'PFC-61-16',
  'PFC-71-18',
  'PFC-91-18',
  'PFC-C-318',
  'PFPMIE',
  'PFTBA',
  'PTPA',
  'Z-R316c',
  'CCl3F',
  'CCl2F2',
  'CClF3',
  'CCl2FCCl2F',
  'CCl3CClF2',
  'CCl2FCClF2',
  'CCl3CF3',
  'CClF2CClF2',
  'CCl2FCF3',
  'CClF2CF3',
  'trans cyc (-CClFCF2CF2CClF-)',
  'cis cyc (-CClFCF2CF2CClF-)',
  'CClF=CClF',
  'CCl2=CF2',
  'CHCl2F',
  'CHClF2',
  'CH2ClF',
  'CHCl2CCl2F',
  'CHCl2CClF2',
  'CHClFCCl2F',
  'CHCl2CF3',
  'CHClFCClF2',
  'CHClFCF3',
  'CHF2CClF2',
  'CHClFCHClF',
  'CHCl2CHF2',
  'CH2FCCl2F',
  'CH2ClCF3',
  'CH2ClCHClF',
  'CH3CCl2F',
  'CH3CClF2',
  'CHCl2CF2CF3',
  'CHClFCF2CClF2',
  '(E)-CF3CH=CHCl',
  '(Z)-CF3CH=CHCl',
  '(E/Z)-CHCl=CHF',
  'CHF3',
  'CH2F2',
  'CH3F',
  'CHF2CF3',
  'CHF2CHF2',
  'CH2FCF3',
  'CH2FCHF2',
  'CH3CF3',
  'CH2FCH2F',
  'CH3CHF2',
  'CH3CH2F',
  'CF3CF2CHF2',
  'CF3CHFCF3',
  'CH2FCF2CF3',
  'CHF2CHFCF3',
  'CF3CH2CF3',
  'CH2FCF2CHF2',
  'CF3CF2CH3',
  'CHF2CHFCHF2',
  'CH2FCHFCF3',
  'CHF2CH2CF3',
  'CH3CH2CF3',
  'CH3CF2CH3',
  'CHF2CF2CF2CF3',
  'CH3CF2CH2CF3',
  'CF3CHFCHFCF2CF3',
  'CHF=CF2',
  'CH2=CF2',
  'CH2=CHF',
  '(Z)-CF3CF=CHF',
  '(E)-CF3CF=CHF',
  '(Z)-CF3CH=CHF',
  '(E)-CF3CH=CHF',
  'CF3CF=CH2',
  '(E)-CF3CH=CHCF3',
  '(Z)-CF3CH=CHCF3',
  'CF3CH=CH2',
  'CF3CF2CH=CH2',
  'n-C4F9CH=CH2',
  'n-C6F13CH=CH2',
  'n-C8F17CH=CH2',
  '(CF3)2C=CH2',
  'cyc (-CF2CF2CF2CH2CH2-)',
  'cyc (-CF2CF2CF2CHFCH2-)',
  'cyc (-CF2CF2CF2CF=CH-)',
  'trans-cyc (-CF2CF2CF2CHFCHF-)',
  '(E)-(CF3)2CFCH=CHF',
  'CF3(CF2)2CH=CH2',
  'cyc (-CH=CFCF2CF2-)',
  'cyc (-CH=CHCF2CF2-)',
  'CH3CCl3',
  'CCl4',
  'CH3Cl',
  'CH2Cl2',
  'CHCl3',
  'CH3CH2Cl',
  'CH2ClCH2Cl',
  'CHCl=CCl2',
  'CCl2=CCl2',
  'CH3CHClCH3',
  'CH3(CH2)2CH2Cl',
  'CH3Br',
  'CH2Br2',
  'CHBrF2',
  'CBr2F2',
  'CBrClF2',
  'CBrF3',
  'CH2BrCF3',
  'CHBrClCF3',
  'CHBrFCF3',
  'CBrF2CBrF2',
  'CHBr3',
  'CH2BrCl',
  'CH3CH2Br',
  'CH2BrCH2Br',
  'CH3CH2CH2Br',
  'CH3CHBrCH3',
  'NF3',
  'N(C2F5)3',
  'N(CF2CF2CF3)3',
  'N(CF2CF2CF2CF3)3',
  'N(CF2CF2CF2CF2CF3)3',
  '(CF3)2CFCN',
  'SF6',
  'SF5CF3',
  'SO2F2',
  'CF4',
  'C2F6',
  'C3F8',
  'cyc (-CF=CFCF2CF2-)',
  'cyc (-CF2CF2CF2CF2-)',
  'n-C4F10',
  'cyc (-CF2CF2CFCF2CF2-)',
  'n-C5F12',
  'n-C6F14',
  'n-C7F16',
  'n-C8F18',
  'C10F18',
  'Z-C10F18',
  'E-C10F18',
  'CF2=CF2',
  'CF3CF=CF2',
  'CF2=CFCF=CF2',
  'CF3CF2CF=CF2',
  'CF3CF=CFCF3',
  'CHF2OCF3',
  'CHF2OCHF2',
  'CH3OCF3',
  'CF3CHFOCF3',
  'CHF2OCF2CHFCl',
  'CHF2OCHClCF3',
  'CHF2OCHFCF3',
  'CF3CH2OCF3',
  'CF3CF2OCH3',
  'CHF2CH2OCF3',
  'CHF2OCH2CF3',
  'CF3CF2CH2OH',
  'CH3OCF2CHF2',
  'CF3CH2OCH3',
  'CF3OCH2CH3',
  'CF3CH2CH2OH',
  'CHF2CF2OCF2CF3',
  '(CF3)2CHOCHF2',
  'CF3CH2OCF2CF3',
  '(CF3)2CHOCH2F',
  'CH3OCF2CF2CF3',
  'CHF2CH2OCF2CF3',
  'CHF2CF2OCH2CF3',
  '(CF3)2CFOCH3',
  'CH3OCF2CHFCF3',
  'CF3CH2OCH2CF3',
  'CHF2CH2OCF2CHF2',
  'CHF2OCH2CF2CHF2',
  'CH3OCF2CF2CHF2',
  '(CF3)2CHOCH3',
  'CF3CF2CH2OCH3',
  'CHF2CF2OCH2CH3',
  'CF3(CH2)2CH2OH',
  'cyc (-(CF2)4CH(OH)-)',
  'CHF2OCF2OCF2CF2OCHF2',
  'C4F9OCH3',
  'CF3CF2CF2CF2OCH3',
  '(CF3)2CFCF2OCH3',
  'C4F9OC2H5',
  '(CF3)2CFCF2OCH2CH3',
  '(CF3)2CFCFOC2H5CF2CF2CF3',
  'n-C3F7CFOC2H5CF(CF3)2',
  'CHF2OCF2OCHF2',
  'CHF2OCF2CF2OCHF2',
  '(CF3)2CHOH',
  'CHF2(OCF2CF2)2OCHF2',
  'CHF2(OCF2CF2)3OCHF2',
  'CF3CH2OCH=CH2',
  'C12H5F19O2',
  'CH3OCHF2',
  'CH3OCF2CF2OCH3',
  'CH3O(CF2CF2O)2CH3',
  'CH3O(CF2CF2O)3CH3',
  'CF3CFHCF2OCF3',
  'CF3(CF2)4CH2CH2OH',
  'CF3(CF2)6CH2CH2OH',
  'CF3(CF2)8CH2CH2OH',
  'CH3OCF2CHClF',
  'CF3OCFCF3CF2OCF2OCF3',
  'CF3OCF=CF2',
  'CF3CF2OCHO',
  'CF3CH2OCHO',
  '(CF3)2CHOCHO',
  'CF3COOCH=CH2',
  'CF3COOCH2CH3',
  'CF3COOCH2CH=CH2',
  'CF3COOCH3',
  'CF3CF2CF2CH2OH',
  'CHF2CHFOCF3',
  'CF3CHFCF2OCH2CH3',
  'CF3CF2CF2OCHFCF3',
  'CHF2CF2CH2OH',
  'CF3CHFCF2CH2OH',
  'CHF2CF2CH2OCH3',
  'CF3CF2COCF(CF3)2',
  'CF3CH2CHO',
  'CH2FCH2OH',
  'CHF2CH2OH',
  'CF3CH2OH',
  'CHF2O(CF2CF2O)4CHF2',
  'CH3OC7F13',
  'CF3COCH3',
  'CF3COCH2CH3',
  'ClCH2CH2OCH=CH2',
  'C2H6',
  'C3H8',
  'n-C4H10',
  'CH3CH2COCH(CH3)2',
  'CH3CH2OCH3',
  'c-C4F8O',
  'CH3CH=CHCHO',
  'CH3COCH=CH2',
  '(CH2=CHCH2)2O',
  'CH3CH2OCH2CH=CH2',
  'CH3CH2CH2CH=CHCH2OH_z',
  'CH3CH2CH2CH=CHCH2OH_e',
  'CH2=CHCH2CN',
  'C6H18OSi2',
  'C8H24O2Si3',
  'C10H30O3Si4',
  'C12H36O4Si5',
  'C6H18O3Si3',
  'C8H24O4Si4',
  'C10H30O5Si5',
  'C12H36O6Si6',
];

export const PRIVATE_JET_TYPES = [
  'Beechcraft Hawker 1000',
  'Beechcraft King Air 200',
  'Beechcraft King Air 90',
  'Bombardier 45XR',
  'Bombardier Challenger 300',
  'Bombardier Challenger 350',
  'Bombardier Challenger 604',
  'Bombardier Challenger 605',
  'Bombardier Challenger 650',
  'Bombardier Challenger 850',
  'Bombardier Challenger 850ER',
  'Bombardier CL-600 Challenger',
  'Bombardier CL-601 Challenger',
  'Bombardier CL-601-3A Challenger',
  'Bombardier CL-604 Challenger',
  'Bombardier Global 5000',
  'Bombardier Global 5500',
  'Bombardier Global 6000',
  'Bombardier Global 6500',
  'Bombardier Global 7500',
  'Bombardier Global Express',
  'Bombardier Global Express XRS',
  'Bombardier Learjet 75/70',
  'Cessna 425 Conquest I',
  'Cessna 425 Corsair',
  'Cessna 441 Conquest',
  'Cessna 441 Conquest II',
  'Cessna 550 Citation Bravo',
  'Cessna 560 Citation V',
  'Cessna 560 Citation V Ultra',
  'Cessna 560-XL Citation Excel',
  'Cessna 650 Citation III',
  'Cessna 650 Citation VI',
  'Cessna 650 Citation VII',
  'Cessna 680 Citation Sovereign',
  'Cessna 750 Citation X',
  'Cessna Citation 500',
  'Cessna Citation 650',
  'Cessna Citation CJ1 525',
  'Cessna Citation CJ1 Plus 525',
  'Cessna Citation CJ2',
  'Cessna Citation CJ2 525A',
  'Cessna Citation CJ2 Plus 525A',
  'Cessna Citation CJ3 525B',
  'Cessna Citation Encore',
  'Cessna Citation I 500',
  'Cessna Citation I 500/501',
  'Cessna Citation II 550',
  'Cessna Citation II 551',
  'Cessna Citation Latitude',
  'Cessna Citation Longitude',
  'Cessna Citation Sovereign+ 680',
  'Cessna Citation VII',
  'Cessna Citation X+',
  'Cessna Citation XLS',
  'Cessna Citationjet 525',
  'Cessna S550 Citation SII',
  'Citation Mustang',
  'Dassault 2000EX',
  'Dassault 2000LX',
  'Dassault Falcon 10',
  'Dassault Falcon 100',
  'Dassault Falcon 20 C',
  'Dassault Falcon 20 D',
  'Dassault Falcon 20 F',
  'Dassault Falcon 200',
  'Dassault Falcon 2000',
  'Dassault Falcon 2000EX',
  'Dassault Falcon 50',
  'Dassault Falcon 7X',
  'Dassault Falcon 8X',
  'Dassault Falcon 900',
  'Dassault Falcon 900 B',
  'Dassault Falcon 900EX',
  'Dassault Lineage 1000',
  'Embraer Legacy 450',
  'Embraer Legacy 500',
  'Embraer Legacy EMB-135BJ',
  'Embraer Lineage 1000',
  'Embraer Phenom 100',
  'Embraer Phenom 300',
  'Embraer Praetor 500',
  'Embraer Praetor 600',
  'Falcon 2000',
  'Gulfstream G IV',
  'Gulfstream G IVSP',
  'Gulfstream G V',
  'Gulfstream G100',
  'Gulfstream G150',
  'Gulfstream G200',
  'Gulfstream G300',
  'Gulfstream G350',
  'Gulfstream G400',
  'Gulfstream G450',
  'Gulfstream G500',
  'Gulfstream G550',
  'Gulfstream G650',
  'Gulfstream G650ER',
  'Gulfstream GI',
  'Gulfstream GII',
  'Gulfstream GII-B',
  'Gulfstream GIII',
  'Hawker 1000',
  'Hawker 4000',
  'Hawker 400XP',
  'Hawker 750',
  'Hawker 800',
  'Hawker 800XP',
  'Hawker 850 XP Pro Line',
  'Hawker 900XP',
  'Hawker Horizon 4000',
  'King Air 350i',
  'Learjet 23 Twin Jet',
  'Learjet 24 B',
  'Learjet 24 C',
  'Learjet 24 D',
  'Learjet 24 E',
  'Learjet 24 F',
  'Learjet 24 Twin Jet',
  'Learjet 25 B',
  'Learjet 25 B,C',
  'Learjet 25 C',
  'Learjet 25 D,F',
  'Learjet 25 G',
  'Learjet 28 Longhorn',
  'Learjet 29 Longhorn',
  'Learjet 31 Std',
  'Learjet 35',
  'Learjet 35A',
  'Learjet 36',
  'Learjet 36 A',
  'Learjet 40',
  'Learjet 45',
  'Learjet 55',
  'Learjet 55C',
  'Learjet 55C/ER',
  'Learjet 55C/LR',
  'Learjet 60',
  'Pilatus PC-12',
  'Pilatus PC-24',
  'Piper Chieftain',
  'Piper PA-31 Navajo',
  'Vision Jet SF50',
];

export const PRODUCT_CATEGORIES = [
  'Room Air Conditioners',
  'Dehumidifiers',
  'Air Cleaners',
  'Ventilating Fans - Bathroom Fans',
  'Ventilating Fans - Inline Fans',
  'Ventilating Fans - Range Hoods',
  'Standard Ceiling Fan',
  'Hugger Ceiling Fan',
  'Standard Ceiling Fan with Light Kit',
  'Hugger Ceiling Fan with Light Kit',
  'Dishwashers - Standard',
  'Dishwashers - Compact',
  'Refrigerators - Top Freezer',
  'Refrigerators - Bottom Freezer',
  'Refrigerators - Side by Side',
  'Freezers - Chest Freezer',
  'Freezers - Upright Freezer',
  'Clothes Washers - Top Loading',
  'Clothes Washers - Front Loading',
  'Clothes Dryers - Standard Electric Dryer (Vented Ventless)',
  'Clothes Dryers - Gas Dryers',
  'Water Heaters - Gas Storage Water Heater',
  'Water Heaters - Electric (Heat Pump) Water Heater',
  'Water Heaters - Tankless Gas Water Heater',
  'Halogen Bulb',
  'LED Bulb',
  'CFL Bulb',
  'Cove Mount',
  'Downlights < 4.5"',
  'Downlights > 4.5"',
  'Downlight Retrofits < 4.5"',
  'Downlight Retrofits > 4.5"',
  'Accent Lights',
  'Undercabinet',
  'Outdoor Wall/Porch/Post',
  'Portable Desk Task',
  'Inseparable SSL',
  'Fluorescent (CFL) (800 lm)',
  'Fluorescent (CFL) (450 lm)',
  'Fluorescent (CFL) (250 lm)',
  'Solid State Light Engine - With Optics (800 lm)',
  'Solid State Light Engine - With Optics (450 lm)',
  'Solid State Light Engine - With Optics (250 lm)',
  'Solid State Light Engine - Without Optics (800 lm)',
  'Solid State Light Engine - Without Optics (450 lm)',
  'Solid State Light Engine - Without Optics (250 lm)',
  'Solid State Retrofit: Sconce - With Optics',
  'Solid State Retrofit: Sconce - Without Optics',
  'Solid State Retrofit: Ceiling Mount - With Optics',
  'Solid State Retrofit: Ceiling Mount - Without Optics',
  'Decorative Light Strings',
  'TV - LCD TVs 20" and under',
  'TV - LCD TVs 21"-23"',
  'TV - LCD TVs 24"-29"',
  'TV - LCD TVs 30"-34"',
  'TV - LCD TVs 35"-39"',
  'TV - LCD TVs 40"-44"',
  'TV - LCD TVs 45"-49"',
  'TV - LCD TVs 50"-54"',
  'TV - LCD TVs 55"-59"',
  'TV - LCD TVs 60"-64"',
  'TV - LCD TVs 65" or greater',
  'TV - Plasma TVs under 60"',
  'TV - Plasma TVs 60" and up',
  'TV - OLED TVs',
  'DVD Players',
  'Blu Ray',
  'Audio Equipment - Receivers',
  'Audio Equipment - Home Theater In-a-Box',
  'Audio Equipment - Compact Systems',
  'Audio Equipment - Soundbars',
  'Audio Equipment - Other Powered Speakers',
  'Audio Equipment - Home Radios',
  'Audio Equipment - Portable Media/MP3 Player Speakers and Docks',
  'Residential Telephony',
  'Commercial Telephony (VOIP)',
  'Set Top Box - Cable',
  'Set Top Box - Cable Digital Transport Adapter',
  'Set Top Box - Satellite',
  'Set Top Box - Multichannel Video Programming Distributor (MVPD) Internet Protocol (IP)',
  'Set Top Box - Over-the-Top (OTT) Internet Protocol (IP)',
  'Set Top Box - Thin-client',
  'Computer Servers',
  'Workstation',
  'Printer (Mono, Laser)',
  'Multifunction Device (Mono, Laser)',
  'Printer (Color, Laser)',
  'Multifunction Device (Color, Laser)',
  'Multifunction Device (Inkjet)',
  'Scanner (Inkjet)',
  'Printer (Inkjet)',
  'Professional Displays',
  'Servers',
  'Small Network Equipment',
  'Residential UPS',
  'Small Commercial UPS',
  'Larger Commercial UPS',
  'Home Desktop-Power Management Not Enabled',
  'Home Desktop-Power Management Enabled',
  'Home Laptop-Power Management Not Enabled',
  'Home Laptop-Power Management Enabled',
  'Home Monitors-Power Management Not Enabled',
  'Home Monitors-Power Management Enabled',
  'Office Desktop-Power Management Not Enabled',
  'Office Desktop-Power Management Enabled',
  'Office Laptop-Power Management Not Enabled',
  'Office Laptop-Power Management Enabled',
  'Office Monitors-Power Management Not Enabled',
  'Office Monitors-Power Management Enabled',
  'Furnace (Gas)',
  'Furnace (Oil)',
  'Furnace (Gas) - North',
  'Furnace (Gas) - South',
  'Central Air Conditioner',
  'Air-Source Heat Pump',
  'Geothermal Heat Pump',
  'Boiler - Gas',
  'Boiler - Oil',
  'Smart Thermostat',
  'Water Coolers - Hot and Cold',
  'Water Coolers - Cook and Cold',
  'Commercial Refrigerators - Vertical Closed Solid Door',
  'Commercial Refrigerators - Vertical Closed Transparent Door',
  'Commercial Refrigerators - Horizontal Closed Solid and Transparent',
  'Commercial Freezers - Vertical Closed Solid Door',
  'Commercial Freezers - Vertical Closed Transparent Door',
  'Commercial Freezers - Horizontal Closed Solid and Transparent',
  'Hot Food Holding Cabinets',
  'Fryers (Large Vat) - Electric Fryers',
  'Fryers (Large Vat) - Gas Fryers',
  'Fryers (Standard) - Electric Fryers',
  'Fryers (Standard) - Gas Fryers',
  'Steamers - Electric Steamers',
  'Steamers - Gas Steamers',
  'Ice Machines - Batch Ice Machines',
  'Ice Machines - Continuous Ice Machines',
  'Dishwashers - Flight Type',
  'Dishwashers - Other Dishwasher',
  'Vending Machines',
  'Griddles - Electric Griddles',
  'Griddles - Gas Griddles',
  'Ovens - Full-size Gas Convection Ovens',
  'Ovens - Half-size Electric Convection Ovens',
  'Ovens - Full-size Electric Convection Ovens',
  'Ovens - Gas Combination Ovens',
  'Ovens - Electric Combination Ovens',
  'Ovens - Single Gas Rack Ovens',
  'Ovens - Double Gas Rack Ovens',
  'Clothes Washers',
  'Water Heaters - Gas-fired Storage Water Heaters',
  'Water Heaters - Gas-fired Instantaneous Water Heaters',
  'Water Heaters - Electric Water Heaters',
  'LCHVAC',
  'Pool Pumps - Above-Ground',
  'Pool Pumps - Below-Ground',
  'Pool Pumps - Pressure Cleaner Booster',
  'Electric Vehicle Supply Equipment',
  'Commercial Boilers',
  'Commercial Coffee Brewers',
  'Boom Box',
  'Cable Boxes (standby losses)',
  'CD Player',
  'DVD Player',
  'Receiver',
  'satellite stations (standby losses)',
  'Tape Player',
  'Telephone Answering Machine',
  'TV (CRT - Projection)',
  'TV (CRT)',
  'TV (DLP)',
  'TV (Plasma)',
  'VCRs',
  'Video Games',
  'Computer CPU',
  'home copiers',
  'Home facsimile machines (thermal)',
  'Home fax/Multi-function device (inkjet)',
  'Laptop Charger',
  'Monitor',
  'Router/DSL/Cable Modem',
  'Pool Heater',
  'Pool Pump',
  'Spa (24 hour elec)',
  'Spa (24 hour gas)',
  'Spa (on-demand elec)',
  'Spa (on-demand gas)',
  'Sump/Sewage Pump',
  'Well Pump',
  'Irrigation/pond/waterfall circulation pump [1]',
  'Bottled Water Dispenser',
  'Broilers',
  'Coffee Maker: Drip (Brew)',
  'Coffee Maker: Drip (Warm)',
  'Coffee Maker: Percolater (Brew)',
  'Coffee Maker: Percolater (Warm)',
  'Compactors',
  'Deep Fryer',
  'Espresso Maker',
  'Fry Pans',
  'Instant Hot Water',
  'Microwaves',
  'Slow Cookers',
  'Toaster',
  'Toaster Oven - Toasting',
  'Toaster Oven - Oven',
  'Aquariums',
  'Auto Engine Heaters',
  'Clock',
  'Doorbell',
  'Electric Blankets',
  'Electric Grills',
  'Electronic Air Cleaner/Filter',
  'Garage Door Openers',
  'Gas Grills',
  'Gas Lighting',
  'Hair Dryers',
  'Heat Tape',
  'Humidifier',
  'Irons',
  'Pipe and Gutter Heaters',
  'Rechargable Handheld Vacuum (charging)',
  'Vacuum - Canister',
  'Vacuum - Upright',
  'Water Bed Heaters',
  'Small household appliances',
  'Medium household appliances',
  'Large household appliances',
  'Small kitchen appliances',
  'Electronics - small',
  'Electronics - medium',
  'Power Supply',
  'Heaters',
  'Power tools',
];

export const WATER_UNIT = [
  'gallons',
  'liter',
  'cubic_meter',
  'mcf',
  'kilogallon',
  'cf',
  'centigallon',
  'ccf',
] as const;

export const AQUEDUCT_VERSION = ['4.0'] as const;

// Keep in sync with py/watershed/models/building.py
export const WASTE_MATERIAL = [
  'aluminum_cans',
  'beef',
  'bread',
  'clothing',
  'commercial_and_industrial',
  'concrete',
  'construction_waste',
  'corrugated_containers',
  'crt_displays',
  'dairy_products',
  'desktop_cpus',
  'electronic_peripherals',
  'flat_panel_displays',
  'food_waste',
  'food_waste_meat_only',
  'food_waste_non_meat',
  'fruits_and_vegetables',
  'glass',
  'grains',
  'hard_copy_devices',
  'hdpe',
  'ldpe',
  'lldpe',
  'mixed_electronics',
  'mixed_metals',
  'mixed_msw',
  'mixed_organics',
  'mixed_paper_general',
  'mixed_plastics',
  'mixed_recyclables',
  'newspaper',
  'pet',
  'pla',
  'portable_electronic_devices',
  'poultry',
  'pp',
  'ps',
  'pvc',
  'steel_cans',
  'unspecified',
  'wood',
] as const;

export const WASTE_LIFECYCLES = ['operations', 'end_of_life'] as const;

// Keep in sync with py/watershed/models/building.py
export const WASTE_TREATMENTS = [
  'combusted',
  'landfilled',
  'recycled',
  'prepared_for_reuse',
  'anaerobically_digested',
  'composted',
  'unspecified',
] as const;

export const TIER_1_INDUSTRIES = [
  '311-Food',
  '3112-Grain and Oilseed Milling',
  '311221-Wet Corn Milling',
  '31131-Sugar Manufacturing',
  '3114-Fruit and Vegetable Preserving and Specialty Food',
  '3115-Dairy Product',
  '3116-Animal Slaughtering and Processing',
  '312-Beverage and Tobacco Products',
  '3121-Beverages',
  '3122-Tobacco',
  '313-Textile Mills',
  '314-Textile Product Mills',
  '315-Apparel',
  '316-Leather and Allied Products',
  '321-Wood Products',
  '321113-Sawmills',
  '3212-Veneer, Plywood, and Engineered Woods',
  '321219-Reconstituted Wood Products',
  '3219-Other Wood Products',
  '322-Paper',
  '322110-Pulp Mills',
  '322121-Paper Mills, except Newsprint',
  '322122-Newsprint Mills',
  '322130-Paperboard Mills',
  '323-Printing and Related Support',
  '324-Petroleum and Coal Products',
  '324110-Petroleum Refineries',
  '324121-Asphalt Paving Mixture and Block',
  '324122-Asphalt Shingle and Coating Materials',
  '324191-Petroleum Lubricating Oil and Grease Products',
  '324199-Other Petroleum and Coal Products',
  '325-Chemicals',
  '325110-Petrochemicals',
  '325120-Industrial Gases',
  '325180-Other Basic Inorganic Chemicals',
  '325193-Ethyl Alcohol',
  '325194-Cyclic Crudes, Intermediate and Gum and Wood Chemicals',
  '325199-Other Basic Organic Chemicals',
  '325211-Plastics Materials and Resins',
  '325212-Synthetic Rubber',
  '325220-Artificial and Synthetic Fibers and Filaments',
  '325311-Nitrogenous Fertilizers',
  '325312-Phosphatic Fertilizers',
  '3254-Pharmaceuticals and Medicines',
  '325412-Pharmaceutical Preparation',
  '325992-Photographic Film, Paper, Plate, and Chemicals',
  '326-Plastics and Rubber Products',
  '327-Nonmetallic Mineral Products',
  '327120 -Clay Building Material and Refractories',
  '327211-Flat Glass',
  '327212-Other Pressed and Blown Glass and Glassware',
  '327213-Glass Containers',
  '327215-Glass Products from Purchased Glass',
  '327310-Cements',
  '327410-Lime',
  '327420-Gypsum',
  '327993-Mineral Wool',
  '331-Primary Metals',
  '331110-Iron and Steel Mills and Ferroalloys',
  '3312-Steel Products from Purchased Steel',
  '3313-Alumina and Aluminum',
  '331314-Secondary Smelting and Alloying of Aluminum',
  '331315-Aluminum Sheet, Plate and Foils',
  '331318-Other Aluminum Rolling, Drawing and Extruding',
  '3314-Nonferrous Metals, except Aluminum',
  '331410-Nonferrous Metal (except Aluminum) Smelting and Refining',
  '3315-Foundries',
  '331511-Iron Foundries',
  '331523-Nonferrous Metal Die-Casting Foundries',
  '331524-Aluminum Foundries, except Die-Casting',
  '332-Fabricated Metal Products',
  '333-Machinery',
  '334-Computer and Electronic Products',
  '334413-Semiconductors and Related Devices',
  '335-Electrical Equip., Appliances, and Components',
  '336-Transportation Equipment',
  '336111-Automobiles',
  '336112-Light Trucks and Utility Vehicles',
  '3364-Aerospace Product and Parts',
  '336411-Aircraft',
  '337-Furniture and Related Products',
  '339-Miscellaneous ',
] as const;

export const PACKAGING_MATERIALS = [
  'Corrugated board box',
  'Packaging film',
  'Kraft paper',
  'PET bottle',
  'Packaging film',
  'Kraft paper',
  'Liquid packaging board container',
  'Packaging glass',
  'Steel can / container',
  'Aluminum can / container',
  'Wood',
  'Solid bleached and unbleached board',
  'Folding box board',
  'Folding box board, white lined',
  'Paper, woodfree, coated',
  'Paper, wood containing, coated',
  'HDPE bottle / container',
  'HDPE film',
  'PP bottle / container',
  'PP film',
  'Synthetic rubber band / packaging',
  'LLDPE film',
  'LDPE bottle / container',
  'PET bottle / container',
  'PS bottle / container',
  'PS film',
  'PMMA caps / other packaging',
  'PC bottle / container',
  'Polyurethane foam',
  'Nylon',
  'EVA film',
  'LDPE film',
  'Cotton, woven',
  'Magnet',
  'Ceramics containers',
  'Latex film',
  'PVC bottle / container',
  'PVC film',
  'Recycled graphic paper',
  'Newspaper',
  'Styrofoam packing peanuts',
  'Biodegradable peanuts',
  'Carbon dioxide liquid',
  'Bagasse',
  'Polyacrylamide',
  'Tape',
  'Compartment boxes & lids',
  'Drink cup lids',
  'Soup cups & lids',
  'Plastic cutlery',
  'Sushi box & lids',
  'Plastic straws',
  'ABS resin',
  'AS resin',
  'HDPE resin',
  'PP resin',
  'Synthetic rubber',
  'PTFE resin',
  'LLDPE resin',
  'LDPE resin',
  'PET resin (bottle grade)',
  'PS resin',
  'PMMA beads',
  'PC resin',
  'Melamine formaldehyde resin',
  'SAN resin',
  'EVA resin',
  'Recycled PET resin (100%)',
  'Recycled aluminum (100%)',
  'Recycled steel (100%)',
  'Recycled PET bottle (100%)',
  'Recycled steel can / container (100%)',
  'Recycled aluminum can / container (100%)',
  'Recycled glass / cullet (100%)',
  'Polyester',
  'Polyamide packaging',
  'PLA resin',
  'PLA bottle / container',
  'PLA film',
  'Ferrous metal can / container',
  'Other textiles',
] as const;

export const FOOD_USE_CASES = ['own_use', 'sold_product', 'shrink'];

export const APPAREL_PRODUCT_CATEGORIES = [
  'Apparel Accessories',
  'Dress',
  'Hosiery',
  'Underwear',
  'Leggings / Tights',
  'Baselayer',
  'Jacket',
  'Jersey (Uniform)',
  'Pants',
  'Shirts',
  'Skirt',
  'Socks',
  'Sweater',
  'Swimsuit',
  'T-Shirt',
  'Pillow',
  'Lighting shade',
  'Upholstery',
  'Duvet',
  'Rug',
  'Cushion',
  'Duvet Cover',
  'Comforter',
  'Quilt',
  'Sham',
  'Slip Cover',
  'Window Curtain',
  'Shower Curtain',
  'Mattress Pad',
  'Blanket',
  'Mat',
  'Table Cloth',
  'Towel',
  'Sheet',
  'Kitchen Towel',
  'Napkin',
  'Place Mat',
  'Pajama Set',
] as const;

export const FABRIC_CATEGORIES = [
  'cotton',
  'synthetic',
  'wool',
  'delicate',
] as const;

export const WASH_METHODS = [
  'machine_wash_warm',
  'machine_wash_cold',
  'dry_clean',
  'hand_wash',
  'no_wash',
] as const;
export const WASH_METHOD_LABEL_MAPPING: Record<
  (typeof WASH_METHODS)[number],
  string
> = {
  machine_wash_cold: 'Machine wash cold',
  machine_wash_warm: 'Machine wash warm',
  dry_clean: 'Dry clean',
  hand_wash: 'Hand wash',
  no_wash: 'No wash',
};

export const DRY_METHODS = ['air', 'machine', 'no_dry'] as const;
export const DRY_METHOD_LABEL_MAPPING: Record<
  (typeof DRY_METHODS)[number],
  string
> = {
  air: 'Air',
  machine: 'Machine',
  no_dry: 'No dry',
};

export const REFRIGERANT_LEAKAGE_SOURCES = [
  'installed',
  'manufacturing',
] as const;

export const CSRD_MATERIALS_ACTIVITY_TYPES: Array<PrimaryTypeName> = [
  'consumer_goods_packaging_material',
  'consumer_goods_material_assessment',
  'consumer_goods_ecoinvent_material',
] as const;

export const DATA_TRANSFER_USE_CASES = [
  'Baseline (inactive)',
  'Web or app browsing',
  'Music streaming or YouTube',
  'Video streaming',
  'Video streaming (HD)',
  'Video streaming (UHD)',
  'File download',
  'Unspecified',
] as const;

export const DATA_TRANSFER_NETWORK_TYPES = ['Wi-Fi', 'Cellular data'] as const;

export const TRIP_KINDS = ['one_way', 'round_trip'] as const;

export const LOGISTICS_POSITION_VALUES = [
  'upstream',
  'downstream',
  'first_party',
  'tier_2_to_tier_1',
] as const;

export const LOGISTICS_POSITION_LABEL_MAPPING: Record<
  (typeof LOGISTICS_POSITION_VALUES)[number],
  string
> = {
  upstream: 'upstream',
  downstream: 'downstream',
  first_party: 'first party',
  tier_2_to_tier_1: 'tier 2 to tier 1',
} as const;

export const DISTANCE_METHOD_VALUES = [
  'distance_provided',
  'distance_great_circle_derived',
] as const;

export const DISTANCE_METHOD_LABEL_MAPPING: Record<
  (typeof DISTANCE_METHOD_VALUES)[number],
  string
> = {
  distance_provided: 'distance provided',
  distance_great_circle_derived: 'distance great circle derived',
};

export const TRIP_KIND_LABEL_MAPPING: Record<
  (typeof TRIP_KINDS)[number],
  string
> = {
  one_way: 'One way',
  round_trip: 'Round trip',
};

// Cabin class can be a comma-separated list of these options, though in IDI we only
// accept a single one in practice.
export const FLIGHT_CABIN_CLASSES = [
  'first',
  'business',
  'premium_economy',
  'economy',
  'unknown',
] as const;

export const FLIGHT_CABIN_CLASSES_LABEL_MAPPING: Record<
  (typeof FLIGHT_CABIN_CLASSES)[number],
  string
> = {
  first: 'First',
  business: 'Business',
  premium_economy: 'Premium economy',
  economy: 'Economy',
  unknown: 'Unknown',
};

export const LODGING_KIND_VALUES = ['hotel_upscale'] as const;

export const RAIL_TRANSPORTATION_MODES: Array<TRANSPORTATION_MODES_TYPE> = [
  'train_commuter',
  'train_intercity',
  'train_transit',
  'light_rail',
  'international_rail',
  'national_rail',
  'london_underground',
] as const;

export const LOBBYING_CONTRIBUTION_KIND = ['in_kind', 'financial'] as const;

export const LOBBYING_EXPENSE_KIND = [
  'direct_political_expense',
  'internal_or_external_lobbying_expense',
  'lobbying_membership_expense',
] as const;

// As defined by CSRD
export const GENDER = ['male', 'female', 'unknown', 'other'] as const;
export const OWN_WORKFORCE_TYPE = ['employees', 'non_employees'] as const;
export const EMPLOYMENT_TYPE = ['full_time', 'part_time'] as const;
export const EMPLOYEE_GROUP_TYPE = [
  'permanent',
  'temporary',
  'employee_with_non_guaranteed_hours',
] as const;
export const AGE_GROUPS = ['under_30', '30_50', 'over_50'] as const;

// We use these three types because the CSRD asks for 3 different quant entries about non-employees.
// - Number of self-employed people
// - Number of people provided by undertakings primarily engaged in employment activities
// - Number of non-employees (total)
export const NON_EMPLOYEE_TYPE = [
  'self_employed_people',
  'people_provided_by_undertakings_primarily_engaged_in_employment_activities',
  'other',
] as const;

// As defined by Finance
// A non-GQ version of GQIciAlignmentStatus
export const ICI_ALIGNMENT_STATUS = [
  'Aligned',
  'Aligning',
  'CapturingData',
  'NotStarted',
  'PreparingToDecarbonize',
] as const;

// Used by the finance footprint snapshot - defines whether we should refer to the scope 3 or subscope 3 overrides
export const SCOPE_3_OVERRIDE_KIND = ['Overall', 'Subscope'] as const;
