/* eslint-disable @watershed/no-custom-colors */

/**
 * OPERATION VAN GOGH
 * The goal here is to rethink our color palette with an earthly quality
 */

const COLOR_COBALT_100 = '#032CA5';
const COLOR_COBALT_70 = '#1246E0';
const COLOR_COBALT = '#265CFF';
const COLOR_COBALT_50 = '#265CFF';
const COLOR_COBALT_40 = '#658DFD';
const COLOR_COBALT_30 = '#93AEFF';
const COLOR_COBALT_20 = '#C1D2FE';
const COLOR_COBALT_10 = '#E3EBFF';
const COLOR_COBALT_05 = '#F5F8FF';

const COLOR_COBALT_GRADIENT = `linear-gradient(90deg, ${COLOR_COBALT_70} 0%, ${COLOR_COBALT} 100%)`;

const COLOR_GREY_100 = '#041330';
const COLOR_GREY_80 = '#232C40';
const COLOR_GREY_70 = '#4F596E';
const COLOR_GREY_50 = '#7A8499';
const COLOR_GREY_40 = '#BEC7DA';
const COLOR_GREY_30 = '#D1D8E5';
const COLOR_GREY_20 = '#E5E9F0';
const COLOR_GREY_10 = '#F0F4F9';
const COLOR_GREY_05 = '#F8FAFC';
const COLOR_WHITE = '#FFFFFF';

// EXTENDED DATA VIZ PALETTE
const COLOR_SPRING_100 = '#15522F';
const COLOR_SPRING_70 = '#4A7D40';
const COLOR_SPRING = '#8AB280';
const COLOR_SPRING_30 = '#AFD9A5';
const COLOR_SPRING_20 = '#C8E8BE';
const COLOR_SPRING_10 = '#E0EDDD';
const COLOR_SPRING_5 = '#EFF7ED';

const COLOR_MARIGOLD_100 = '#7D440F';
const COLOR_MARIGOLD_70 = '#B26D27';
const COLOR_MARIGOLD = '#ECA74E';
const COLOR_MARIGOLD_30 = '#EDB872';
const COLOR_MARIGOLD_20 = '#FAD49B';
const COLOR_MARIGOLD_10 = '#FDEBCF';
const COLOR_MARIGOLD_5 = '#FFF4E3';

const COLOR_SUN_100 = '#7A301F';
const COLOR_SUN_70 = '#B03F23';
const COLOR_SUN_50 = '#D26247';
const COLOR_SUN_30 = '#F0947F';
const COLOR_SUN_20 = '#FAB5A5';
const COLOR_SUN_10 = '#FDDCD5';
const COLOR_SUN_5 = '#FFECE8';
const COLOR_SUN = '#E77054';

const COLOR_FOREST_100 = '#033838';
const COLOR_FOREST_70 = '#1D7368';
const COLOR_FOREST = '#299D8F';
const COLOR_FOREST_50 = '#299D8F';
const COLOR_FOREST_30 = '#69C3B8';
const COLOR_FOREST_20 = '#9FE0DA';
const COLOR_FOREST_10 = '#D7F7F4';
const COLOR_FOREST_5 = '#E6FAF8';

// CATEGORICAL DATA VIZ PALETTE
const COLOR_MIDNIGHT = '#0B2471';
const COLOR_AFTERNOON = '#E9C471';
const COLOR_PERIWINKLE = COLOR_COBALT_30;
const COLOR_POPPY = '#FF6633';
const COLOR_SEAFOAM = '#C4E7F4';
const COLOR_STRAW = '#F6F4D7';
const COLOR_MARINE_BLUE = COLOR_COBALT_70;
const COLOR_TANGERINE = '#FF9966';
const COLOR_GRASS = '#00BB81';

export const CATEGORICAL_DATA_VIZ_SCALE = [
  COLOR_MIDNIGHT,
  COLOR_SPRING,
  COLOR_AFTERNOON,
  COLOR_PERIWINKLE,
  COLOR_POPPY,
  COLOR_FOREST_70,
  COLOR_MARIGOLD,
  COLOR_SEAFOAM,
  COLOR_STRAW,
  COLOR_MARINE_BLUE,
  COLOR_TANGERINE,
  COLOR_GRASS,
] as const;

// OTHER BRAND MOMENTS
const COLOR_NEW_DAY = '#FFFBF3';
const COLOR_SLATE = COLOR_GREY_100;

const COLOR_ERROR_LIGHT = COLOR_SUN_10;
const COLOR_ERROR = COLOR_SUN;
const COLOR_ERROR_DARK = COLOR_SUN_70;
const COLOR_WARNING_LIGHT = COLOR_MARIGOLD_10;
const COLOR_WARNING = COLOR_MARIGOLD;
const COLOR_WARNING_DARK = COLOR_MARIGOLD_70;
const COLOR_SUCCESS_LIGHT = COLOR_SPRING_10;
const COLOR_SUCCESS = COLOR_SPRING;
const COLOR_SUCCESS_DARK = COLOR_SPRING_70;
const COLOR_INFO_LIGHT = COLOR_GREY_10;
const COLOR_INFO = COLOR_GREY_50;
const COLOR_INFO_DARK = COLOR_GREY_70;

const COLOR_SHADOW_LIGHT = 'rgba(255, 255, 255, 0.5)';
const COLOR_SECONDARY = COLOR_GREY_70;
const COLOR_DIVIDER = COLOR_GREY_20;
const COLOR_BORDER = COLOR_GREY_20;

export const MONOCHROME_DATA_VIZ_THREE_SCALE = [
  COLOR_COBALT_30,
  COLOR_COBALT,
  COLOR_COBALT_100,
] as const;

export const MONOCHROME_DATA_VIZ_SPRING_THREE_SCALE = [
  COLOR_SPRING_70,
  COLOR_SPRING,
  COLOR_SPRING_30,
] as const;

export const MONOCHROME_DATA_VIZ_SUN_THREE_SCALE = [
  COLOR_SUN,
  COLOR_SUN_20,
  COLOR_SUN_10,
] as const;

export const MONOCHROME_DATA_VIZ_FIVE_SCALE = [
  COLOR_COBALT_20,
  COLOR_COBALT_30,
  COLOR_COBALT,
  COLOR_COBALT_70,
  COLOR_COBALT_100,
] as const;

export const MONOCHROME_DATA_VIZ_SIX_SCALE = [
  COLOR_COBALT_10,
  COLOR_COBALT_30,
  COLOR_COBALT_40,
  COLOR_COBALT,
  COLOR_COBALT_70,
  COLOR_COBALT_100,
] as const;

export const MONOCHROME_DATA_VIZ_FOREST_FIVE_SCALE = [
  COLOR_FOREST_100,
  COLOR_FOREST_70,
  COLOR_FOREST_50,
  COLOR_FOREST_20,
  COLOR_FOREST_10,
] as const;

export const MONOCHROME_DATA_VIZ_FULL_COBALT = [
  COLOR_COBALT_100,
  COLOR_COBALT,
  COLOR_COBALT_30,
  COLOR_COBALT_10,
] as const;

export const MONOCHROME_DATA_VIZ_FULL_MARIGOLD = [
  COLOR_MARIGOLD_100,
  COLOR_MARIGOLD,
  COLOR_MARIGOLD_30,
  COLOR_MARIGOLD_10,
] as const;

export const MONOCHROME_DATA_VIZ_FULL_SUN = [
  COLOR_SUN_100,
  COLOR_SUN_70,
  COLOR_SUN_50,
  COLOR_SUN,
  COLOR_SUN_30,
  COLOR_SUN_10,
] as const;

export const BENCHMARK_DATA_VIZ_SCALE = [
  COLOR_GREY_30,
  COLOR_COBALT_100,
  COLOR_COBALT,
] as const;

export const MONOCHROME_DATA_VIZ_EXTENDED = [
  COLOR_SPRING_100,
  COLOR_SPRING_70,
  COLOR_SPRING,
  COLOR_SPRING_30,
  COLOR_SPRING_20,
  COLOR_SPRING_10,
  COLOR_SPRING_5,
  COLOR_MARIGOLD_100,
  COLOR_MARIGOLD_70,
  COLOR_MARIGOLD,
  COLOR_MARIGOLD_30,
  COLOR_MARIGOLD_20,
  COLOR_MARIGOLD_10,
  COLOR_MARIGOLD_5,
  COLOR_SUN_100,
  COLOR_SUN_70,
  COLOR_SUN,
  COLOR_SUN_30,
  COLOR_SUN_20,
  COLOR_SUN_10,
  COLOR_SUN_5,
  COLOR_FOREST_100,
  COLOR_FOREST_70,
  COLOR_FOREST,
  COLOR_FOREST_30,
  COLOR_FOREST_20,
  COLOR_FOREST_10,
  COLOR_FOREST_5,
] as const;

export const MONOCHROME_DATA_VIZ_SOFT = [
  COLOR_SPRING_30,
  COLOR_SPRING_20,
  COLOR_SPRING_10,
  COLOR_SPRING_5,
  COLOR_MARIGOLD_30,
  COLOR_MARIGOLD_20,
  COLOR_MARIGOLD_10,
  COLOR_MARIGOLD_5,
  COLOR_SUN_30,
  COLOR_SUN_20,
  COLOR_SUN_10,
  COLOR_SUN_5,
  COLOR_FOREST_30,
  COLOR_FOREST_20,
  COLOR_FOREST_10,
  COLOR_FOREST_5,
] as const;

export const MONOCHROME_DATA_VIZ_BRIGHT = [
  COLOR_SPRING_100,
  COLOR_SPRING,
  COLOR_SPRING_30,
  COLOR_SPRING_10,
] as const;

export const MONOCHROME_DATA_VIZ_BRIGHT_EXTENDED = [
  COLOR_SPRING_100,
  COLOR_SPRING_70,
  COLOR_SPRING,
  COLOR_SPRING_30,
  COLOR_SPRING_20,
] as const;

// Used by #WinFinance for a focal point chart that stands out from the other monochrome charts
export const EMPHASIS_DATA_VIZ_FIVE_SCALE = [
  COLOR_FOREST_70,
  COLOR_SUN_70,
  COLOR_MARIGOLD_70,
  COLOR_SPRING_70,
  COLOR_COBALT_70,
] as const;

// Used by #Reporting in visualizing a histogram bar with two datapoints
export const MONOCHROME_DATA_VIZ_GREEN_TWO_SCALE = [
  COLOR_FOREST_70,
  COLOR_SPRING,
] as const;

export const AVATAR_COLORS = [
  COLOR_COBALT_100,
  COLOR_COBALT_70,
  COLOR_COBALT_50,
  COLOR_COBALT_40,
  COLOR_COBALT_30,
  COLOR_COBALT_20,
  COLOR_SPRING_100,
  COLOR_SPRING_70,
  COLOR_SPRING,
  COLOR_SPRING_30,
  COLOR_MARIGOLD_100,
  COLOR_MARIGOLD_70,
  COLOR_MARIGOLD,
  COLOR_MARIGOLD_30,
  COLOR_MARIGOLD_20,
  COLOR_SUN_100,
  COLOR_SUN_70,
  COLOR_SUN_50,
  COLOR_SUN,
  COLOR_SUN_30,
  COLOR_SUN_20,
  COLOR_FOREST_100,
  COLOR_FOREST_70,
  COLOR_FOREST,
  COLOR_FOREST_30,
  COLOR_FOREST_20,
  COLOR_MIDNIGHT,
  COLOR_AFTERNOON,
  COLOR_POPPY,
  COLOR_SEAFOAM,
  COLOR_TANGERINE,
  COLOR_GRASS,
] as const;

const COLOR_PAPER = COLOR_WHITE;
const COLOR_PANEL = COLOR_GREY_10;
const COLOR_SURFACE = COLOR_GREY_20;
const COLOR_LIGHT_GREY = COLOR_GREY_30;

export const palette = {
  background: {
    default: COLOR_SURFACE,
    paper: COLOR_WHITE,
    dark: COLOR_SLATE,
  },
  text: {
    primary: COLOR_SLATE,
    secondary: COLOR_SECONDARY,
    disabled: COLOR_GREY_30,
  },
  primary: {
    light: COLOR_COBALT_05,
    main: COLOR_COBALT,
    dark: COLOR_COBALT_100,
    contrastText: COLOR_WHITE,
  },
  secondary: {
    light: COLOR_PANEL,
    main: COLOR_LIGHT_GREY,
    dark: COLOR_SECONDARY,
    contrastText: COLOR_WHITE,
  },
  success: {
    light: COLOR_SUCCESS_LIGHT,
    main: COLOR_SUCCESS,
    dark: COLOR_SUCCESS_DARK,
    contrastText: COLOR_WHITE,
  },
  warning: {
    light: COLOR_WARNING_LIGHT,
    main: COLOR_WARNING,
    dark: COLOR_WARNING_DARK,
    contrastText: COLOR_WHITE,
  },
  error: {
    light: COLOR_ERROR_LIGHT,
    main: COLOR_ERROR,
    dark: COLOR_ERROR_DARK,
    contrastText: COLOR_WHITE,
  },
  info: {
    light: COLOR_INFO_LIGHT,
    main: COLOR_INFO,
    dark: COLOR_INFO_DARK,
    contrastText: COLOR_WHITE,
  },
  decorative: {
    light: COLOR_FOREST_10,
    main: COLOR_FOREST_50,
    dark: COLOR_FOREST_70,
    contrastText: COLOR_WHITE,
  },

  cobalt100: COLOR_COBALT_100,
  cobalt70: COLOR_COBALT_70,
  cobalt: COLOR_COBALT,
  cobalt50: COLOR_COBALT_50,
  cobalt40: COLOR_COBALT_40,
  cobalt30: COLOR_COBALT_30,
  cobalt20: COLOR_COBALT_20,
  cobalt10: COLOR_COBALT_10,
  cobalt05: COLOR_COBALT_05,

  white: COLOR_WHITE,
  grey05: COLOR_GREY_05,
  grey10: COLOR_GREY_10,
  grey20: COLOR_GREY_20,
  grey30: COLOR_GREY_30,
  grey40: COLOR_GREY_40,
  grey50: COLOR_GREY_50,
  grey70: COLOR_GREY_70,
  grey80: COLOR_GREY_80,
  grey100: COLOR_GREY_100,

  newDay: COLOR_NEW_DAY,
  paper: COLOR_PAPER,
  panel: COLOR_PANEL,
  surface: COLOR_SURFACE,
  lightGrey: COLOR_LIGHT_GREY,
  slate: COLOR_SLATE,

  divider: COLOR_DIVIDER,
  border: COLOR_BORDER,
  lightShadow: COLOR_SHADOW_LIGHT,

  midnight: COLOR_MIDNIGHT,
  spring05: COLOR_SPRING_5,
  spring20: COLOR_SPRING_20,
  spring: COLOR_SPRING,
  spring70: COLOR_SPRING_70,
  spring100: COLOR_SPRING_100,
  afternoon: COLOR_AFTERNOON,
  periwinkle: COLOR_PERIWINKLE,
  poppy: COLOR_POPPY,
  forest: COLOR_FOREST,
  forest10: COLOR_FOREST_10,
  forest30: COLOR_FOREST_30,
  forest50: COLOR_FOREST_50,
  forest20: COLOR_FOREST_20,
  forest70: COLOR_FOREST_70,
  marigold: COLOR_MARIGOLD,
  marigold05: COLOR_MARIGOLD_5,
  marigold20: COLOR_MARIGOLD_20,
  marigold70: COLOR_MARIGOLD_70,
  marigold100: COLOR_MARIGOLD_100,
  seafoam: COLOR_SEAFOAM,
  straw: COLOR_STRAW,
  marineBlue: COLOR_MARINE_BLUE,
  tangerine: COLOR_TANGERINE,
  grass: COLOR_GRASS,
  sun: COLOR_SUN,
  sun05: COLOR_SUN_5,
  sun10: COLOR_SUN_10,
  sun20: COLOR_SUN_20,
  sun30: COLOR_SUN_30,
  sun50: COLOR_SUN_50,

  cobaltGradient: COLOR_COBALT_GRADIENT,
};

export type WatershedPalette = typeof palette;
