import React, { useState, MouseEvent, useId, useEffect, useRef } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import {
  PopoverOrigin,
  Menu,
  MenuItem,
  Box,
  Theme,
  SxProps,
  Typography,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { Stack, SystemStyleObject } from '@mui/system';
import { Tab, Tabs } from '@watershed/ui-core/components/Tab';
import Button, { ButtonProps } from './Button';
import MoreIcon from '@watershed/icons/components/More';
import type { IconProps } from '@watershed/icons/Icon';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import ChevronUpIcon from '@watershed/icons/components/ChevronUp';
import must from '@watershed/shared-universal/utils/must';
import { mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import IconButton from './IconButton';
import assertNever from '@watershed/shared-util/assertNever';
import { TextFieldSearch } from './Form/TextField';
import ComposeIcon from '@watershed/icons/components/Compose';
import uniqBy from 'lodash/uniqBy';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import SearchIcon from '@watershed/icons/components/Search';
import { search } from 'fast-fuzzy';
import useLocale from '@watershed/intl/frontend/useLocale';

export const colorDangerousSx: SystemStyleObject<Theme> = {
  // Extra CSS specificity
  '&&': {
    color: (theme) => theme.palette.error.main,
  },
};

const colorSuccessSx: SystemStyleObject<Theme> = {
  // Extra CSS specificity
  '&&': {
    color: (theme) => theme.palette.success.dark,
  },
};

export type DropdownMenuAnalyticsEvent = 'open' | 'close';

export interface DropdownItem {
  id: string;
  testId?: string;
  Icon?: React.ComponentType<IconProps>;
  EndIcon?: React.ComponentType<IconProps>;
  label: React.ReactNode;
  /**
   * Specify additional text you want to use for searching
   * (this will be used in addition to the item label & sublabel)
   */
  searchText?: string;
  sublabel?: React.ReactNode;
  onSelect?: (id: string) => void;
  selected?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'dangerous' | 'success';
  variantIconOnly?: boolean;
  menuItemSx?: SxProps<Theme>;
  innardSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  tooltip?: React.ReactNode;
  tooltipProps?: Partial<TooltipProps>;
  scrollIntoView?: boolean;
  /**
   * Optional: specify a unique group name to pair with `groupLayout`
   * (future reader: could split this into another groupKey and render prop if needed)
   */
  groupLabel?: LocalizedString;
}

export function DropdownItemRendered({
  item,
  dense = false,
  setAnchorEl,
  minWidth,
  maxWidth = '100%',
  renderInlineGroupLabel,
}: {
  item: DropdownItem;
  /**
   * Whether we want to render a menu item to use less padding and smaller icons.
   */
  dense?: boolean;
  setAnchorEl: (anchor: HTMLButtonElement | null) => void;
  minWidth: number | string;
  maxWidth?: number | string;
  /**
   * If this is the start of a group, we show a border-top and we add the group
   * label above the item itself. This is only true if we're in inline groups mode.
   */
  renderInlineGroupLabel?: boolean;
}) {
  const ref = useRef<HTMLLIElement | null>(null);
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    must(item.onSelect)(item.id);
    setAnchorEl(null);
    event.stopPropagation();
  };

  useEffect(() => {
    if (item.scrollIntoView) {
      ref.current?.scrollIntoView();
    }
  }, [item.scrollIntoView]);

  const itemVariantColorSx = (() => {
    switch (item.variant) {
      case 'dangerous':
        return colorDangerousSx;
      case 'success':
        return colorSuccessSx;
      case 'primary':
      case undefined:
        return undefined;
      default:
        assertNever(item.variant);
    }
  })();

  const innards =
    item.Icon || item.EndIcon ? (
      <Box
        sx={mixinSx(
          {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: 1,
          },
          item.innardSx
        )}
      >
        {item.Icon && (
          <item.Icon
            size={20}
            sx={mixinSx(
              {
                color: (theme) => theme.palette.text.secondary,
                display: 'block',
                flexShrink: 0,
              },
              itemVariantColorSx
            )}
          />
        )}
        <Box flexGrow={1} sx={{ minWidth: 0 }}>
          <Typography variant="body1" color="inherit" sx={item.labelSx}>
            {item.label}
          </Typography>
          {item.sublabel && (
            <Typography
              variant="body3"
              color="textSecondary"
              sx={{ whiteSpace: 'normal' }}
            >
              {item.sublabel}
            </Typography>
          )}
        </Box>
        {item.EndIcon && (
          <item.EndIcon
            size={20}
            sx={mixinSx(
              {
                color: (theme) => theme.palette.text.secondary,
                display: 'block',
                flexShrink: 0,
              },
              itemVariantColorSx
            )}
          />
        )}
      </Box>
    ) : (
      <Box sx={item.innardSx}>
        <Typography variant="body1" color="inherit" sx={item.labelSx}>
          {item.label}
        </Typography>
        {item.sublabel && (
          <Typography
            paragraph
            variant="body3"
            color="textSecondary"
            sx={{
              textWrap: 'balance',
              marginBlockEnd: 0,
              whiteSpace: 'normal',
            }}
          >
            {item.sublabel}
          </Typography>
        )}
      </Box>
    );

  // Show a label for this item if it's the start of a group (and we're using
  // inline group layout).
  const maybeGroupLabel = renderInlineGroupLabel ? (
    <Typography
      key={`groupLabel-${item.groupLabel}`}
      variant={dense ? 'body3' : 'body2'}
      style={{ minWidth, maxWidth }}
      component="div"
      sx={mixinSx({
        borderTop: (theme) => `1px solid ${theme.palette.border}`,
        color: (theme) => theme.palette.text.secondary,
        cursor: 'default',
        paddingInline: '10px',
        paddingBlockStart: 1.5,
        paddingBlockEnd: 0.5,
      })}
    >
      {item.groupLabel}
    </Typography>
  ) : null;

  const menuItem = (
    <>
      {maybeGroupLabel}
      <MenuItem
        data-testid={item.testId}
        key={item.id}
        onClick={item.onSelect ? handleClick : undefined}
        style={{ minWidth, maxWidth }}
        disabled={item.disabled}
        selected={item.selected}
        sx={mixinSx(
          {
            minHeight: 0,
            '&.Mui-selected': {
              background: (theme) => theme.palette.cobalt05,
            },
          },
          // When dense, don't divide the menu items, make the padding smaller,
          // and make icons smaller
          dense && {
            border: 'none',
            paddingInline: 1.5,
            paddingBlock: '4px',
            '&:first-of-type': {
              marginBlockStart: 0.5,
            },
            '&:last-of-type': {
              marginBlockEnd: 0.5,
            },
            // consistent smaller icon size
            '& svg': {
              width: 14,
              height: 14,
            },
          },
          item.menuItemSx,
          item.variantIconOnly ? undefined : itemVariantColorSx
        )}
        ref={ref}
      >
        {innards}
      </MenuItem>
    </>
  );

  // For tooltips to work on MenuItems that are disabled, we have to wrap the MenuItem in a div.
  // See https://stackoverflow.com/questions/63691972/react-material-ui-tooltip-hover-overfire-an-event-trigger-when-the-item-is-disa
  return item.tooltip ? (
    <Tooltip title={item.tooltip} {...item.tooltipProps}>
      <Box
        sx={{
          // We apply `borderTop: none` to all first-of-type MenuItems, so we need
          // to re-apply this border here. See
          // https://github.com/watershed-climate/ghg/blob/2ebc6b5dd06c54e3bc50ddc1f9584e1abb1a4391/workspaces/shared-frontend/styles/themes/default.tsx#L831
          // When dense, don't divide the menu items
          borderTop: !dense
            ? (theme) => `1px solid ${theme.palette.border}`
            : 'none',
        }}
      >
        {menuItem}
      </Box>
    </Tooltip>
  ) : (
    menuItem
  );
}

export function DropdownTrigger({
  trigger,
  triggerStartIcon,
  triggerEndIcon,
  menuElementId,
  handleClick,
  buttonProps,
  open,
  testId,
  sx,
}: {
  trigger?: React.ReactNode;
  triggerStartIcon?: React.ReactNode;
  triggerEndIcon?: React.ReactNode;
  menuElementId: string;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
  buttonProps?: DropdownMenuProps['buttonProps'];
  open: Boolean;
  testId?: string;
  sx?: SxProps<Theme>;
}) {
  return trigger ? (
    <Button
      data-testid={testId ?? TestIds.DropdownMenuButton}
      aria-label="Actions"
      aria-controls={menuElementId}
      aria-haspopup="true"
      onClick={handleClick}
      startIcon={triggerStartIcon}
      sx={sx}
      endIcon={
        triggerEndIcon !== undefined ? (
          triggerEndIcon
        ) : open ? (
          <ChevronUpIcon />
        ) : (
          <ChevronDownIcon />
        )
      }
      {...buttonProps}
      size={buttonProps?.size ?? 'medium'}
    >
      {trigger}
    </Button>
  ) : (
    <IconButton
      data-testid={testId ?? TestIds.DropdownMenuButton}
      aria-label="Actions"
      aria-controls={menuElementId}
      aria-haspopup="true"
      sx={sx}
      onClick={handleClick}
      {...buttonProps}
      size={buttonProps?.size ?? 'large'}
    >
      {triggerEndIcon ?? <MoreIcon />}
    </IconButton>
  );
}

const DropdownSearchbar: React.FC<{
  /** Pass along a testId, which will be the suffix to the TextFieldSearch's testId */
  testIdSuffix?: string;
  value: string;
  onChange: (value: string) => void;
  endNode?: React.ReactNode;
}> = ({ testIdSuffix: testId, value, onChange, endNode }) => {
  return (
    <Stack
      direction="row"
      padding={1}
      gap={1}
      alignItems="center"
      onKeyDown={(e) => {
        // Ugh MUI - we're working against some aggressive a11y defaults
        // We want to stop certain key event propagations from triggering behaviors:
        // - if the key is one letter, focus jumps to the menu item with the first
        //   letter, and the focus leaves the input field. Bad!
        //   desire: let us keep typing, and use arrow keys to select the menu item
        // - if we hit 'Tab', then the focus leaves the menu and causes the whole
        //   menu to close (advancing to the next parent control)
        //   desire: hit tab lets us advance to other controls
        if (e.key.length === 1 || e.key === 'Tab') {
          e.stopPropagation();
        }
      }}
      tabIndex={-1}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.border}`,
      })}
    >
      <TextFieldSearch
        autoFocus
        id={testId ?? ''}
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        sx={{
          flexGrow: 1,
        }}
        size="small"
      />
      {endNode}
    </Stack>
  );
};

const DropdownGroupTabs: React.FC<{
  options: Array<string>;
  value: string;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  return (
    <Tabs
      orientation="vertical"
      value={value}
      sx={(theme) => ({
        flex: 1,
        overflow: 'auto',
        position: 'relative',
        minWidth: '140px',
        maxWidth: '180px',
        '& .MuiTabs-flexContainer': {
          gap: 0,
        },
        '& .MuiTab-root': {
          fontWeight: 'normal',
          color: theme.palette.text.primary,
          borderLeft: `2px solid transparent`,
          paddingLeft: 1,
          paddingRight: 1,
          paddingY: 1.5,
          textAlign: 'left',
          // Must be inline-block for ellipsis
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiTab-root.Mui-selected': {
          borderLeftColor: theme.palette.primary.main,
          background: theme.palette.cobalt05,
        },
        borderRight: `1px solid ${theme.palette.border}`,
      })}
      onChange={(_, value) => onChange(value)}
      TabIndicatorProps={{
        // The tab indicator doesn't size itself correctly on first mount, and
        // the motion is actually pretty distracting/doesn't follow
        // the background color change.
        hidden: true,
      }}
    >
      {options.map((value) => (
        <Tab key={value} label={value} value={value} />
      ))}
    </Tabs>
  );
};

const DropdownSearchEmptyState: React.FC<{
  filterText?: LocalizedString;
}> = ({ filterText }) => {
  return (
    <Stack
      gap={1}
      flex={1}
      alignItems="center"
      justifyContent="center"
      padding={2}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={24}
        height={24}
        borderRadius={1}
        bgcolor={(theme) => theme.palette.grey20}
      >
        <SearchIcon size={16} />
      </Box>
      <Typography variant="body3" color="textSecondary">
        <Trans context="Overfiltered state for a search dropbox">
          No results for “{filterText}”
        </Trans>
      </Typography>
    </Stack>
  );
};

const defaultDropdownSearchFn = (
  items: Array<DropdownItem>,
  state: { filterText: string }
): Array<DropdownItem> => {
  return search(state.filterText, items, {
    keySelector: (item) =>
      [
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        item.label?.toString(),
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        item.sublabel?.toString(),
        item.searchText,
      ].filter(isNotNullish),
  });
};

/**
 * Manage the state of filtering with groups so that we can
 * control how they work together.
 * Ex: filtering will override the group label to "all" but
 * preserve the original selection once search is cleared.
 *
 * Exported for testing purposes only.
 */
export function useDropdownFiltersAndGroupsState({
  items,
  shouldSearch,
  filterFn,
  open,
  groupLayout,
}: {
  items: Array<DropdownItem>;
  shouldSearch: boolean;
  filterFn?: typeof defaultDropdownSearchFn;
  open: boolean;
  groupLayout?: 'tabs' | 'inline';
}) {
  const { t } = useLingui();
  const locale = useLocale();

  const groupLabelAll = t({
    message: 'All',
    context: 'Dropdown group label for all items',
  });
  const groupLabelOther = t({
    message: 'Other',
    context: 'Dropdown group label for other (uncategorized) items',
  });

  const [filterText, setFilterText] = useState('');
  const [selectedGroupLabel, setSelectedGroupLabel] = useState(groupLabelAll);

  useEffect(() => {
    // Whenever we open/close, reset the group label to all
    setSelectedGroupLabel(groupLabelAll);
  }, [open, groupLabelAll]);

  const shouldShowOther = items.some(
    (item) => !item.groupLabel || item.groupLabel === groupLabelOther
  );
  const groupLabels = uniqBy(
    [
      groupLabelAll,
      ...items
        .map((item) => item.groupLabel || undefined)
        .filter((label) => label !== groupLabelAll && label !== groupLabelOther)
        // Sort groups other than All and Other alphabetically
        .sort((a, b) => (a && b ? a.localeCompare(b, locale) : 0)),
      shouldShowOther ? groupLabelOther : undefined,
    ],
    (label) => label
  ).filter(isNotNullish);

  let maybeFilteredItems = items;

  let currentGroupLabel = selectedGroupLabel;

  if (shouldSearch && filterText) {
    const fn = filterFn ?? defaultDropdownSearchFn;
    maybeFilteredItems = fn(maybeFilteredItems, { filterText });
    // When searching, force the group label to "All"
    currentGroupLabel = groupLabelAll;
  } else if (selectedGroupLabel && selectedGroupLabel !== groupLabelAll) {
    maybeFilteredItems = maybeFilteredItems.filter((item) => {
      return (
        item.groupLabel === selectedGroupLabel ||
        (!item.groupLabel && selectedGroupLabel === groupLabelOther)
      );
    });
  }

  return {
    filterText,
    setFilterText,
    maybeFilteredItems,
    groupLabels,
    currentGroupLabel,
    setSelectedGroupLabel,
  };
}

/**
 * A dropdown menu with some of the standard functionality that you want pretty
 * much every time you use a dropdown menu.
 *
 * - Opens and closes without you having to manage that state.
 * - Closes after you make a selection.
 * - Renders a text button trigger or a three-dot-icon trigger.
 */
export interface DropdownMenuProps {
  testId?: string;
  /**
   * Dense mode renders a more compact layout with 14px sized icons, less
   * padding, smaller fonts, and no item dividers. Dense mode is automatically
   * enabled when any of these are the case:
   * - `searchable` is true
   * - `groupLayout` is 'inline'
   */
  dense?: boolean;
  /**
   * If trigger is not provided, a three-dot-icon button is used.
   */
  trigger?: React.ReactNode;
  triggerSx?: SxProps<Theme>;
  triggerStartIcon?: React.ReactNode;
  triggerEndIcon?: React.ReactNode;
  minWidth?: number | string;
  paperMaxHeight?: number;
  paperMaxWidth?: number;
  /**
   * By default the menu opens bottom and left-aligned the anchor element, but it
   * can it be modified with anchor/transformOrigin. This playground is useful:
   * https://mui.com/components/popover/#anchor-playground
   */
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  /**
   * The component passed as `Icon` is rendered in front of text with some
   * standard styling. `EndIcon` is rendered after the text and similarly styled.
   * If you need more customization, do it with `label`.
   */
  items: Array<DropdownItem>;
  /**
   * Whether we should render a search box. If true, `dense` will be turned on
   * to enable a more compact layout for easier searching.
   */
  searchable?: boolean;
  /**
   * A custom filter function - default to searching against the label/sublabel
   * if they are strings. With localization, the labels/sublabels are often
   * ReactNodes, so you typically need to pass a custom function or `searchText`
   * as a `t` localized string from Lingui.
   */
  filterFn?: (
    items: Array<DropdownItem>,
    state: { filterText: LocalizedString }
  ) => Array<DropdownItem>;
  /**
   * If provided, renders a "create" button next to
   * Quirk: Requires {@link filterFn} to be defined as the create button
   * will only render next to a search input.
   */
  onClickCreate?: () => void;
  /**
   * Optional tooltip for the "create" button
   */
  createTooltip?: LocalizedString | React.ReactNode;
  /**
   * If set to a value, shows groups in the dropdown menu.
   * - 'tabs' will show "All", "Other", and any labeled groups on the left side
   *   of the dropdown menu in a scrollable sidebar. Clicking one will filter
   *   the whole dropdown list to that group. This is useful when you have many,
   *   many options and want to categorize them. If you only have one single
   *   group among all items (including unset group label), then group tabs
   *   won't render, as they would be redundant (same content in every group).
   * - 'inline' will show a group label above the first item in a group in the
   *   dropdown menu. This will turn on dense mode automatically.
   */
  groupLayout?: 'tabs' | 'inline';
  /**
   * Passes through to MUI Menu's keepMounted property
   */
  keepMounted?: boolean;
  onAnalyticsEvent?: (event: DropdownMenuAnalyticsEvent) => void;
  buttonProps?: Pick<
    ButtonProps,
    | 'size'
    | 'color'
    | 'isIcon'
    | 'variant'
    | 'tooltip'
    | 'disabled'
    | 'startIcon'
    | 'endIcon'
    | 'sx'
    | 'aria-label'
  >;
  tooltip?: LocalizedString;
  menuSx?: SxProps<Theme>;
  onOpen?: () => void;
}

/**
 * A dropdown menu with some of the standard functionality that you want pretty
 * much every time you use a dropdown menu.
 *
 * - Opens and closes without you having to manage that state.
 * - Closes after you make a selection.
 * - Renders a text button trigger or a three-dot-icon trigger.
 */
export default function DropdownMenu({
  testId,
  trigger,
  triggerSx,
  triggerStartIcon,
  triggerEndIcon,
  items,
  searchable,
  dense: maybeDense,
  filterFn,
  groupLayout: maybeGroupLayout,
  onClickCreate,
  createTooltip,
  minWidth = 150,
  paperMaxHeight = 400, // Set an explicit paperMaxHeight for scrolling
  paperMaxWidth,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  onAnalyticsEvent = () => {},
  keepMounted = true,
  buttonProps,
  tooltip,
  menuSx,
  onOpen,
}: DropdownMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  // Create a unique ID for this instance that can be used for the accessibility
  // props.
  const menuElementId = useId();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    onAnalyticsEvent('open');
    onOpen?.();
    event.stopPropagation();
  };

  const handleClose = (event: MouseEvent) => {
    event.preventDefault();
    setAnchorEl(null);
    onAnalyticsEvent('close');
    event.stopPropagation();
  };

  const isSearchable = Boolean(searchable);
  const {
    filterText,
    currentGroupLabel,
    groupLabels,
    maybeFilteredItems,
    setFilterText,
    setSelectedGroupLabel,
  } = useDropdownFiltersAndGroupsState({
    items,
    shouldSearch: isSearchable,
    filterFn,
    open,
  });

  // Apply rules that determine if we show the group layout properly
  const groupLayout: typeof maybeGroupLayout = (() => {
    switch (maybeGroupLayout) {
      case 'tabs':
        // We always have an "All" group, and at least one other group (either some group defined
        // by a groupLabel, or "Other"). So if there's only one other group besides "All",
        // that means that all items are in the All group AND in the other group, and there's
        // no point showing the tabs, because they'll both have the same items.
        return groupLabels.length > 2 ? maybeGroupLayout : undefined;
      case 'inline':
      case undefined:
        return maybeGroupLayout;
      default:
        assertNever(maybeGroupLayout);
    }
  })();
  const isTabsGroupLayout = groupLayout === 'tabs';

  /**
   * We toggle on dense mode if we're in searchable mode or if we're in inline
   * groups layout mode so that we render items consistently without
   * overwhelming with section dividers.
   */
  const dense = isSearchable || groupLayout === 'inline' ? true : maybeDense;

  const renderedItems = maybeFilteredItems.map((item, index) => {
    const isStartOfGroup =
      maybeFilteredItems[index - 1]?.groupLabel !== item.groupLabel;
    const renderInlineGroupLabel =
      groupLayout === 'inline' && item.groupLabel && isStartOfGroup;
    return (
      <DropdownItemRendered
        key={`dropdownItemRendered-${item.id}`}
        item={item}
        setAnchorEl={setAnchorEl}
        minWidth={minWidth}
        dense={dense}
        renderInlineGroupLabel={renderInlineGroupLabel}
      />
    );
  });

  if (renderedItems.length === 0 && filterText) {
    renderedItems.push(<DropdownSearchEmptyState filterText={filterText} />);
  }

  const renderedTrigger = (
    <DropdownTrigger
      sx={triggerSx}
      testId={testId}
      trigger={trigger}
      triggerStartIcon={triggerStartIcon}
      triggerEndIcon={triggerEndIcon}
      menuElementId={menuElementId}
      handleClick={handleClick}
      buttonProps={buttonProps}
      open={open}
    />
  );

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <span>{renderedTrigger}</span>
        </Tooltip>
      ) : (
        renderedTrigger
      )}
      <Menu
        id={menuElementId}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        keepMounted={keepMounted}
        open={open}
        onClose={handleClose}
        sx={mixinSx(
          {
            '& .MuiMenu-paper, & .MuiMenu-list': {
              maxHeight: paperMaxHeight
                ? `${paperMaxHeight}px !important`
                : undefined,
              maxWidth: paperMaxWidth
                ? `${paperMaxWidth}px !important`
                : undefined,
              // When working with tab groups, add extra
              // width to balance out with the group tabs
              minWidth: isTabsGroupLayout ? '360px' : undefined,
              // If we're searching or there are tabs available, set 'hidden'
              // to create scrollable panes
              overflow: searchable || isTabsGroupLayout ? 'hidden' : undefined,
            },
          },
          // Turn the list into a flex container so we can use flexbox sizing
          (searchable || isTabsGroupLayout) && {
            '& .MuiMenu-list': {
              display: 'flex',
              flexDirection: 'column',
            },
          },
          menuSx
        )}
      >
        {searchable && (
          <DropdownSearchbar
            testIdSuffix={testId}
            value={filterText}
            onChange={(value) => setFilterText(value)}
            endNode={
              onClickCreate && (
                <Button
                  isIcon
                  variant="text"
                  tooltip={createTooltip}
                  onClick={onClickCreate}
                >
                  <ComposeIcon
                    size={16}
                    color={(theme) => theme.palette.grey70}
                  />
                </Button>
              )
            }
          />
        )}
        {isTabsGroupLayout || searchable ? (
          <Stack
            direction={isTabsGroupLayout ? 'row' : undefined}
            flexGrow={isTabsGroupLayout ? 1 : undefined}
            overflow="hidden"
          >
            {isTabsGroupLayout && (
              <Stack overflow="auto">
                <DropdownGroupTabs
                  options={groupLabels}
                  value={currentGroupLabel}
                  onChange={(value) => setSelectedGroupLabel(value)}
                />
              </Stack>
            )}
            <Stack flex={1} overflow="auto">
              {renderedItems}
            </Stack>
          </Stack>
        ) : (
          renderedItems
        )}
      </Menu>
    </>
  );
}
